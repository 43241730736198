import * as React from 'react'

import * as GQL from '@/gql-types'

import UniversalMenu from '@/components/UniversalMenu'

const UnknownSeriesMenu = (data: any) => {
  const series = data.series;
  const sidebar = data.sidebar;

  const profileData = GQL.useUserGameProfileQuery({ variables: {game: series.shortname}});

  let playerItems: Array<any> = [];

  let hasProfile = false;
  if (profileData?.data?.me?.gameProfile) {
    hasProfile = true;
    playerItems = [
      { "text": <i>You do have a profile though</i> }
    ];
  }

  return <UniversalMenu title={series.name} sidebar={sidebar} dim={!hasProfile} items={[
    { "text": <i>Sorry, but we don't fully support this series yet</i> }
  ].concat(playerItems)}/>
};

export default UnknownSeriesMenu
