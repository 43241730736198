import * as React from 'react'

import { default as BishiBashiChannelMenu } from './game/bbc/Menu';
import { default as BeatstreamMenu } from './game/bst/Menu';
import { default as IIDXMenu } from './game/iidx/Menu';
import { default as JubeatMenu } from './game/jb/Menu';
import { default as MahjongFightClubMenu } from './game/mfc/Menu';
import { default as NostalgiaMenu } from './game/nost/Menu';
import { default as PopnMenu } from './game/popn/Menu';
import { default as QMAMenu } from './game/qma/Menu';
import { default as SDVXMenu } from './game/sdvx/Menu';
import { default as UnknownSeriesMenu } from './game/_generic/Menu';

import * as GQL from '@/gql-types';

type Props = {
  sidebar?: boolean,
  account?: GQL.UserDetailsQuery["me"],
  sidebarMenus?: [Array<string>, React.Dispatch<React.SetStateAction<string[]>>]
}

const sortBySeriesName = (a: any, b: any) => {
    if (a.name > b.name)
        return 1;

    if (a.name < b.name)
        return -1;

    return 0;
};

const GameList: React.FunctionComponent<Props> = ({ sidebar, sidebarMenus }) => {
  const { data: userData } = GQL.useUserDetailsQuery();
  const account = userData?.me;

  const { loading, data } = GQL.useGameListQuery();

  if (loading || !data) {
    return <></>;
  }

  const seriesList = [...data.series].sort(sortBySeriesName);

  return <>
    {
      seriesList.map((series) => {

        let seriesMenu = UnknownSeriesMenu;
        if (series.shortname == 'bbc') {
            seriesMenu = BishiBashiChannelMenu;
        }

        if (series.shortname == 'bst') {
            seriesMenu = BeatstreamMenu;
        }

        if (series.shortname == 'iidx') {
            seriesMenu = IIDXMenu;
        }

        if (series.shortname == 'jb') {
            seriesMenu = JubeatMenu;
        }

        if (series.shortname == 'mfc') {
            seriesMenu = MahjongFightClubMenu;
        }

        if (series.shortname == 'nost') {
            seriesMenu = NostalgiaMenu;
        }

        // The Paseli Charge Station doesn't have a UI; it's provided through
        // the API in order to resolve metadata for e.g. transactions and cabs.
        if (series.shortname == 'pcs') {
            return undefined;
        }

        if (series.shortname == 'popn') {
            seriesMenu = PopnMenu;
        }

        if (series.shortname == 'qma') {
            seriesMenu = QMAMenu;
        }

        if (series.shortname == 'sdvx') {
            seriesMenu = SDVXMenu;
        }

        return React.createElement(
            seriesMenu,
            {
                series: series,
                key: series.shortname,
                sidebar: sidebar,
                sidebarMenus: sidebarMenus,
                user: account,
            },
            null,
        );

      })
    }
  </>;
};

export default GameList
