import * as React from 'react'

import Router from 'next/router';

import UniversalMenu from '@/components/UniversalMenu'

import * as GQL from '@/gql-types'

const navigateTo = (url: string, as: string | null) => {
    let actualAs: string | undefined = undefined;

    if (as != null)
      actualAs = as;

    return (e: any) => {
        e.preventDefault();
        Router.push(url,actualAs);
        return false;
    }
};

const BishiBashiChannelMenu = (data: any) => {
  const series = data.series;
  const sidebar = data.sidebar;

  const profileData = GQL.useUserGameProfileQuery({ variables: {game: series.shortname} });

  let playerItems: any = [];
  let hasProfile = false;
  if (profileData?.data?.me?.gameProfile) {
    hasProfile = true;
    playerItems = [
      { "icon": "user", "text": "Profile", "onClick": navigateTo('/game/bbc/[pid]', '/game/bbc/me') },
      { "divider": true },
    ];
  }

  let defaultItems: any = [
    { "icon": "users", "text": "Players", "onClick": navigateTo('/game/bbc/players', null) },
  ];

  return <UniversalMenu title={series.name} icon="bahai" sidebar={sidebar} dim={!hasProfile} items={playerItems.concat(defaultItems)} />
};

export default BishiBashiChannelMenu
