import * as React from 'react';

const PaseliIcon: React.FunctionComponent<{className?: string, style?: any}> = ({style, className}) => <svg x="0px" y="0px" viewBox="0 0 46.900345 62.799998" style={style} className={className}>
  <path
     id="XMLID_82_"
     className="st0" fill="currentColor"
     d="M 8.4,62.8 C 3.8,62.8 1.9,59.3 1.9,56.1 1.9,52.6 4.6,47.8 4.4,42.5 4.2,36.4 0,26 0,19.1 0,10.2 6.1,0 22.4,0 c 17.7,0 23.1,12.5 24.1,17 1.3,5.8 0.1,17.4 -13.8,20.7 -13.1,3 -18.3,-5.2 -18.3,-5.2 0,0 -0.6,-1.5 0.2,-1.8 1.1,-0.4 5.2,4.3 12.6,3.5 4.3,-0.5 11.7,-3.1 11.9,-11.4 0.1,-5 -1,-7.4 -4.1,-11.2 C 32.1,8.1 26.9,5.6 21.2,5.9 12.2,6.3 6,12.6 6.3,21.3 c 0.1,2.5 0.6,4.5 0.8,5.9 0.5,2.6 1.2,5.1 2.2,8.9 2,7 4.8,13.1 4.9,19.7 0.2,4.1 -2.7,7 -5.8,7 z" />
  <path
     id="XMLID_81_" fill="currentColor"
     d="m 27.599609,19.300781 c -1.3,-0.1 -2.198828,0.898828 -2.298828,1.798828 -0.1,1.2 0.698828,1.59961 1.298828,2.59961 0.6,1 0.700782,1.601562 1.300782,2.101562 0.8,0.7 2.499609,0.5 3.099609,0 1.4,-1.1 0.500391,-3.401172 -0.599609,-4.701172 -1.1,-1.4 -1.400782,-1.798828 -2.800782,-1.798828 z m -10.099609,2 c -1.3,-0.1 -2.200781,0.898828 -2.300781,1.798828 -0.1,1.2 0.700781,1.59961 1.300781,2.59961 0.6,1 0.700781,1.601562 1.300781,2.101562 0.8,0.7 2.49961,0.5 3.09961,0 1.4,-1.1 0.50039,-3.401172 -0.59961,-4.701172 -1.1,-1.3 -1.500781,-1.798828 -2.800781,-1.798828 z" />
</svg>

export default PaseliIcon;
