import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
};

export enum AclFlag {
  CanManage = 'CAN_MANAGE',
  CanAdd = 'CAN_ADD',
  CanEdit = 'CAN_EDIT',
  CanView = 'CAN_VIEW',
  CanDelete = 'CAN_DELETE'
}

export type ApiDetails = {
  __typename?: 'APIDetails';
  accountSettingsUrl: Scalars['String'];
  apiDocsUrl: Scalars['String'];
  assetsBaseUrl: Scalars['String'];
  scoreImportUrl: Scalars['String'];
};

export type Account = Node & {
  __typename?: 'Account';
  id: Scalars['ID'];
  networkOperator: Scalars['Boolean'];
  cards?: Maybe<Array<Card>>;
  wallets?: Maybe<Array<Wallet>>;
  gameProfiles?: Maybe<Array<GameProfile>>;
  shops?: Maybe<Array<Shop>>;
  shopUnions?: Maybe<Array<ShopUnion>>;
  email?: Maybe<Scalars['String']>;
  gameProfile?: Maybe<GameProfile>;
  aclCards: Array<AclFlag>;
  aclWallets: Array<AclFlag>;
  aclShop: Array<AclFlag>;
};


export type AccountGameProfileArgs = {
  shortname: Scalars['String'];
};

export type AddCard = {
  __typename?: 'AddCard';
  ok: Scalars['Boolean'];
  card?: Maybe<Card>;
};

export type AddMembership = {
  __typename?: 'AddMembership';
  ok: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  membership?: Maybe<UserMembership>;
};

export type AddRival = {
  __typename?: 'AddRival';
  ok: Scalars['Boolean'];
  entry?: Maybe<IidxRival>;
};

export type AnalyticsAbsoluteTimeRange = {
  since?: Maybe<Scalars['DateTime']>;
  until?: Maybe<Scalars['DateTime']>;
};

export enum AnalyticsBucketSize {
  Hour = 'HOUR',
  Day = 'DAY',
  Week = 'WEEK',
  Month = 'MONTH',
  Year = 'YEAR'
}

export type AnalyticsData = IncomeAnalyticsData | PlayAnalyticsData;

export enum AnalyticsGroup {
  Game = 'GAME',
  Series = 'SERIES',
  Cabinet = 'CABINET',
  ShopUnion = 'SHOP_UNION',
  Shop = 'SHOP'
}

export type AnalyticsGroupNode = Game | Series | Shop | ShopUnion | Cabinet;

export type AnalyticsItem = {
  __typename?: 'AnalyticsItem';
  periodStart: Scalars['String'];
  isEmptyBucket: Scalars['Boolean'];
  group?: Maybe<AnalyticsGroupNode>;
  data: AnalyticsData;
};

export enum AnalyticsQuery {
  IncomeTotal = 'INCOME_TOTAL',
  IncomeVirtualcoin = 'INCOME_VIRTUALCOIN',
  IncomeCoin = 'INCOME_COIN',
  Gamestarts = 'GAMESTARTS'
}

export type AnalyticsRelativeTimeRange = {
  unit: AnalyticsBucketSize;
  amount: Scalars['Int'];
};

export type Area = Node & {
  __typename?: 'Area';
  id: Scalars['ID'];
  country: Scalars['String'];
  titleJp: Scalars['String'];
  titleEn: Scalars['String'];
};

export type BbcRoot = {
  __typename?: 'BBCRoot';
  profileLookup?: Maybe<BishiBashiChannelProfile>;
  profileSearch: BishiBashiChannelProfileConnection;
};


export type BbcRootProfileLookupArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type BbcRootProfileSearchArgs = {
  name?: Maybe<Scalars['String']>;
  lastPlay?: Maybe<Scalars['Int']>;
  last: Scalars['Int'];
  offset: Scalars['Int'];
};

export type BstRoot = {
  __typename?: 'BSTRoot';
  revision?: Maybe<BeatstreamRevision>;
  chart?: Maybe<BeatstreamChart>;
  song?: Maybe<BeatstreamSong>;
  songs: BeatstreamSongConnection;
  profileLookup?: Maybe<BeatstreamProfile>;
  profileSearch: BeatstreamProfileConnection;
};


export type BstRootRevisionArgs = {
  id: Scalars['ID'];
};


export type BstRootChartArgs = {
  id: Scalars['ID'];
};


export type BstRootSongArgs = {
  id: Scalars['ID'];
};


export type BstRootSongsArgs = {
  version?: Maybe<Scalars['Int']>;
  useDefaultGame?: Maybe<Scalars['Boolean']>;
  last: Scalars['Int'];
  offset: Scalars['Int'];
};


export type BstRootProfileLookupArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type BstRootProfileSearchArgs = {
  name?: Maybe<Scalars['String']>;
  lastPlay?: Maybe<Scalars['Int']>;
  last: Scalars['Int'];
  offset: Scalars['Int'];
};

export type BeatstreamBattleComparison = {
  __typename?: 'BeatstreamBattleComparison';
  wins: Scalars['Int'];
  losses: Scalars['Int'];
};

export type BeatstreamChart = Node & {
  __typename?: 'BeatstreamChart';
  id: Scalars['ID'];
  difficulty: ChartType;
  level: ChartLevel;
  genre: SongGenre;
  hasCustomJacket: Scalars['Boolean'];
  revision?: Maybe<BeatstreamRevision>;
  song: BeatstreamSong;
  game: Game;
  siblingCharts: Array<BeatstreamChart>;
};

export type BeatstreamCourseScore = Node & {
  __typename?: 'BeatstreamCourseScore';
  id: Scalars['ID'];
  timestamp: Scalars['DateTime'];
  score: Scalars['Int'];
  maxCombo: Scalars['Int'];
  countFanta?: Maybe<Scalars['Int']>;
  countFine?: Maybe<Scalars['Int']>;
  countMiss?: Maybe<Scalars['Int']>;
  shop: Shop;
  rank: Scalars['Int'];
  stagesCleared: Scalars['Int'];
  game: Game;
  plays: Array<BeatstreamPlay>;
  grade: BeatstreamGrade;
  medal: BeatstreamMedal;
  clearGauge: Scalars['Int'];
};

export enum BeatstreamGrade {
  D = 'D',
  C = 'C',
  B = 'B',
  A = 'A',
  Aa = 'AA',
  Aaa = 'AAA',
  RedAaa = 'RED_AAA'
}

export enum BeatstreamHighTensionResult {
  None = 'NONE',
  White = 'WHITE',
  Bronze = 'BRONZE',
  Silver = 'SILVER',
  Gold = 'GOLD'
}

export type BeatstreamHighscore = {
  __typename?: 'BeatstreamHighscore';
  score: Scalars['Int'];
  medal: BeatstreamMedal;
  timestamp: Scalars['DateTime'];
  profileId: Scalars['ID'];
  profileName: Scalars['String'];
  play: BeatstreamPlay;
  profile: BeatstreamProfile;
  game: Game;
};

export enum BeatstreamMedal {
  NoPlay = 'NO_PLAY',
  Failed = 'FAILED',
  Saved = 'SAVED',
  Cleared = 'CLEARED',
  FullCombo = 'FULL_COMBO',
  Perfect = 'PERFECT'
}

export type BeatstreamPlay = Node & {
  __typename?: 'BeatstreamPlay';
  timestamp: Scalars['DateTime'];
  score: Scalars['Int'];
  maxCombo: Scalars['Int'];
  profile?: Maybe<BeatstreamProfile>;
  chart?: Maybe<BeatstreamChart>;
  coursePlay?: Maybe<BeatstreamCourseScore>;
  shop?: Maybe<Shop>;
  id: Scalars['ID'];
  song: BeatstreamSong;
  game: Game;
  medal: BeatstreamMedal;
  grade: BeatstreamGrade;
  highTensionResult: BeatstreamHighTensionResult;
  clearGauge: Scalars['Int'];
  judgeMiss?: Maybe<Scalars['Int']>;
  judgeFine?: Maybe<Scalars['Int']>;
  judgeGreat?: Maybe<Scalars['Int']>;
  judgeFantastic?: Maybe<Scalars['Int']>;
};

export type BeatstreamPlayConnection = {
  __typename?: 'BeatstreamPlayConnection';
  totalItems: Scalars['Int'];
  items: Array<BeatstreamPlay>;
};

export type BeatstreamPlayHistogramEntry = {
  __typename?: 'BeatstreamPlayHistogramEntry';
  period: Scalars['String'];
  plays: Scalars['Int'];
};

export type BeatstreamProfile = Node & {
  __typename?: 'BeatstreamProfile';
  name: Scalars['String'];
  playerId: Scalars['Int'];
  accessed: Scalars['DateTime'];
  id: Scalars['ID'];
  plays: BeatstreamPlayConnection;
  playHistogram?: Maybe<Array<BeatstreamPlayHistogramEntry>>;
  registered: Scalars['DateTime'];
  courseRank?: Maybe<Scalars['Int']>;
  scoreBattle?: Maybe<BeatstreamBattleComparison>;
};


export type BeatstreamProfilePlaysArgs = {
  last: Scalars['Int'];
  offset: Scalars['Int'];
  revision?: Maybe<Scalars['ID']>;
};

export type BeatstreamProfileConnection = {
  __typename?: 'BeatstreamProfileConnection';
  totalItems: Scalars['Int'];
  items: Array<BeatstreamProfile>;
};

export type BeatstreamRevision = Node & {
  __typename?: 'BeatstreamRevision';
  id: Scalars['ID'];
  song?: Maybe<BeatstreamSong>;
  highscores: Array<BeatstreamHighscore>;
  charts: Array<BeatstreamChart>;
};


export type BeatstreamRevisionHighscoresArgs = {
  players?: Maybe<Array<Scalars['ID']>>;
};

export type BeatstreamSong = Node & {
  __typename?: 'BeatstreamSong';
  id: Scalars['ID'];
  title: Scalars['String'];
  subTitle?: Maybe<Scalars['String']>;
  artist: Scalars['String'];
  bpmMin: Scalars['Float'];
  bpmMax: Scalars['Float'];
  filename: Scalars['String'];
  introducedIn: Game;
  revisions: Array<BeatstreamRevision>;
  charts: Array<BeatstreamChart>;
};


export type BeatstreamSongChartsArgs = {
  version?: Maybe<Scalars['Int']>;
  useDefaultGame?: Maybe<Scalars['Boolean']>;
};

export type BeatstreamSongConnection = {
  __typename?: 'BeatstreamSongConnection';
  totalItems: Scalars['Int'];
  items: Array<BeatstreamSong>;
};

export enum BingoCardSize {
  Square3X3 = 'SQUARE3X3',
  Square4X4 = 'SQUARE4X4',
  Square5X5 = 'SQUARE5X5'
}

export type BishiBashiChannelProfile = Node & {
  __typename?: 'BishiBashiChannelProfile';
  name: Scalars['String'];
  registered: Scalars['DateTime'];
  accessed: Scalars['DateTime'];
  id: Scalars['ID'];
};

export type BishiBashiChannelProfileConnection = {
  __typename?: 'BishiBashiChannelProfileConnection';
  totalItems: Scalars['Int'];
  items: Array<BishiBashiChannelProfile>;
};

export type Cabinet = Node & {
  __typename?: 'Cabinet';
  id: Scalars['ID'];
  enabled: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  created: Scalars['DateTime'];
  lastContact?: Maybe<Scalars['DateTime']>;
  shop?: Maybe<Shop>;
  description?: Maybe<Scalars['String']>;
  pcbid?: Maybe<Scalars['String']>;
  flags?: Maybe<Array<Scalars['String']>>;
  playSessionStats: Array<HistogramValue>;
  series?: Maybe<Series>;
  game?: Maybe<Game>;
};

export type Card = Node & {
  __typename?: 'Card';
  registered: Scalars['DateTime'];
  accessed?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  tagType: CardType;
  tagId: Scalars['String'];
  displayId?: Maybe<Scalars['String']>;
};

export enum CardType {
  EapassIso15693 = 'EAPASS_ISO15693',
  EapassFelica = 'EAPASS_FELICA',
  Aime = 'AIME',
  Nesica = 'NESICA',
  EapassDisplaycode = 'EAPASS_DISPLAYCODE'
}

export enum ChartLevel {
  Lvl_1 = 'LVL_1',
  Lvl_2 = 'LVL_2',
  Lvl_3 = 'LVL_3',
  Lvl_4 = 'LVL_4',
  Lvl_5 = 'LVL_5',
  Lvl_6 = 'LVL_6',
  Lvl_7 = 'LVL_7',
  Lvl_8 = 'LVL_8',
  Lvl_9Minus = 'LVL_9_MINUS',
  Lvl_9 = 'LVL_9',
  Lvl_9Plus = 'LVL_9_PLUS',
  Lvl_10Minus = 'LVL_10_MINUS',
  Lvl_10 = 'LVL_10',
  Lvl_10Plus = 'LVL_10_PLUS',
  LvlGod = 'LVL_GOD'
}

export enum ChartType {
  Light = 'LIGHT',
  Medium = 'MEDIUM',
  Beast = 'BEAST',
  Nightmare = 'NIGHTMARE'
}

export enum ClearLamp {
  NoPlay = 'NO_PLAY',
  Failed = 'FAILED',
  AssistClear = 'ASSIST_CLEAR',
  EasyClear = 'EASY_CLEAR',
  NormalClear = 'NORMAL_CLEAR',
  HardClear = 'HARD_CLEAR',
  ExHardClear = 'EX_HARD_CLEAR',
  FullCombo = 'FULL_COMBO'
}

export type CreateBingoCard = {
  __typename?: 'CreateBingoCard';
  ok: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  bingoCard?: Maybe<IidxBingoCard>;
};

export type CreateCabinet = {
  __typename?: 'CreateCabinet';
  ok: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  cabinet?: Maybe<Cabinet>;
};

export type CreateCompetition = {
  __typename?: 'CreateCompetition';
  ok: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  competition?: Maybe<IidxCompetition>;
};

export type CreateShop = {
  __typename?: 'CreateShop';
  ok: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  shop?: Maybe<Shop>;
};


export type DeleteCard = {
  __typename?: 'DeleteCard';
  ok: Scalars['Boolean'];
};

export type DismissNotification = {
  __typename?: 'DismissNotification';
  ok: Scalars['Boolean'];
};

export type EditShop = {
  __typename?: 'EditShop';
  ok: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  shop?: Maybe<Shop>;
};

export type EnumParameterEntry = {
  __typename?: 'EnumParameterEntry';
  value: Scalars['Int'];
  description: Scalars['String'];
};

export type EnumParameterInfo = ParameterInfo & {
  __typename?: 'EnumParameterInfo';
  type: ParameterType;
  values: Array<EnumParameterEntry>;
};

export type FloatParameterInfo = ParameterInfo & {
  __typename?: 'FloatParameterInfo';
  type: ParameterType;
  minValue?: Maybe<Scalars['Float']>;
  maxValue?: Maybe<Scalars['Float']>;
};

export type Game = {
  __typename?: 'Game';
  name: Scalars['String'];
  version: Scalars['Int'];
};

export type GameProfile = BishiBashiChannelProfile | BeatstreamProfile | IidxProfile | JubeatProfile | SdvxProfile | QmaProfile;

export type GenericParameterInfo = ParameterInfo & {
  __typename?: 'GenericParameterInfo';
  type: ParameterType;
};

export type HistogramValue = {
  __typename?: 'HistogramValue';
  key: Scalars['String'];
  value: Scalars['Int'];
};

export enum IidxArenaClass {
  None = 'NONE',
  D5 = 'D5',
  D4 = 'D4',
  D3 = 'D3',
  D2 = 'D2',
  D1 = 'D1',
  C5 = 'C5',
  C4 = 'C4',
  C3 = 'C3',
  C2 = 'C2',
  C1 = 'C1',
  B5 = 'B5',
  B4 = 'B4',
  B3 = 'B3',
  B2 = 'B2',
  B1 = 'B1',
  A5 = 'A5',
  A4 = 'A4',
  A3 = 'A3',
  A2 = 'A2',
  A1 = 'A1'
}

export type IidxArenaMatch = Node & {
  __typename?: 'IIDXArenaMatch';
  id: Scalars['ID'];
  stages?: Maybe<IidxArenaStageConnection>;
  participants?: Maybe<IidxArenaParticipantConnection>;
};


export type IidxArenaMatchStagesArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type IidxArenaMatchParticipantsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type IidxArenaParticipant = Node & {
  __typename?: 'IIDXArenaParticipant';
  id: Scalars['ID'];
  name: Scalars['String'];
  isCpu: Scalars['Boolean'];
  profile?: Maybe<IidxProfile>;
  match?: Maybe<IidxArenaMatch>;
};

export type IidxArenaParticipantConnection = {
  __typename?: 'IIDXArenaParticipantConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<IidxArenaParticipantEdge>>;
};

export type IidxArenaParticipantEdge = {
  __typename?: 'IIDXArenaParticipantEdge';
  node?: Maybe<IidxArenaParticipant>;
  cursor: Scalars['String'];
};

export type IidxArenaScore = Node & {
  __typename?: 'IIDXArenaScore';
  id: Scalars['ID'];
  exscore: Scalars['Int'];
  stage?: Maybe<IidxArenaStage>;
  participant?: Maybe<IidxArenaParticipant>;
  play?: Maybe<IidxPlay>;
  lamp: IidxLamp;
};

export type IidxArenaStage = Node & {
  __typename?: 'IIDXArenaStage';
  id: Scalars['ID'];
  stage?: Maybe<Scalars['Int']>;
  match?: Maybe<IidxArenaMatch>;
  picker?: Maybe<IidxArenaParticipant>;
  chart?: Maybe<IidxChart>;
  revision?: Maybe<IidxRevision>;
  scores: Array<IidxArenaScore>;
};

export type IidxArenaStageConnection = {
  __typename?: 'IIDXArenaStageConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<IidxArenaStageEdge>>;
};

export type IidxArenaStageEdge = {
  __typename?: 'IIDXArenaStageEdge';
  node?: Maybe<IidxArenaStage>;
  cursor: Scalars['String'];
};

export type IidxBattleComparison = {
  __typename?: 'IIDXBattleComparison';
  wins: Scalars['Int'];
  losses: Scalars['Int'];
};

export type IidxBingoCard = Node & {
  __typename?: 'IIDXBingoCard';
  id: Scalars['ID'];
  title: Scalars['String'];
  created: Scalars['DateTime'];
  size: BingoCardSize;
  creator?: Maybe<IidxProfile>;
  slots: Array<IidxBingoCardSlot>;
  games: Array<Game>;
  playerProgress?: Maybe<IidxBingoCardProgress>;
};


export type IidxBingoCardPlayerProgressArgs = {
  profile?: Maybe<Scalars['ID']>;
};

export type IidxBingoCardConnection = {
  __typename?: 'IIDXBingoCardConnection';
  totalItems: Scalars['Int'];
  items: Array<IidxBingoCard>;
};

export type IidxBingoCardProgress = Node & {
  __typename?: 'IIDXBingoCardProgress';
  playerId: Scalars['Int'];
  clearedSlots: Array<Maybe<Scalars['Boolean']>>;
  id: Scalars['ID'];
};

export enum IidxBingoCardSize {
  Square3x3 = 'Square3x3',
  Square4x4 = 'Square4x4',
  Square5x5 = 'Square5x5'
}

export type IidxBingoCardSlot = Node & {
  __typename?: 'IIDXBingoCardSlot';
  id: Scalars['ID'];
  slotId: Scalars['Int'];
  clearType: ClearLamp;
  card?: Maybe<IidxBingoCard>;
  revision?: Maybe<IidxRevision>;
};

export type IidxBingoCardSlotInput = {
  slotX: Scalars['Int'];
  slotY: Scalars['Int'];
  revision: Scalars['ID'];
  clearType: IidxLamp;
};

export type IidxBingoData = {
  __typename?: 'IIDXBingoData';
  myCard?: Maybe<IidxBingoCard>;
  discipleCard?: Maybe<IidxBingoCard>;
  bingoCards: Array<IidxBingoCard>;
};

export type IidxChart = Node & {
  __typename?: 'IIDXChart';
  id: Scalars['ID'];
  level: Scalars['Int'];
  revision?: Maybe<IidxRevision>;
  song: IidxSong;
  game: Game;
  gameFolder: Scalars['Int'];
  notes: Scalars['Int'];
  chargeNotes: Scalars['Int'];
  bssNotes: Scalars['Int'];
  mssNotes: Scalars['Int'];
  maxExscore: Scalars['Int'];
  hellChargeNotes: Scalars['Boolean'];
  diff: IidxDifficulty;
  playstyle: IidxPlayStyle;
  siblingCharts: Array<IidxChart>;
};

export type IidxClassCourseScore = Node & {
  __typename?: 'IIDXClassCourseScore';
  id: Scalars['ID'];
  playtime: Scalars['DateTime'];
  stagesCleared: Scalars['Int'];
  courseCleared: Scalars['Boolean'];
  isHidden: Scalars['Boolean'];
  isIgnored: Scalars['Boolean'];
  isEx: Scalars['Boolean'];
  isMirror: Scalars['Boolean'];
  shop: Shop;
  achievementRate: Scalars['Float'];
  rank: IidxClassRank;
  playStyle: IidxPlayStyle;
  game: Game;
  plays: Array<IidxPlay>;
};

export type IidxClassCourseScoreConnection = {
  __typename?: 'IIDXClassCourseScoreConnection';
  totalItems: Scalars['Int'];
  items: Array<IidxClassCourseScore>;
};

export enum IidxClassRank {
  None = 'NONE',
  Kyuu_7 = 'KYUU_7',
  Kyuu_6 = 'KYUU_6',
  Kyuu_5 = 'KYUU_5',
  Kyuu_4 = 'KYUU_4',
  Kyuu_3 = 'KYUU_3',
  Kyuu_2 = 'KYUU_2',
  Kyuu_1 = 'KYUU_1',
  Dan_1 = 'DAN_1',
  Dan_2 = 'DAN_2',
  Dan_3 = 'DAN_3',
  Dan_4 = 'DAN_4',
  Dan_5 = 'DAN_5',
  Dan_6 = 'DAN_6',
  Dan_7 = 'DAN_7',
  Dan_8 = 'DAN_8',
  Dan_9 = 'DAN_9',
  Dan_10 = 'DAN_10',
  Chuuden = 'CHUUDEN',
  Kaiden = 'KAIDEN'
}

export type IidxCompetition = Node & {
  __typename?: 'IIDXCompetition';
  id: Scalars['ID'];
  title: Scalars['String'];
  subtitle?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  created: Scalars['DateTime'];
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
  creator?: Maybe<IidxProfile>;
  charts: Array<IidxChart>;
  game: Game;
  isParticipant: Scalars['Boolean'];
  highscores: Array<IidxCompetitionHighscore>;
};


export type IidxCompetitionIsParticipantArgs = {
  profile?: Maybe<Scalars['ID']>;
};

export type IidxCompetitionConnection = {
  __typename?: 'IIDXCompetitionConnection';
  totalItems: Scalars['Int'];
  items: Array<IidxCompetition>;
};

export type IidxCompetitionHighscore = {
  __typename?: 'IIDXCompetitionHighscore';
  profile: IidxProfile;
  score: Scalars['Int'];
  rank: Scalars['Int'];
};

export enum IidxCustomizationFlag {
  FolderGrade = 'FOLDER_GRADE',
  FolderClearLamp = 'FOLDER_CLEAR_LAMP',
  FolderDifficulty = 'FOLDER_DIFFICULTY',
  FolderAlphabet = 'FOLDER_ALPHABET',
  FolderRivalPlayed = 'FOLDER_RIVAL_PLAYED',
  FolderRivalVersus = 'FOLDER_RIVAL_VERSUS',
  RivalWindow = 'RIVAL_WINDOW',
  HideProfilePlaycount = 'HIDE_PROFILE_PLAYCOUNT',
  FolderRivalAllPlayed = 'FOLDER_RIVAL_ALL_PLAYED',
  HideProfileId = 'HIDE_PROFILE_ID',
  SongPreview = 'SONG_PREVIEW',
  EffectorLock = 'EFFECTOR_LOCK'
}

export type IidxDaily = Node & {
  __typename?: 'IIDXDaily';
  id: Scalars['ID'];
  date: Scalars['String'];
  chart1?: Maybe<IidxChart>;
  chart2?: Maybe<IidxChart>;
  chart3?: Maybe<IidxChart>;
};

export type IidxDailyProgress = {
  __typename?: 'IIDXDailyProgress';
  dailies: IidxDaily;
  playedChart1: Scalars['Boolean'];
  playedChart2: Scalars['Boolean'];
  playedChart3: Scalars['Boolean'];
};

export enum IidxDifficulty {
  Beginner = 'BEGINNER',
  Normal = 'NORMAL',
  Hyper = 'HYPER',
  Another = 'ANOTHER',
  Leggendaria = 'LEGGENDARIA'
}

export type IidxGameData = Node & {
  __typename?: 'IIDXGameData';
  id: Scalars['ID'];
  game: Game;
  qpro: IidxQpro;
  uiSettings: IidxuiSettings;
  rivals: Array<IidxRival>;
  challengers: Array<IidxRival>;
  activeWeekly?: Maybe<IidxWeekly>;
  activeDailies?: Maybe<IidxDaily>;
  dailiesProgress?: Maybe<IidxDailyProgress>;
  spArenaClass?: Maybe<IidxArenaClass>;
  dpArenaClass?: Maybe<IidxArenaClass>;
  spDjPoints: Scalars['Int'];
  dpDjPoints: Scalars['Int'];
  medals: IidxMedalStats;
};

export type IidxHighscore = {
  __typename?: 'IIDXHighscore';
  score: Scalars['Int'];
  lamp: IidxLamp;
  timestamp: Scalars['DateTime'];
  profileId: Scalars['ID'];
  profileName: Scalars['String'];
  play: IidxPlay;
  profile: IidxProfile;
  game: Game;
};

export enum IidxLamp {
  NoPlay = 'NO_PLAY',
  Failed = 'FAILED',
  AssistClear = 'ASSIST_CLEAR',
  EasyClear = 'EASY_CLEAR',
  NormalClear = 'NORMAL_CLEAR',
  HardClear = 'HARD_CLEAR',
  ExHardClear = 'EX_HARD_CLEAR',
  FullCombo = 'FULL_COMBO'
}

export type IidxMedal = Node & {
  __typename?: 'IIDXMedal';
  id: Scalars['ID'];
  text: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  type: Scalars['Int'];
  subtype: Scalars['Int'];
  value: Scalars['Int'];
  dellerReward: Scalars['Int'];
  color: IidxMedalColor;
};

export enum IidxMedalColor {
  Platinum = 'PLATINUM',
  Gold = 'GOLD',
  Silver = 'SILVER',
  Bronze = 'BRONZE',
  Black = 'BLACK'
}

export type IidxMedalState = {
  __typename?: 'IIDXMedalState';
  obtained: Scalars['Boolean'];
  medal: IidxMedal;
};

export type IidxMedalStats = {
  __typename?: 'IIDXMedalStats';
  platinum: Scalars['Int'];
  gold: Scalars['Int'];
  silver: Scalars['Int'];
  bronze: Scalars['Int'];
  black: Scalars['Int'];
  state: Array<IidxMedalState>;
};

export type IidxNews = Node & {
  __typename?: 'IIDXNews';
  id: Scalars['ID'];
  type: NewsType;
  profile?: Maybe<IidxProfile>;
  play: IidxPlay;
};

export enum IidxNewsChart {
  Spb = 'SPB',
  Spn = 'SPN',
  Sph = 'SPH',
  Spa = 'SPA',
  Spl = 'SPL',
  Dpb = 'DPB',
  Dpn = 'DPN',
  Dph = 'DPH',
  Dpa = 'DPA',
  Dpl = 'DPL'
}

export type IidxNewsConnection = {
  __typename?: 'IIDXNewsConnection';
  totalItems: Scalars['Int'];
  items: Array<IidxNews>;
};

export type IidxNewsSettings = {
  __typename?: 'IIDXNewsSettings';
  types: Array<IidxNewsType>;
  charts: Array<IidxNewsChart>;
  topics: Array<IidxNewsTopic>;
};

export enum IidxNewsTopic {
  NationalTop = 'NationalTop',
  AreaTop = 'AreaTop',
  VenueTop = 'VenueTop',
  NationalImprovements = 'NationalImprovements',
  GradeImprovements = 'GradeImprovements',
  VenueImprovements = 'VenueImprovements',
  RivalImprovements = 'RivalImprovements'
}

export enum IidxNewsType {
  ClearLamp = 'ClearLamp',
  Score = 'Score'
}

export type IidxNotesRadar = {
  __typename?: 'IIDXNotesRadar';
  notes: Scalars['Float'];
  chord: Scalars['Float'];
  peak: Scalars['Float'];
  charge: Scalars['Float'];
  scratch: Scalars['Float'];
  soflan: Scalars['Float'];
};

export type IidxPlay = Node & {
  __typename?: 'IIDXPlay';
  id: Scalars['ID'];
  exscore: Scalars['Int'];
  isHidden: Scalars['Boolean'];
  isIgnored: Scalars['Boolean'];
  chart?: Maybe<IidxChart>;
  shop?: Maybe<Shop>;
  details?: Maybe<IidxPlayDetails>;
  arenaStage?: Maybe<IidxArenaStage>;
  song: IidxSong;
  game: Game;
  timestamp: Scalars['DateTime'];
  lamp: IidxLamp;
  classCourseScore?: Maybe<IidxClassCourseScore>;
  arenaScore?: Maybe<IidxArenaScore>;
  competition?: Maybe<IidxCompetition>;
  ghost?: Maybe<Array<Scalars['Int']>>;
  gauge?: Maybe<Array<Scalars['Float']>>;
  playOptions?: Maybe<Array<IidxPlayOption>>;
};

export type IidxPlayConnection = {
  __typename?: 'IIDXPlayConnection';
  totalItems: Scalars['Int'];
  items: Array<IidxPlay>;
};

export type IidxPlayDetails = Node & {
  __typename?: 'IIDXPlayDetails';
  id: Scalars['ID'];
  targetGraph: Scalars['Int'];
  targetExscore: Scalars['Int'];
  judgePgreat: Scalars['Int'];
  judgeGreat: Scalars['Int'];
  judgeGood: Scalars['Int'];
  judgeBad: Scalars['Int'];
  judgePoor: Scalars['Int'];
  comboBreak: Scalars['Int'];
  timingFast: Scalars['Int'];
  timingSlow: Scalars['Int'];
};

export type IidxPlayHistogramEntry = {
  __typename?: 'IIDXPlayHistogramEntry';
  period: Scalars['String'];
  plays: Scalars['Int'];
};

export enum IidxPlayOption {
  Hiddenplus = 'HIDDENPLUS',
  Suddenplus = 'SUDDENPLUS',
  AssistGauge = 'ASSIST_GAUGE',
  EasyGauge = 'EASY_GAUGE',
  HardGauge = 'HARD_GAUGE',
  ExHardGauge = 'EX_HARD_GAUGE',
  Random = 'RANDOM',
  RRandom = 'R_RANDOM',
  SRandom = 'S_RANDOM',
  Mirror = 'MIRROR',
  AutoScratch = 'AUTO_SCRATCH',
  Battle = 'BATTLE',
  HazardGauge = 'HAZARD_GAUGE',
  Lift = 'LIFT',
  LegacyNote = 'LEGACY_NOTE',
  RightRandom = 'RIGHT_RANDOM',
  RightRRandom = 'RIGHT_R_RANDOM',
  RightSRandom = 'RIGHT_S_RANDOM',
  RightMirror = 'RIGHT_MIRROR',
  SideP1 = 'SIDE_P1',
  SideP2 = 'SIDE_P2'
}

export type IidxPlayStatistics = {
  __typename?: 'IIDXPlayStatistics';
  lamp?: Maybe<IidxLamp>;
  grade?: Maybe<Scalars['String']>;
  count: Scalars['Int'];
};

export enum IidxPlayStatsQuery {
  Lamps = 'LAMPS',
  Grades = 'GRADES'
}

export enum IidxPlayStyle {
  Single = 'SINGLE',
  Double = 'DOUBLE'
}

export type IidxProfile = Node & {
  __typename?: 'IIDXProfile';
  name: Scalars['String'];
  forceUnlockCurrent: Scalars['Boolean'];
  forceUnlockPrevious: Scalars['Boolean'];
  registered: Scalars['DateTime'];
  accessed: Scalars['DateTime'];
  area?: Maybe<Area>;
  id: Scalars['ID'];
  playerId: Scalars['Int'];
  qpro: IidxQpro;
  uiSettings: IidxuiSettings;
  newsSettings: IidxNewsSettings;
  bingo: IidxBingoData;
  classCourseScores: IidxClassCourseScoreConnection;
  spRank?: Maybe<IidxClassCourseScore>;
  dpRank?: Maybe<IidxClassCourseScore>;
  spArenaClass?: Maybe<IidxArenaClass>;
  dpArenaClass?: Maybe<IidxArenaClass>;
  spDjPoints: Scalars['Int'];
  dpDjPoints: Scalars['Int'];
  spNotesRadar?: Maybe<IidxNotesRadar>;
  dpNotesRadar?: Maybe<IidxNotesRadar>;
  plays: IidxPlayConnection;
  playStats?: Maybe<Array<IidxPlayStatistics>>;
  playHistogram?: Maybe<Array<IidxPlayHistogramEntry>>;
  gameData: Array<IidxGameData>;
  defaultGame: Game;
  activeWeekly?: Maybe<IidxWeekly>;
  activeDailies?: Maybe<IidxDaily>;
  dailiesProgress?: Maybe<IidxDailyProgress>;
  rivals: Array<IidxRival>;
  scoreBattle?: Maybe<IidxBattleComparison>;
  medals: IidxMedalStats;
  arenaComment1?: Maybe<Scalars['String']>;
  arenaComment2?: Maybe<Scalars['String']>;
  arenaComment3?: Maybe<Scalars['String']>;
  arenaComment4?: Maybe<Scalars['String']>;
};


export type IidxProfileClassCourseScoresArgs = {
  last: Scalars['Int'];
  offset: Scalars['Int'];
};


export type IidxProfilePlaysArgs = {
  last: Scalars['Int'];
  offset: Scalars['Int'];
  revision?: Maybe<Scalars['ID']>;
  lamp?: Maybe<IidxLamp>;
  plays?: Maybe<Array<Scalars['ID']>>;
};


export type IidxProfilePlayStatsArgs = {
  query?: Maybe<IidxPlayStatsQuery>;
};

export type IidxProfileConnection = {
  __typename?: 'IIDXProfileConnection';
  totalItems: Scalars['Int'];
  items: Array<IidxProfile>;
};

export type IidxQpro = {
  __typename?: 'IIDXQpro';
  body: IidxQproPart;
  face: IidxQproPart;
  hair: IidxQproPart;
  hand: IidxQproPart;
  head: IidxQproPart;
};

export type IidxQproPart = Node & {
  __typename?: 'IIDXQproPart';
  name: Scalars['String'];
  text: Scalars['String'];
  id: Scalars['ID'];
  type: IidxQproPartType;
  version: Scalars['Int'];
};

export enum IidxQproPartType {
  Head = 'HEAD',
  Hair = 'HAIR',
  Face = 'FACE',
  Hand = 'HAND',
  Body = 'BODY'
}

export type IidxRevision = Node & {
  __typename?: 'IIDXRevision';
  id: Scalars['ID'];
  song?: Maybe<IidxSong>;
  highscores: Array<IidxHighscore>;
};


export type IidxRevisionHighscoresArgs = {
  players?: Maybe<Array<Scalars['ID']>>;
  competition?: Maybe<Scalars['ID']>;
  weekly?: Maybe<Scalars['ID']>;
  arenaStage?: Maybe<Scalars['ID']>;
};

export type IidxRival = Node & {
  __typename?: 'IIDXRival';
  id: Scalars['ID'];
  game: Game;
  player: IidxProfile;
  rival: IidxProfile;
  playerGameData: IidxGameData;
  rivalGameData: IidxGameData;
  playStyle: IidxPlayStyle;
  scoreBattle: IidxBattleComparison;
};

export type IidxRoot = {
  __typename?: 'IIDXRoot';
  profileLookup?: Maybe<IidxProfile>;
  profileSearch?: Maybe<IidxProfileConnection>;
  qproParts: Array<IidxQproPart>;
  uiParts: Array<IidxuiPart>;
  songs: IidxSongConnection;
  bingoCards: IidxBingoCardConnection;
  bingoCard?: Maybe<IidxBingoCard>;
  competitions: IidxCompetitionConnection;
  competition?: Maybe<IidxCompetition>;
  news: IidxNewsConnection;
  revision?: Maybe<IidxRevision>;
  chart?: Maybe<IidxChart>;
  song?: Maybe<IidxSong>;
};


export type IidxRootProfileLookupArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type IidxRootProfileSearchArgs = {
  name?: Maybe<Scalars['String']>;
  lastPlay?: Maybe<Scalars['Int']>;
  last: Scalars['Int'];
  offset: Scalars['Int'];
};


export type IidxRootQproPartsArgs = {
  unlockedBy?: Maybe<Scalars['ID']>;
  version?: Maybe<Scalars['Int']>;
};


export type IidxRootUiPartsArgs = {
  unlockedBy?: Maybe<Scalars['ID']>;
  version?: Maybe<Scalars['Int']>;
};


export type IidxRootSongsArgs = {
  version?: Maybe<Scalars['Int']>;
  useDefaultGame?: Maybe<Scalars['Boolean']>;
  playedBy?: Maybe<Array<Scalars['ID']>>;
  last: Scalars['Int'];
  offset: Scalars['Int'];
  search?: Maybe<Scalars['String']>;
};


export type IidxRootBingoCardsArgs = {
  last: Scalars['Int'];
  offset: Scalars['Int'];
};


export type IidxRootBingoCardArgs = {
  id: Scalars['ID'];
};


export type IidxRootCompetitionsArgs = {
  last: Scalars['Int'];
  offset: Scalars['Int'];
};


export type IidxRootCompetitionArgs = {
  id: Scalars['ID'];
};


export type IidxRootNewsArgs = {
  last: Scalars['Int'];
  offset: Scalars['Int'];
};


export type IidxRootRevisionArgs = {
  id: Scalars['ID'];
};


export type IidxRootChartArgs = {
  id: Scalars['ID'];
};


export type IidxRootSongArgs = {
  id: Scalars['ID'];
};

export type IidxSong = Node & {
  __typename?: 'IIDXSong';
  id: Scalars['ID'];
  title: Scalars['String'];
  rtitle: Scalars['String'];
  artist: Scalars['String'];
  genre: Scalars['String'];
  charts: Array<IidxChart>;
};


export type IidxSongChartsArgs = {
  limit?: Maybe<Scalars['Int']>;
  version?: Maybe<Scalars['Int']>;
  useDefaultGame?: Maybe<Scalars['Boolean']>;
  playStyle?: Maybe<IidxPlayStyle>;
};

export type IidxSongConnection = {
  __typename?: 'IIDXSongConnection';
  totalItems: Scalars['Int'];
  items: Array<IidxSong>;
};

export type IidxuiPart = Node & {
  __typename?: 'IIDXUIPart';
  partId: Scalars['Int'];
  text: Scalars['String'];
  id: Scalars['ID'];
  type: IidxuiPartType;
};

export enum IidxuiPartType {
  Voice = 'VOICE',
  Cover = 'COVER',
  Beam = 'BEAM',
  Frame = 'FRAME',
  Notes = 'NOTES',
  Bgm = 'BGM',
  Burst = 'BURST',
  BurstSize = 'BURST_SIZE',
  Judge = 'JUDGE',
  Fullcombo = 'FULLCOMBO',
  Turntable = 'TURNTABLE',
  Effector = 'EFFECTOR',
  NoteStart = 'NOTE_START',
  HcnSkin = 'HCN_SKIN',
  PremiumAreaSkin = 'PREMIUM_AREA_SKIN',
  QproEmote = 'QPRO_EMOTE'
}

export type IidxuiSettings = {
  __typename?: 'IIDXUISettings';
  availableFlags: Array<IidxCustomizationFlag>;
  flags: Array<IidxCustomizationFlag>;
  frame?: Maybe<IidxuiPart>;
  turntable?: Maybe<IidxuiPart>;
  explosion?: Maybe<IidxuiPart>;
  explosionSize?: Maybe<IidxuiPart>;
  systemBgm?: Maybe<IidxuiPart>;
  lane?: Maybe<IidxuiPart>;
  categoryVoice?: Maybe<IidxuiPart>;
  noteskin?: Maybe<IidxuiPart>;
  fullcombo?: Maybe<IidxuiPart>;
  beams?: Maybe<IidxuiPart>;
  judge?: Maybe<IidxuiPart>;
  pacemaker?: Maybe<IidxuiPart>;
  effectorDefault?: Maybe<IidxuiPart>;
  noteStart?: Maybe<IidxuiPart>;
  hcnSkin?: Maybe<IidxuiPart>;
  arenaEmote1?: Maybe<IidxuiPart>;
  arenaEmote2?: Maybe<IidxuiPart>;
  arenaEmote3?: Maybe<IidxuiPart>;
};

export type IidxWeekly = Node & {
  __typename?: 'IIDXWeekly';
  id: Scalars['ID'];
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
  song?: Maybe<IidxSong>;
  topRankers: Array<IidxWeeklyScore>;
  charts: Array<IidxChart>;
};

export type IidxWeeklyScore = {
  __typename?: 'IIDXWeeklyScore';
  chart: IidxChart;
  play: IidxPlay;
  rank: Scalars['Int'];
  participants: Scalars['Int'];
  profile: IidxProfile;
};

export type IncomeAnalyticsData = {
  __typename?: 'IncomeAnalyticsData';
  transactions: Scalars['Int'];
  serviceCredit: Scalars['Int'];
  freePlay: Scalars['Int'];
  charge: Scalars['Int'];
  spent: Scalars['Int'];
};

export type IntParameterInfo = ParameterInfo & {
  __typename?: 'IntParameterInfo';
  type: ParameterType;
  minValue?: Maybe<Scalars['Int']>;
  maxValue?: Maybe<Scalars['Int']>;
};

export type JoinCompetition = {
  __typename?: 'JoinCompetition';
  ok: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type JubeatProfile = Node & {
  __typename?: 'JubeatProfile';
  name: Scalars['String'];
  playerId: Scalars['Int'];
  accessed: Scalars['DateTime'];
  id: Scalars['ID'];
  registered: Scalars['DateTime'];
};

export type JubeatProfileConnection = {
  __typename?: 'JubeatProfileConnection';
  totalItems: Scalars['Int'];
  items: Array<JubeatProfile>;
};

export type JubeatRoot = {
  __typename?: 'JubeatRoot';
  profileLookup?: Maybe<JubeatProfile>;
  profileSearch?: Maybe<JubeatProfileConnection>;
};


export type JubeatRootProfileLookupArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type JubeatRootProfileSearchArgs = {
  name?: Maybe<Scalars['String']>;
  lastPlay?: Maybe<Scalars['Int']>;
  last: Scalars['Int'];
  offset: Scalars['Int'];
};

export type LeaveCompetition = {
  __typename?: 'LeaveCompetition';
  ok: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  dismissNotification?: Maybe<DismissNotification>;
  createShop?: Maybe<CreateShop>;
  editShop?: Maybe<EditShop>;
  removeMembership?: Maybe<RemoveMembership>;
  addMembership?: Maybe<AddMembership>;
  createCabinet?: Maybe<CreateCabinet>;
  unsetParameter?: Maybe<UnsetParameter>;
  setParameter?: Maybe<SetParameter>;
  iidxSetQpro?: Maybe<SetQpro>;
  iidxSetProfileData?: Maybe<SetProfileData>;
  iidxSetUiSettings?: Maybe<SetUiSettings>;
  iidxSetNewsSettings?: Maybe<SetNewsSettings>;
  iidxRemoveRival?: Maybe<RemoveRival>;
  iidxAddRival?: Maybe<AddRival>;
  iidxCreateBingoCard?: Maybe<CreateBingoCard>;
  iidxSetBingoCard?: Maybe<SetBingoCard>;
  iidxCreateCompetition?: Maybe<CreateCompetition>;
  iidxJoinCompetition?: Maybe<JoinCompetition>;
  iidxLeaveCompetition?: Maybe<LeaveCompetition>;
  addCard?: Maybe<AddCard>;
  deleteCard?: Maybe<DeleteCard>;
};


export type MutationDismissNotificationArgs = {
  id: Scalars['ID'];
};


export type MutationCreateShopArgs = {
  area: Scalars['ID'];
  name: Scalars['String'];
  owner?: Maybe<Scalars['ID']>;
};


export type MutationEditShopArgs = {
  area?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  operatorPin?: Maybe<Scalars['String']>;
  shop: Scalars['ID'];
};


export type MutationRemoveMembershipArgs = {
  id: Scalars['ID'];
};


export type MutationAddMembershipArgs = {
  account: Scalars['ID'];
  entity: Scalars['ID'];
  role: ShopUserRole;
};


export type MutationCreateCabinetArgs = {
  description: Scalars['String'];
  enabled: Scalars['Boolean'];
  hidden: Scalars['Boolean'];
  name: Scalars['String'];
  natPort?: Maybe<Scalars['Int']>;
  pcbid?: Maybe<Scalars['String']>;
  shop: Scalars['ID'];
};


export type MutationUnsetParameterArgs = {
  node?: Maybe<Scalars['ID']>;
  parameter: Scalars['ID'];
};


export type MutationSetParameterArgs = {
  forced?: Maybe<Scalars['Boolean']>;
  node?: Maybe<Scalars['ID']>;
  parameter: Scalars['ID'];
  value?: Maybe<Scalars['String']>;
};


export type MutationIidxSetQproArgs = {
  body: Scalars['ID'];
  face: Scalars['ID'];
  hair: Scalars['ID'];
  hand: Scalars['ID'];
  head: Scalars['ID'];
  profile?: Maybe<Scalars['ID']>;
  version?: Maybe<Scalars['Int']>;
};


export type MutationIidxSetProfileDataArgs = {
  area?: Maybe<Scalars['ID']>;
  arenaComment1?: Maybe<Scalars['String']>;
  arenaComment2?: Maybe<Scalars['String']>;
  arenaComment3?: Maybe<Scalars['String']>;
  arenaComment4?: Maybe<Scalars['String']>;
  forceUnlockCurrent?: Maybe<Scalars['Boolean']>;
  forceUnlockPrevious?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  profile?: Maybe<Scalars['ID']>;
};


export type MutationIidxSetUiSettingsArgs = {
  arenaEmote1?: Maybe<Scalars['ID']>;
  arenaEmote2?: Maybe<Scalars['ID']>;
  arenaEmote3?: Maybe<Scalars['ID']>;
  beams?: Maybe<Scalars['ID']>;
  categoryVoice?: Maybe<Scalars['ID']>;
  effectorDefault?: Maybe<Scalars['ID']>;
  explosion?: Maybe<Scalars['ID']>;
  explosionSize?: Maybe<Scalars['ID']>;
  flags?: Maybe<Array<IidxCustomizationFlag>>;
  frame?: Maybe<Scalars['ID']>;
  fullcombo?: Maybe<Scalars['ID']>;
  hcnSkin?: Maybe<Scalars['ID']>;
  judge?: Maybe<Scalars['ID']>;
  lane?: Maybe<Scalars['ID']>;
  noteStart?: Maybe<Scalars['ID']>;
  noteskin?: Maybe<Scalars['ID']>;
  pacemaker?: Maybe<Scalars['ID']>;
  profile?: Maybe<Scalars['ID']>;
  systemBgm?: Maybe<Scalars['ID']>;
  turntable?: Maybe<Scalars['ID']>;
  version?: Maybe<Scalars['Int']>;
};


export type MutationIidxSetNewsSettingsArgs = {
  charts?: Maybe<Array<IidxNewsChart>>;
  profile?: Maybe<Scalars['ID']>;
  topics?: Maybe<Array<IidxNewsTopic>>;
  types?: Maybe<Array<IidxNewsType>>;
};


export type MutationIidxRemoveRivalArgs = {
  id: Scalars['ID'];
};


export type MutationIidxAddRivalArgs = {
  playStyle: IidxPlayStyle;
  profile?: Maybe<Scalars['ID']>;
  rival: Scalars['ID'];
  version?: Maybe<Scalars['Int']>;
};


export type MutationIidxCreateBingoCardArgs = {
  profile?: Maybe<Scalars['ID']>;
  size: IidxBingoCardSize;
  slots: Array<IidxBingoCardSlotInput>;
  title: Scalars['String'];
};


export type MutationIidxSetBingoCardArgs = {
  card: Scalars['ID'];
  profile?: Maybe<Scalars['ID']>;
};


export type MutationIidxCreateCompetitionArgs = {
  charts: Array<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  end: Scalars['DateTime'];
  profile?: Maybe<Scalars['ID']>;
  start: Scalars['DateTime'];
  subtitle?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};


export type MutationIidxJoinCompetitionArgs = {
  competition: Scalars['ID'];
  profile?: Maybe<Scalars['ID']>;
};


export type MutationIidxLeaveCompetitionArgs = {
  competition: Scalars['ID'];
  profile?: Maybe<Scalars['ID']>;
};


export type MutationAddCardArgs = {
  account?: Maybe<Scalars['ID']>;
  tagId: Scalars['String'];
  tagType: CardType;
};


export type MutationDeleteCardArgs = {
  card: Scalars['ID'];
};

export enum NewsType {
  ClearLamp = 'CLEAR_LAMP',
  Score = 'SCORE'
}

export type Node = {
  id: Scalars['ID'];
};

export type Notification = Node & {
  __typename?: 'Notification';
  id: Scalars['ID'];
  pending: Scalars['Boolean'];
  sent: Scalars['DateTime'];
  type: NotificationType;
  topic: NotificationTopic;
  customTopic?: Maybe<Scalars['String']>;
  severity: NotificationSeverity;
  data?: Maybe<Scalars['String']>;
};

export type NotificationConnection = {
  __typename?: 'NotificationConnection';
  totalItems: Scalars['Int'];
  items: Array<Notification>;
};

export enum NotificationSeverity {
  Low = 'Low',
  Medium = 'Medium',
  High = 'High'
}

export enum NotificationTopic {
  UnionInvitation = 'UNION_INVITATION',
  RoleInvitation = 'ROLE_INVITATION',
  ScoreImport = 'SCORE_IMPORT',
  GamePlayArtifact = 'GAME_PLAY_ARTIFACT',
  Other = 'OTHER'
}

export enum NotificationType {
  Informational = 'INFORMATIONAL',
  ApprovalRequest = 'APPROVAL_REQUEST'
}

export type Notifications = {
  __typename?: 'Notifications';
  pendingItems: Scalars['Int'];
  highestSeverity?: Maybe<NotificationSeverity>;
  notifications: NotificationConnection;
};


export type NotificationsNotificationsArgs = {
  last: Scalars['Int'];
  offset: Scalars['Int'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
  endCursor?: Maybe<Scalars['String']>;
};

export type Parameter = Node & {
  __typename?: 'Parameter';
  id: Scalars['ID'];
  module: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  defaultValue: ParameterValue;
  activeValue?: Maybe<ParameterValue>;
  values?: Maybe<Array<ParameterValue>>;
  info: ParameterInfo;
};


export type ParameterActiveValueArgs = {
  shop?: Maybe<Scalars['ID']>;
};


export type ParameterValuesArgs = {
  node?: Maybe<Scalars['ID']>;
};

export type ParameterInfo = {
  type: ParameterType;
};

export enum ParameterOrigin {
  Default = 'DEFAULT',
  Network = 'NETWORK',
  Union = 'UNION',
  Shop = 'SHOP'
}

export enum ParameterType {
  Integer = 'INTEGER',
  Float = 'FLOAT',
  String = 'STRING',
  Enum = 'ENUM',
  Boolean = 'BOOLEAN'
}

export type ParameterValue = {
  __typename?: 'ParameterValue';
  id: Scalars['ID'];
  origin: ParameterOrigin;
  value: Scalars['String'];
  forced: Scalars['Boolean'];
};

export type PlayAnalyticsData = {
  __typename?: 'PlayAnalyticsData';
  plays: Scalars['Int'];
};

export type QmaAnswerAccuracy = {
  __typename?: 'QMAAnswerAccuracy';
  genre: QmaGenre;
  accuracyRate: Scalars['Float'];
};

export enum QmaCharacter {
  Leon = 'LEON',
  Serious = 'SERIOUS',
  Kaile = 'KAILE',
  Lask = 'LASK',
  Thanders = 'THANDERS',
  Taiga = 'TAIGA',
  Yu = 'YU',
  Hald = 'HALD',
  Rick = 'RICK',
  Ruquia = 'RUQUIA',
  Shalon = 'SHALON',
  Clala = 'CLALA',
  Aloe = 'ALOE',
  Malariya = 'MALARIYA',
  Yuri = 'YURI',
  YangYang = 'YANG_YANG',
  Riel = 'RIEL',
  Aiko = 'AIKO',
  Media = 'MEDIA',
  Myu = 'MYU',
  Maya = 'MAYA',
  Vany = 'VANY',
  Vanessa = 'VANESSA'
}

export type QmaExam = Node & {
  __typename?: 'QMAExam';
  id: Scalars['ID'];
  title: Scalars['String'];
  subtitle: Scalars['String'];
  rankTill?: Maybe<Scalars['DateTime']>;
  priority: Scalars['Int'];
  isSealed: Scalars['Boolean'];
  game: Game;
  availableFrom: Scalars['DateTime'];
  availableUntil?: Maybe<Scalars['DateTime']>;
  details: Scalars['String'];
  textureId: Scalars['Int'];
  scores: Array<QmaExamRank>;
};

export type QmaExamRank = {
  __typename?: 'QMAExamRank';
  profile: QmaProfile;
  exam: QmaExam;
  attempts: Scalars['Int'];
  bestScore: Scalars['Int'];
  timestamp: Scalars['DateTime'];
};

export type QmaGameData = Node & {
  __typename?: 'QMAGameData';
  id: Scalars['ID'];
  character: QmaCharacter;
  qLevel: Scalars['Int'];
};

export enum QmaGenre {
  NonGenre = 'NON_GENRE',
  AnimeGames = 'ANIME_GAMES',
  Sports = 'SPORTS',
  Entertainment = 'ENTERTAINMENT',
  Lifestyle = 'LIFESTYLE',
  Society = 'SOCIETY',
  Humanities = 'HUMANITIES',
  Science = 'SCIENCE'
}

export type QmaProfile = Node & {
  __typename?: 'QMAProfile';
  id: Scalars['ID'];
  name: Scalars['String'];
  studentId: Scalars['String'];
  accessed: Scalars['DateTime'];
  registered: Scalars['DateTime'];
  gameData: Array<QmaGameData>;
  defaultGame: Game;
  character: QmaCharacter;
  qLevel: Scalars['Int'];
  answerAccuracy: Array<QmaAnswerAccuracy>;
};

export type QmaProfileConnection = {
  __typename?: 'QMAProfileConnection';
  totalItems: Scalars['Int'];
  items: Array<QmaProfile>;
};

export type QmaRoot = {
  __typename?: 'QMARoot';
  exam: QmaExam;
  exams: Array<QmaExam>;
  profileLookup?: Maybe<QmaProfile>;
  profileSearch?: Maybe<QmaProfileConnection>;
};


export type QmaRootExamArgs = {
  id: Scalars['ID'];
};


export type QmaRootProfileLookupArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QmaRootProfileSearchArgs = {
  name?: Maybe<Scalars['String']>;
  lastPlay?: Maybe<Scalars['Int']>;
  last: Scalars['Int'];
  offset: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  userNotifications?: Maybe<Notifications>;
  apiDetails: ApiDetails;
  statistics: Array<AnalyticsItem>;
  parameters: Array<Parameter>;
  shopUnions: Array<ShopUnion>;
  shops: Array<Shop>;
  cabinets: Array<Cabinet>;
  areas: Array<Area>;
  shopUnion?: Maybe<ShopUnion>;
  shop?: Maybe<Shop>;
  qma: QmaRoot;
  sdvx: SdvxRoot;
  jubeat: JubeatRoot;
  iidx: IidxRoot;
  bst: BstRoot;
  bbc: BbcRoot;
  me: Account;
  user?: Maybe<Account>;
  users: Array<Account>;
  series: Array<Series>;
  node?: Maybe<Node>;
};


export type QueryStatisticsArgs = {
  query: AnalyticsQuery;
  groupBy?: Maybe<AnalyticsGroup>;
  filterBy?: Maybe<Scalars['ID']>;
  timeRange?: Maybe<AnalyticsAbsoluteTimeRange>;
  since?: Maybe<AnalyticsRelativeTimeRange>;
  bucketSize?: Maybe<AnalyticsBucketSize>;
};


export type QueryShopsArgs = {
  area?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};


export type QueryCabinetsArgs = {
  tiedTo?: Maybe<Scalars['ID']>;
};


export type QueryShopUnionArgs = {
  id: Scalars['ID'];
};


export type QueryShopArgs = {
  id: Scalars['ID'];
};


export type QueryUserArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryUsersArgs = {
  cardTag?: Maybe<Scalars['String']>;
};


export type QuerySeriesArgs = {
  shortname?: Maybe<Scalars['String']>;
};


export type QueryNodeArgs = {
  id: Scalars['ID'];
};

export type RemoveMembership = {
  __typename?: 'RemoveMembership';
  ok: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type RemoveRival = {
  __typename?: 'RemoveRival';
  ok: Scalars['Boolean'];
};

export type SdvxProfile = Node & {
  __typename?: 'SDVXProfile';
  name: Scalars['String'];
  playerId: Scalars['Int'];
  registered: Scalars['DateTime'];
  accessed: Scalars['DateTime'];
  id: Scalars['ID'];
};

export type SdvxProfileConnection = {
  __typename?: 'SDVXProfileConnection';
  totalItems: Scalars['Int'];
  items: Array<SdvxProfile>;
};

export type SdvxRoot = {
  __typename?: 'SDVXRoot';
  profileLookup?: Maybe<SdvxProfile>;
  profileSearch: SdvxProfileConnection;
};


export type SdvxRootProfileLookupArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type SdvxRootProfileSearchArgs = {
  name?: Maybe<Scalars['String']>;
  lastPlay?: Maybe<Scalars['Int']>;
  last: Scalars['Int'];
  offset: Scalars['Int'];
};

export type Series = {
  __typename?: 'Series';
  name: Scalars['String'];
  shortname: Scalars['String'];
  games: Array<Game>;
};

export type SetBingoCard = {
  __typename?: 'SetBingoCard';
  ok: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type SetNewsSettings = {
  __typename?: 'SetNewsSettings';
  ok: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  newsSettings?: Maybe<IidxNewsSettings>;
};

export type SetParameter = {
  __typename?: 'SetParameter';
  ok: Scalars['Boolean'];
  value?: Maybe<ParameterValue>;
};

export type SetProfileData = {
  __typename?: 'SetProfileData';
  ok: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  profile?: Maybe<IidxProfile>;
};

export type SetQpro = {
  __typename?: 'SetQpro';
  ok: Scalars['Boolean'];
};

export type SetUiSettings = {
  __typename?: 'SetUISettings';
  ok: Scalars['Boolean'];
};

export type Shop = Node & {
  __typename?: 'Shop';
  id: Scalars['ID'];
  name: Scalars['String'];
  area?: Maybe<Area>;
  union?: Maybe<ShopUnion>;
  cabinets?: Maybe<Array<Cabinet>>;
  acl: Array<AclFlag>;
  aclAudit: Array<AclFlag>;
  aclAnalytics: Array<AclFlag>;
  aclCabinets: Array<AclFlag>;
  aclUsers: Array<AclFlag>;
  aclParameters: Array<AclFlag>;
  users?: Maybe<Array<UserMembership>>;
  transactions?: Maybe<WalletTransactionConnection>;
};


export type ShopTransactionsArgs = {
  last: Scalars['Int'];
  offset: Scalars['Int'];
};

export type ShopUnion = Node & {
  __typename?: 'ShopUnion';
  id: Scalars['ID'];
  name: Scalars['String'];
  shops: Array<Shop>;
  transactions?: Maybe<WalletTransactionConnection>;
  aclAnalytics: Array<AclFlag>;
  aclParameters: Array<AclFlag>;
};


export type ShopUnionTransactionsArgs = {
  last: Scalars['Int'];
  offset: Scalars['Int'];
};

export type ShopUnionUserMembership = Node & {
  __typename?: 'ShopUnionUserMembership';
  id: Scalars['ID'];
  role: ShopUserRole;
  account?: Maybe<Account>;
  union?: Maybe<ShopUnion>;
};

export type ShopUserMembership = Node & {
  __typename?: 'ShopUserMembership';
  id: Scalars['ID'];
  account?: Maybe<Account>;
  shop?: Maybe<Shop>;
  role: ShopUserRole;
};

export enum ShopUserRole {
  Manager = 'Manager',
  Operator = 'Operator',
  Helper = 'Helper'
}

export enum SongGenre {
  Konami = 'KONAMI',
  Touhou = 'TOUHOU',
  Anime = 'ANIME',
  Exittunes = 'EXITTUNES',
  Other = 'OTHER'
}

export type UnsetParameter = {
  __typename?: 'UnsetParameter';
  ok: Scalars['Boolean'];
};

export type UserMembership = ShopUserMembership | ShopUnionUserMembership;

export type Wallet = Node & {
  __typename?: 'Wallet';
  balance: Scalars['Int'];
  account?: Maybe<Account>;
  union?: Maybe<ShopUnion>;
  id: Scalars['ID'];
  transactions: WalletTransactionConnection;
};


export type WalletTransactionsArgs = {
  last: Scalars['Int'];
  offset: Scalars['Int'];
};

export type WalletTransaction = Node & {
  __typename?: 'WalletTransaction';
  timestamp: Scalars['DateTime'];
  charge: Scalars['Int'];
  service: Scalars['Int'];
  id: Scalars['ID'];
  detail: Scalars['String'];
  series?: Maybe<Series>;
  game?: Maybe<Game>;
  cabinet: Cabinet;
  shop: Shop;
  isFreeplay: Scalars['Boolean'];
  balanceAfter?: Maybe<Scalars['Int']>;
};

export type WalletTransactionConnection = {
  __typename?: 'WalletTransactionConnection';
  totalItems: Scalars['Int'];
  items: Array<WalletTransaction>;
};

export type StatisticsQueryVariables = Exact<{
  query: AnalyticsQuery;
  filterBy?: Maybe<Scalars['ID']>;
  groupBy?: Maybe<AnalyticsGroup>;
  since?: Maybe<AnalyticsRelativeTimeRange>;
  timeRange?: Maybe<AnalyticsAbsoluteTimeRange>;
  bucketSize?: Maybe<AnalyticsBucketSize>;
}>;


export type StatisticsQuery = (
  { __typename?: 'Query' }
  & { statistics: Array<(
    { __typename?: 'AnalyticsItem' }
    & Pick<AnalyticsItem, 'periodStart' | 'isEmptyBucket'>
    & { group?: Maybe<(
      { __typename?: 'Game' }
      & { gameName: Game['name'] }
    ) | (
      { __typename?: 'Series' }
      & { seriesName: Series['name'] }
    ) | (
      { __typename?: 'Shop' }
      & Pick<Shop, 'id'>
      & { shopName: Shop['name'] }
    ) | (
      { __typename?: 'ShopUnion' }
      & Pick<ShopUnion, 'id'>
      & { unionName: ShopUnion['name'] }
    ) | (
      { __typename?: 'Cabinet' }
      & Pick<Cabinet, 'id'>
      & { cabinetName: Cabinet['name'] }
      & { shop?: Maybe<(
        { __typename?: 'Shop' }
        & Pick<Shop, 'id' | 'name'>
      )> }
    )>, data: (
      { __typename?: 'IncomeAnalyticsData' }
      & Pick<IncomeAnalyticsData, 'transactions' | 'serviceCredit' | 'freePlay' | 'charge' | 'spent'>
    ) | (
      { __typename?: 'PlayAnalyticsData' }
      & Pick<PlayAnalyticsData, 'plays'>
    ) }
  )> }
);

export type UserDetailsQueryVariables = Exact<{ [key: string]: never; }>;


export type UserDetailsQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'Account' }
    & Pick<Account, 'id' | 'networkOperator' | 'aclShop'>
    & { shops?: Maybe<Array<(
      { __typename?: 'Shop' }
      & Pick<Shop, 'id'>
    )>>, shopUnions?: Maybe<Array<(
      { __typename?: 'ShopUnion' }
      & Pick<ShopUnion, 'id'>
    )>> }
  ) }
);

export type ServiceUrlsQueryVariables = Exact<{ [key: string]: never; }>;


export type ServiceUrlsQuery = (
  { __typename?: 'Query' }
  & { apiDetails: (
    { __typename?: 'APIDetails' }
    & Pick<ApiDetails, 'accountSettingsUrl' | 'apiDocsUrl' | 'assetsBaseUrl' | 'scoreImportUrl'>
  ) }
);

export type CardListQueryVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
}>;


export type CardListQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'Account' }
    & Pick<Account, 'id' | 'email'>
    & { cards?: Maybe<Array<(
      { __typename?: 'Card' }
      & Pick<Card, 'id' | 'tagId' | 'tagType' | 'displayId' | 'registered' | 'accessed'>
    )>> }
  )> }
);

export type AddCardMutationVariables = Exact<{
  accountId?: Maybe<Scalars['ID']>;
  tagId: Scalars['String'];
  tagType: CardType;
}>;


export type AddCardMutation = (
  { __typename?: 'Mutation' }
  & { addCard?: Maybe<(
    { __typename?: 'AddCard' }
    & Pick<AddCard, 'ok'>
    & { card?: Maybe<(
      { __typename?: 'Card' }
      & Pick<Card, 'id'>
    )> }
  )> }
);

export type DeleteCardMutationVariables = Exact<{
  card: Scalars['ID'];
}>;


export type DeleteCardMutation = (
  { __typename?: 'Mutation' }
  & { deleteCard?: Maybe<(
    { __typename?: 'DeleteCard' }
    & Pick<DeleteCard, 'ok'>
  )> }
);

export type GameListQueryVariables = Exact<{
  game?: Maybe<Scalars['String']>;
}>;


export type GameListQuery = (
  { __typename?: 'Query' }
  & { series: Array<(
    { __typename?: 'Series' }
    & Pick<Series, 'name' | 'shortname'>
    & { games: Array<(
      { __typename?: 'Game' }
      & Pick<Game, 'version' | 'name'>
    )> }
  )> }
);

export type UserGameProfileQueryVariables = Exact<{
  game: Scalars['String'];
}>;


export type UserGameProfileQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'Account' }
    & Pick<Account, 'id'>
    & { gameProfile?: Maybe<(
      { __typename?: 'BishiBashiChannelProfile' }
      & Pick<BishiBashiChannelProfile, 'id'>
    ) | (
      { __typename?: 'BeatstreamProfile' }
      & Pick<BeatstreamProfile, 'id'>
    ) | (
      { __typename?: 'IIDXProfile' }
      & Pick<IidxProfile, 'id'>
    ) | (
      { __typename?: 'JubeatProfile' }
      & Pick<JubeatProfile, 'id'>
    ) | (
      { __typename?: 'SDVXProfile' }
      & Pick<SdvxProfile, 'id'>
    ) | (
      { __typename?: 'QMAProfile' }
      & Pick<QmaProfile, 'id'>
    )> }
  ) }
);

export type BbcProfileIdQueryVariables = Exact<{ [key: string]: never; }>;


export type BbcProfileIdQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'Account' }
    & Pick<Account, 'id'>
    & { gameProfile?: Maybe<(
      { __typename?: 'BishiBashiChannelProfile' }
      & Pick<BishiBashiChannelProfile, 'id'>
    ) | (
      { __typename?: 'BeatstreamProfile' }
      & Pick<BeatstreamProfile, 'id'>
    ) | (
      { __typename?: 'IIDXProfile' }
      & Pick<IidxProfile, 'id'>
    ) | (
      { __typename?: 'JubeatProfile' }
      & Pick<JubeatProfile, 'id'>
    ) | (
      { __typename?: 'SDVXProfile' }
      & Pick<SdvxProfile, 'id'>
    ) | (
      { __typename?: 'QMAProfile' }
      & Pick<QmaProfile, 'id'>
    )> }
  ) }
);

export type BbcProfileSearchQueryVariables = Exact<{
  last: Scalars['Int'];
  offset: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  lastPlay?: Maybe<Scalars['Int']>;
}>;


export type BbcProfileSearchQuery = (
  { __typename?: 'Query' }
  & { bbc: (
    { __typename?: 'BBCRoot' }
    & { profileSearch: (
      { __typename?: 'BishiBashiChannelProfileConnection' }
      & Pick<BishiBashiChannelProfileConnection, 'totalItems'>
      & { items: Array<(
        { __typename?: 'BishiBashiChannelProfile' }
        & Pick<BishiBashiChannelProfile, 'id' | 'name' | 'accessed'>
      )> }
    ) }
  ) }
);

export type BbcProfileQueryVariables = Exact<{
  profile?: Maybe<Scalars['ID']>;
}>;


export type BbcProfileQuery = (
  { __typename?: 'Query' }
  & { bbc: (
    { __typename?: 'BBCRoot' }
    & { profileLookup?: Maybe<(
      { __typename?: 'BishiBashiChannelProfile' }
      & Pick<BishiBashiChannelProfile, 'id' | 'name' | 'accessed'>
    )> }
  ) }
);

export type BstProfileIdQueryVariables = Exact<{ [key: string]: never; }>;


export type BstProfileIdQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'Account' }
    & Pick<Account, 'id'>
    & { gameProfile?: Maybe<(
      { __typename?: 'BishiBashiChannelProfile' }
      & Pick<BishiBashiChannelProfile, 'id'>
    ) | (
      { __typename?: 'BeatstreamProfile' }
      & Pick<BeatstreamProfile, 'id'>
    ) | (
      { __typename?: 'IIDXProfile' }
      & Pick<IidxProfile, 'id'>
    ) | (
      { __typename?: 'JubeatProfile' }
      & Pick<JubeatProfile, 'id'>
    ) | (
      { __typename?: 'SDVXProfile' }
      & Pick<SdvxProfile, 'id'>
    ) | (
      { __typename?: 'QMAProfile' }
      & Pick<QmaProfile, 'id'>
    )> }
  ) }
);

export type BstProfileSearchQueryVariables = Exact<{
  last: Scalars['Int'];
  offset: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  lastPlay?: Maybe<Scalars['Int']>;
}>;


export type BstProfileSearchQuery = (
  { __typename?: 'Query' }
  & { bst: (
    { __typename?: 'BSTRoot' }
    & { profileSearch: (
      { __typename?: 'BeatstreamProfileConnection' }
      & Pick<BeatstreamProfileConnection, 'totalItems'>
      & { items: Array<(
        { __typename?: 'BeatstreamProfile' }
        & Pick<BeatstreamProfile, 'id' | 'playerId' | 'name' | 'registered' | 'accessed' | 'courseRank'>
      )> }
    ) }
  ) }
);

export type BstProfileQueryVariables = Exact<{
  profile?: Maybe<Scalars['ID']>;
  withBattles?: Maybe<Scalars['Boolean']>;
}>;


export type BstProfileQuery = (
  { __typename?: 'Query' }
  & { bst: (
    { __typename?: 'BSTRoot' }
    & { profileLookup?: Maybe<(
      { __typename?: 'BeatstreamProfile' }
      & Pick<BeatstreamProfile, 'id' | 'playerId' | 'name' | 'registered' | 'accessed' | 'courseRank'>
      & { scoreBattle?: Maybe<(
        { __typename?: 'BeatstreamBattleComparison' }
        & Pick<BeatstreamBattleComparison, 'wins' | 'losses'>
      )> }
    )> }
  ) }
);

export type BstPlaysQueryVariables = Exact<{
  profile?: Maybe<Scalars['ID']>;
  last: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type BstPlaysQuery = (
  { __typename?: 'Query' }
  & { bst: (
    { __typename?: 'BSTRoot' }
    & { profileLookup?: Maybe<(
      { __typename?: 'BeatstreamProfile' }
      & Pick<BeatstreamProfile, 'id'>
      & { playHistogram?: Maybe<Array<(
        { __typename?: 'BeatstreamPlayHistogramEntry' }
        & Pick<BeatstreamPlayHistogramEntry, 'period' | 'plays'>
      )>>, plays: (
        { __typename?: 'BeatstreamPlayConnection' }
        & Pick<BeatstreamPlayConnection, 'totalItems'>
        & { items: Array<(
          { __typename?: 'BeatstreamPlay' }
          & Pick<BeatstreamPlay, 'id' | 'timestamp' | 'score' | 'medal' | 'grade' | 'judgeMiss' | 'judgeFine' | 'judgeGreat' | 'judgeFantastic'>
          & { game: (
            { __typename?: 'Game' }
            & Pick<Game, 'name'>
          ), chart?: Maybe<(
            { __typename?: 'BeatstreamChart' }
            & Pick<BeatstreamChart, 'id' | 'difficulty' | 'level' | 'hasCustomJacket'>
            & { revision?: Maybe<(
              { __typename?: 'BeatstreamRevision' }
              & Pick<BeatstreamRevision, 'id'>
            )> }
          )>, song: (
            { __typename?: 'BeatstreamSong' }
            & Pick<BeatstreamSong, 'id' | 'title' | 'artist' | 'filename'>
          ), coursePlay?: Maybe<(
            { __typename?: 'BeatstreamCourseScore' }
            & Pick<BeatstreamCourseScore, 'id' | 'rank' | 'stagesCleared'>
            & { plays: Array<(
              { __typename?: 'BeatstreamPlay' }
              & Pick<BeatstreamPlay, 'id'>
            )> }
          )>, shop?: Maybe<(
            { __typename?: 'Shop' }
            & Pick<Shop, 'id' | 'name'>
          )> }
        )> }
      ) }
    )> }
  ) }
);

export type BstPlayHistoryQueryVariables = Exact<{
  profile?: Maybe<Scalars['ID']>;
  revision: Scalars['ID'];
}>;


export type BstPlayHistoryQuery = (
  { __typename?: 'Query' }
  & { bst: (
    { __typename?: 'BSTRoot' }
    & { profileLookup?: Maybe<(
      { __typename?: 'BeatstreamProfile' }
      & Pick<BeatstreamProfile, 'id'>
      & { plays: (
        { __typename?: 'BeatstreamPlayConnection' }
        & Pick<BeatstreamPlayConnection, 'totalItems'>
        & { items: Array<(
          { __typename?: 'BeatstreamPlay' }
          & Pick<BeatstreamPlay, 'id' | 'timestamp' | 'score' | 'medal' | 'grade'>
          & { shop?: Maybe<(
            { __typename?: 'Shop' }
            & Pick<Shop, 'id' | 'name'>
          )> }
        )> }
      ) }
    )> }
  ) }
);

export type BstChartLeaderboardQueryVariables = Exact<{
  chart: Scalars['ID'];
}>;


export type BstChartLeaderboardQuery = (
  { __typename?: 'Query' }
  & { bst: (
    { __typename?: 'BSTRoot' }
    & { chart?: Maybe<(
      { __typename?: 'BeatstreamChart' }
      & Pick<BeatstreamChart, 'id' | 'difficulty' | 'level' | 'hasCustomJacket' | 'genre'>
      & { song: (
        { __typename?: 'BeatstreamSong' }
        & Pick<BeatstreamSong, 'id' | 'title' | 'subTitle' | 'artist' | 'filename' | 'bpmMin' | 'bpmMax'>
        & { introducedIn: (
          { __typename?: 'Game' }
          & Pick<Game, 'name'>
        ) }
      ), siblingCharts: Array<(
        { __typename?: 'BeatstreamChart' }
        & Pick<BeatstreamChart, 'id' | 'difficulty' | 'level'>
      )>, revision?: Maybe<(
        { __typename?: 'BeatstreamRevision' }
        & Pick<BeatstreamRevision, 'id'>
        & { highscores: Array<(
          { __typename?: 'BeatstreamHighscore' }
          & Pick<BeatstreamHighscore, 'score' | 'medal' | 'timestamp'>
          & { profile: (
            { __typename?: 'BeatstreamProfile' }
            & Pick<BeatstreamProfile, 'id' | 'name'>
          ), play: (
            { __typename?: 'BeatstreamPlay' }
            & Pick<BeatstreamPlay, 'id' | 'grade'>
          ), game: (
            { __typename?: 'Game' }
            & Pick<Game, 'name' | 'version'>
          ) }
        )> }
      )> }
    )> }
  ) }
);

export type BstSongListQueryVariables = Exact<{
  last: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type BstSongListQuery = (
  { __typename?: 'Query' }
  & { bst: (
    { __typename?: 'BSTRoot' }
    & { songs: (
      { __typename?: 'BeatstreamSongConnection' }
      & Pick<BeatstreamSongConnection, 'totalItems'>
      & { items: Array<(
        { __typename?: 'BeatstreamSong' }
        & Pick<BeatstreamSong, 'id' | 'title' | 'subTitle' | 'artist' | 'filename'>
      )> }
    ) }
  ) }
);

export type BstSongInfoQueryVariables = Exact<{
  song: Scalars['ID'];
}>;


export type BstSongInfoQuery = (
  { __typename?: 'Query' }
  & { bst: (
    { __typename?: 'BSTRoot' }
    & { song?: Maybe<(
      { __typename?: 'BeatstreamSong' }
      & Pick<BeatstreamSong, 'id' | 'title' | 'subTitle' | 'artist' | 'filename'>
      & { charts: Array<(
        { __typename?: 'BeatstreamChart' }
        & Pick<BeatstreamChart, 'id' | 'difficulty' | 'level' | 'hasCustomJacket'>
        & { game: (
          { __typename?: 'Game' }
          & Pick<Game, 'name' | 'version'>
        ) }
      )> }
    )> }
  ) }
);

export type BstCompareScoresQueryVariables = Exact<{
  last: Scalars['Int'];
  offset: Scalars['Int'];
  version?: Maybe<Scalars['Int']>;
  players: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type BstCompareScoresQuery = (
  { __typename?: 'Query' }
  & { bst: (
    { __typename?: 'BSTRoot' }
    & { songs: (
      { __typename?: 'BeatstreamSongConnection' }
      & Pick<BeatstreamSongConnection, 'totalItems'>
      & { items: Array<(
        { __typename?: 'BeatstreamSong' }
        & Pick<BeatstreamSong, 'id' | 'title' | 'artist'>
        & { charts: Array<(
          { __typename?: 'BeatstreamChart' }
          & Pick<BeatstreamChart, 'id' | 'difficulty' | 'level'>
          & { revision?: Maybe<(
            { __typename?: 'BeatstreamRevision' }
            & Pick<BeatstreamRevision, 'id'>
            & { highscores: Array<(
              { __typename?: 'BeatstreamHighscore' }
              & Pick<BeatstreamHighscore, 'profileId' | 'medal' | 'score'>
              & { play: (
                { __typename?: 'BeatstreamPlay' }
                & Pick<BeatstreamPlay, 'id' | 'grade'>
              ) }
            )> }
          )> }
        )> }
      )> }
    ) }
  ) }
);

export type IidxProfileIdQueryVariables = Exact<{ [key: string]: never; }>;


export type IidxProfileIdQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'Account' }
    & Pick<Account, 'id'>
    & { gameProfile?: Maybe<(
      { __typename?: 'BishiBashiChannelProfile' }
      & Pick<BishiBashiChannelProfile, 'id'>
    ) | (
      { __typename?: 'BeatstreamProfile' }
      & Pick<BeatstreamProfile, 'id'>
    ) | (
      { __typename?: 'IIDXProfile' }
      & Pick<IidxProfile, 'id'>
    ) | (
      { __typename?: 'JubeatProfile' }
      & Pick<JubeatProfile, 'id'>
    ) | (
      { __typename?: 'SDVXProfile' }
      & Pick<SdvxProfile, 'id'>
    ) | (
      { __typename?: 'QMAProfile' }
      & Pick<QmaProfile, 'id'>
    )> }
  ) }
);

export type IidxProfileSearchQueryVariables = Exact<{
  last: Scalars['Int'];
  offset: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  lastPlay?: Maybe<Scalars['Int']>;
}>;


export type IidxProfileSearchQuery = (
  { __typename?: 'Query' }
  & { iidx: (
    { __typename?: 'IIDXRoot' }
    & { profileSearch?: Maybe<(
      { __typename?: 'IIDXProfileConnection' }
      & Pick<IidxProfileConnection, 'totalItems'>
      & { items: Array<(
        { __typename?: 'IIDXProfile' }
        & Pick<IidxProfile, 'id' | 'playerId' | 'name' | 'registered' | 'accessed' | 'spArenaClass' | 'spDjPoints' | 'dpArenaClass' | 'dpDjPoints'>
        & { spNotesRadar?: Maybe<(
          { __typename?: 'IIDXNotesRadar' }
          & Pick<IidxNotesRadar, 'notes' | 'chord' | 'peak' | 'charge' | 'scratch' | 'soflan'>
        )>, spRank?: Maybe<(
          { __typename?: 'IIDXClassCourseScore' }
          & Pick<IidxClassCourseScore, 'id' | 'rank' | 'playStyle'>
        )>, dpNotesRadar?: Maybe<(
          { __typename?: 'IIDXNotesRadar' }
          & Pick<IidxNotesRadar, 'notes' | 'chord' | 'peak' | 'charge' | 'scratch' | 'soflan'>
        )>, dpRank?: Maybe<(
          { __typename?: 'IIDXClassCourseScore' }
          & Pick<IidxClassCourseScore, 'id' | 'rank' | 'playStyle'>
        )>, qpro: (
          { __typename?: 'IIDXQpro' }
          & { head: (
            { __typename?: 'IIDXQproPart' }
            & Pick<IidxQproPart, 'id' | 'name'>
          ), face: (
            { __typename?: 'IIDXQproPart' }
            & Pick<IidxQproPart, 'id' | 'name'>
          ), hair: (
            { __typename?: 'IIDXQproPart' }
            & Pick<IidxQproPart, 'id' | 'name'>
          ), hand: (
            { __typename?: 'IIDXQproPart' }
            & Pick<IidxQproPart, 'id' | 'name'>
          ), body: (
            { __typename?: 'IIDXQproPart' }
            & Pick<IidxQproPart, 'id' | 'name'>
          ) }
        ) }
      )> }
    )> }
  ) }
);

export type IidxProfileQueryVariables = Exact<{
  profile?: Maybe<Scalars['ID']>;
  withRivals?: Maybe<Scalars['Boolean']>;
  withClassRank?: Maybe<Scalars['Boolean']>;
  withQpro?: Maybe<Scalars['Boolean']>;
  withBattles?: Maybe<Scalars['Boolean']>;
  withMedals?: Maybe<Scalars['Boolean']>;
  withBingo?: Maybe<Scalars['Boolean']>;
}>;


export type IidxProfileQuery = (
  { __typename?: 'Query' }
  & { iidx: (
    { __typename?: 'IIDXRoot' }
    & { profileLookup?: Maybe<(
      { __typename?: 'IIDXProfile' }
      & Pick<IidxProfile, 'id' | 'playerId' | 'name' | 'registered' | 'accessed' | 'spDjPoints' | 'spArenaClass' | 'dpDjPoints' | 'dpArenaClass' | 'forceUnlockCurrent' | 'forceUnlockPrevious' | 'arenaComment1' | 'arenaComment2' | 'arenaComment3' | 'arenaComment4'>
      & { bingo?: Maybe<(
        { __typename?: 'IIDXBingoData' }
        & { myCard?: Maybe<(
          { __typename?: 'IIDXBingoCard' }
          & Pick<IidxBingoCard, 'id' | 'size'>
          & { playerProgress?: Maybe<(
            { __typename?: 'IIDXBingoCardProgress' }
            & Pick<IidxBingoCardProgress, 'id' | 'clearedSlots'>
          )> }
        )>, discipleCard?: Maybe<(
          { __typename?: 'IIDXBingoCard' }
          & Pick<IidxBingoCard, 'id' | 'size'>
          & { playerProgress?: Maybe<(
            { __typename?: 'IIDXBingoCardProgress' }
            & Pick<IidxBingoCardProgress, 'id' | 'clearedSlots'>
          )> }
        )> }
      )>, medals?: Maybe<(
        { __typename?: 'IIDXMedalStats' }
        & Pick<IidxMedalStats, 'platinum' | 'gold' | 'silver' | 'bronze' | 'black'>
      )>, qpro?: Maybe<(
        { __typename?: 'IIDXQpro' }
        & { head: (
          { __typename?: 'IIDXQproPart' }
          & Pick<IidxQproPart, 'id' | 'name'>
        ), face: (
          { __typename?: 'IIDXQproPart' }
          & Pick<IidxQproPart, 'id' | 'name'>
        ), hair: (
          { __typename?: 'IIDXQproPart' }
          & Pick<IidxQproPart, 'id' | 'name'>
        ), hand: (
          { __typename?: 'IIDXQproPart' }
          & Pick<IidxQproPart, 'id' | 'name'>
        ), body: (
          { __typename?: 'IIDXQproPart' }
          & Pick<IidxQproPart, 'id' | 'name'>
        ) }
      )>, scoreBattle?: Maybe<(
        { __typename?: 'IIDXBattleComparison' }
        & Pick<IidxBattleComparison, 'wins' | 'losses'>
      )>, spNotesRadar?: Maybe<(
        { __typename?: 'IIDXNotesRadar' }
        & Pick<IidxNotesRadar, 'notes' | 'chord' | 'peak' | 'charge' | 'scratch' | 'soflan'>
      )>, spRank?: Maybe<(
        { __typename?: 'IIDXClassCourseScore' }
        & Pick<IidxClassCourseScore, 'id' | 'rank' | 'playStyle' | 'courseCleared' | 'achievementRate' | 'playtime'>
        & { game: (
          { __typename?: 'Game' }
          & Pick<Game, 'version' | 'name'>
        ), plays: Array<(
          { __typename?: 'IIDXPlay' }
          & Pick<IidxPlay, 'id' | 'exscore'>
          & { song: (
            { __typename?: 'IIDXSong' }
            & Pick<IidxSong, 'id' | 'title'>
          ), chart?: Maybe<(
            { __typename?: 'IIDXChart' }
            & Pick<IidxChart, 'id' | 'maxExscore'>
          )> }
        )> }
      )>, dpNotesRadar?: Maybe<(
        { __typename?: 'IIDXNotesRadar' }
        & Pick<IidxNotesRadar, 'notes' | 'chord' | 'peak' | 'charge' | 'scratch' | 'soflan'>
      )>, dpRank?: Maybe<(
        { __typename?: 'IIDXClassCourseScore' }
        & Pick<IidxClassCourseScore, 'id' | 'rank' | 'playStyle' | 'courseCleared' | 'achievementRate' | 'playtime'>
        & { game: (
          { __typename?: 'Game' }
          & Pick<Game, 'version' | 'name'>
        ), plays: Array<(
          { __typename?: 'IIDXPlay' }
          & Pick<IidxPlay, 'exscore'>
          & { song: (
            { __typename?: 'IIDXSong' }
            & Pick<IidxSong, 'title'>
          ), chart?: Maybe<(
            { __typename?: 'IIDXChart' }
            & Pick<IidxChart, 'maxExscore'>
          )> }
        )> }
      )>, rivals?: Maybe<Array<(
        { __typename?: 'IIDXRival' }
        & Pick<IidxRival, 'id' | 'playStyle'>
        & { rival: (
          { __typename?: 'IIDXProfile' }
          & Pick<IidxProfile, 'id' | 'name'>
          & { qpro?: Maybe<(
            { __typename?: 'IIDXQpro' }
            & { head: (
              { __typename?: 'IIDXQproPart' }
              & Pick<IidxQproPart, 'id' | 'name'>
            ), face: (
              { __typename?: 'IIDXQproPart' }
              & Pick<IidxQproPart, 'id' | 'name'>
            ), hair: (
              { __typename?: 'IIDXQproPart' }
              & Pick<IidxQproPart, 'id' | 'name'>
            ), hand: (
              { __typename?: 'IIDXQproPart' }
              & Pick<IidxQproPart, 'id' | 'name'>
            ), body: (
              { __typename?: 'IIDXQproPart' }
              & Pick<IidxQproPart, 'id' | 'name'>
            ) }
          )> }
        ) }
      )>>, area?: Maybe<(
        { __typename?: 'Area' }
        & Pick<Area, 'id' | 'country' | 'titleEn'>
      )>, newsSettings: (
        { __typename?: 'IIDXNewsSettings' }
        & Pick<IidxNewsSettings, 'topics' | 'types' | 'charts'>
      ) }
    )> }
  ) }
);

export type IidxClassCourseScoresQueryVariables = Exact<{
  profile?: Maybe<Scalars['ID']>;
  last: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type IidxClassCourseScoresQuery = (
  { __typename?: 'Query' }
  & { iidx: (
    { __typename?: 'IIDXRoot' }
    & { profileLookup?: Maybe<(
      { __typename?: 'IIDXProfile' }
      & Pick<IidxProfile, 'id'>
      & { classCourseScores: (
        { __typename?: 'IIDXClassCourseScoreConnection' }
        & Pick<IidxClassCourseScoreConnection, 'totalItems'>
        & { items: Array<(
          { __typename?: 'IIDXClassCourseScore' }
          & Pick<IidxClassCourseScore, 'id' | 'rank' | 'playStyle' | 'courseCleared' | 'isHidden' | 'isIgnored' | 'isEx' | 'isMirror' | 'stagesCleared' | 'achievementRate' | 'playtime'>
          & { game: (
            { __typename?: 'Game' }
            & Pick<Game, 'version' | 'name'>
          ), shop: (
            { __typename?: 'Shop' }
            & Pick<Shop, 'id' | 'name'>
          ), plays: Array<(
            { __typename?: 'IIDXPlay' }
            & Pick<IidxPlay, 'id' | 'lamp' | 'gauge' | 'ghost' | 'exscore'>
            & { song: (
              { __typename?: 'IIDXSong' }
              & Pick<IidxSong, 'id' | 'title' | 'artist'>
            ), chart?: Maybe<(
              { __typename?: 'IIDXChart' }
              & Pick<IidxChart, 'id' | 'playstyle' | 'level' | 'diff' | 'maxExscore'>
            )> }
          )> }
        )> }
      ) }
    )> }
  ) }
);

export type IidxPlayHistogramQueryVariables = Exact<{
  profile?: Maybe<Scalars['ID']>;
}>;


export type IidxPlayHistogramQuery = (
  { __typename?: 'Query' }
  & { iidx: (
    { __typename?: 'IIDXRoot' }
    & { profileLookup?: Maybe<(
      { __typename?: 'IIDXProfile' }
      & Pick<IidxProfile, 'id'>
      & { playHistogram?: Maybe<Array<(
        { __typename?: 'IIDXPlayHistogramEntry' }
        & Pick<IidxPlayHistogramEntry, 'period' | 'plays'>
      )>> }
    )> }
  ) }
);

export type IidxPlayGradeStatsQueryVariables = Exact<{
  profile?: Maybe<Scalars['ID']>;
}>;


export type IidxPlayGradeStatsQuery = (
  { __typename?: 'Query' }
  & { iidx: (
    { __typename?: 'IIDXRoot' }
    & { profileLookup?: Maybe<(
      { __typename?: 'IIDXProfile' }
      & Pick<IidxProfile, 'id'>
      & { playStats?: Maybe<Array<(
        { __typename?: 'IIDXPlayStatistics' }
        & Pick<IidxPlayStatistics, 'count' | 'grade'>
      )>> }
    )> }
  ) }
);

export type IidxPlayClearStatsQueryVariables = Exact<{
  profile?: Maybe<Scalars['ID']>;
}>;


export type IidxPlayClearStatsQuery = (
  { __typename?: 'Query' }
  & { iidx: (
    { __typename?: 'IIDXRoot' }
    & { profileLookup?: Maybe<(
      { __typename?: 'IIDXProfile' }
      & Pick<IidxProfile, 'id'>
      & { playStats?: Maybe<Array<(
        { __typename?: 'IIDXPlayStatistics' }
        & Pick<IidxPlayStatistics, 'count' | 'lamp'>
      )>> }
    )> }
  ) }
);

export type IidxPlaysQueryVariables = Exact<{
  profile?: Maybe<Scalars['ID']>;
  last: Scalars['Int'];
  offset: Scalars['Int'];
  revision?: Maybe<Scalars['ID']>;
  lamp?: Maybe<IidxLamp>;
  plays?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type IidxPlaysQuery = (
  { __typename?: 'Query' }
  & { iidx: (
    { __typename?: 'IIDXRoot' }
    & { profileLookup?: Maybe<(
      { __typename?: 'IIDXProfile' }
      & Pick<IidxProfile, 'id'>
      & { plays: (
        { __typename?: 'IIDXPlayConnection' }
        & Pick<IidxPlayConnection, 'totalItems'>
        & { items: Array<(
          { __typename?: 'IIDXPlay' }
          & Pick<IidxPlay, 'id' | 'timestamp' | 'exscore' | 'lamp' | 'ghost' | 'gauge' | 'isHidden' | 'isIgnored' | 'playOptions'>
          & { song: (
            { __typename?: 'IIDXSong' }
            & Pick<IidxSong, 'id' | 'title' | 'artist'>
          ), chart?: Maybe<(
            { __typename?: 'IIDXChart' }
            & Pick<IidxChart, 'id' | 'level' | 'diff' | 'playstyle' | 'chargeNotes' | 'hellChargeNotes' | 'maxExscore'>
            & { revision?: Maybe<(
              { __typename?: 'IIDXRevision' }
              & Pick<IidxRevision, 'id'>
            )> }
          )>, game: (
            { __typename?: 'Game' }
            & Pick<Game, 'name'>
          ), shop?: Maybe<(
            { __typename?: 'Shop' }
            & Pick<Shop, 'id' | 'name'>
          )>, competition?: Maybe<(
            { __typename?: 'IIDXCompetition' }
            & Pick<IidxCompetition, 'id'>
          )>, classCourseScore?: Maybe<(
            { __typename?: 'IIDXClassCourseScore' }
            & Pick<IidxClassCourseScore, 'id' | 'rank' | 'stagesCleared'>
            & { plays: Array<(
              { __typename?: 'IIDXPlay' }
              & Pick<IidxPlay, 'id'>
            )> }
          )>, arenaScore?: Maybe<(
            { __typename?: 'IIDXArenaScore' }
            & Pick<IidxArenaScore, 'id'>
          )>, arenaStage?: Maybe<(
            { __typename?: 'IIDXArenaStage' }
            & Pick<IidxArenaStage, 'id' | 'stage'>
            & { scores: Array<(
              { __typename?: 'IIDXArenaScore' }
              & Pick<IidxArenaScore, 'id' | 'exscore' | 'lamp'>
              & { play?: Maybe<(
                { __typename?: 'IIDXPlay' }
                & Pick<IidxPlay, 'id'>
              )>, participant?: Maybe<(
                { __typename?: 'IIDXArenaParticipant' }
                & Pick<IidxArenaParticipant, 'id' | 'name' | 'isCpu'>
                & { profile?: Maybe<(
                  { __typename?: 'IIDXProfile' }
                  & Pick<IidxProfile, 'id'>
                )> }
              )> }
            )> }
          )>, details?: Maybe<(
            { __typename?: 'IIDXPlayDetails' }
            & Pick<IidxPlayDetails, 'id' | 'judgePgreat' | 'judgeGreat' | 'judgeGood' | 'judgeBad' | 'judgePoor' | 'timingFast' | 'timingSlow' | 'comboBreak'>
          )> }
        )> }
      ) }
    )> }
  ) }
);

export type IidxPlayHistoryQueryVariables = Exact<{
  profile?: Maybe<Scalars['ID']>;
  revision: Scalars['ID'];
}>;


export type IidxPlayHistoryQuery = (
  { __typename?: 'Query' }
  & { iidx: (
    { __typename?: 'IIDXRoot' }
    & { profileLookup?: Maybe<(
      { __typename?: 'IIDXProfile' }
      & Pick<IidxProfile, 'id'>
      & { plays: (
        { __typename?: 'IIDXPlayConnection' }
        & Pick<IidxPlayConnection, 'totalItems'>
        & { items: Array<(
          { __typename?: 'IIDXPlay' }
          & Pick<IidxPlay, 'id' | 'timestamp' | 'exscore' | 'playOptions' | 'lamp' | 'isHidden' | 'isIgnored'>
          & { song: (
            { __typename?: 'IIDXSong' }
            & Pick<IidxSong, 'id' | 'title' | 'artist'>
          ), chart?: Maybe<(
            { __typename?: 'IIDXChart' }
            & Pick<IidxChart, 'id' | 'level' | 'diff' | 'playstyle' | 'maxExscore'>
          )>, game: (
            { __typename?: 'Game' }
            & Pick<Game, 'name'>
          ), classCourseScore?: Maybe<(
            { __typename?: 'IIDXClassCourseScore' }
            & Pick<IidxClassCourseScore, 'id' | 'rank' | 'stagesCleared'>
            & { plays: Array<(
              { __typename?: 'IIDXPlay' }
              & Pick<IidxPlay, 'id'>
            )> }
          )> }
        )> }
      ) }
    )> }
  ) }
);

export type IidxGameDataQueryVariables = Exact<{
  profile?: Maybe<Scalars['ID']>;
  withQpro?: Maybe<Scalars['Boolean']>;
  withRivals?: Maybe<Scalars['Boolean']>;
  withBattles?: Maybe<Scalars['Boolean']>;
  withUI?: Maybe<Scalars['Boolean']>;
  withMedals?: Maybe<Scalars['Boolean']>;
}>;


export type IidxGameDataQuery = (
  { __typename?: 'Query' }
  & { iidx: (
    { __typename?: 'IIDXRoot' }
    & { profileLookup?: Maybe<(
      { __typename?: 'IIDXProfile' }
      & Pick<IidxProfile, 'id' | 'name'>
      & { defaultGame: (
        { __typename?: 'Game' }
        & Pick<Game, 'name' | 'version'>
      ), medals?: Maybe<(
        { __typename?: 'IIDXMedalStats' }
        & { state: Array<(
          { __typename?: 'IIDXMedalState' }
          & Pick<IidxMedalState, 'obtained'>
          & { medal: (
            { __typename?: 'IIDXMedal' }
            & Pick<IidxMedal, 'id' | 'text' | 'description' | 'color'>
          ) }
        )> }
      )>, rivals?: Maybe<Array<(
        { __typename?: 'IIDXRival' }
        & Pick<IidxRival, 'id' | 'playStyle'>
        & { scoreBattle?: Maybe<(
          { __typename?: 'IIDXBattleComparison' }
          & Pick<IidxBattleComparison, 'wins' | 'losses'>
        )>, rival: (
          { __typename?: 'IIDXProfile' }
          & Pick<IidxProfile, 'id' | 'playerId' | 'name'>
          & { qpro: (
            { __typename?: 'IIDXQpro' }
            & { head: (
              { __typename?: 'IIDXQproPart' }
              & Pick<IidxQproPart, 'id' | 'name'>
            ), face: (
              { __typename?: 'IIDXQproPart' }
              & Pick<IidxQproPart, 'id' | 'name'>
            ), hair: (
              { __typename?: 'IIDXQproPart' }
              & Pick<IidxQproPart, 'id' | 'name'>
            ), hand: (
              { __typename?: 'IIDXQproPart' }
              & Pick<IidxQproPart, 'id' | 'name'>
            ), body: (
              { __typename?: 'IIDXQproPart' }
              & Pick<IidxQproPart, 'id' | 'name'>
            ) }
          ) }
        ) }
      )>>, uiSettings?: Maybe<(
        { __typename?: 'IIDXUISettings' }
        & Pick<IidxuiSettings, 'availableFlags' | 'flags'>
        & { lane?: Maybe<(
          { __typename?: 'IIDXUIPart' }
          & Pick<IidxuiPart, 'id'>
        )>, pacemaker?: Maybe<(
          { __typename?: 'IIDXUIPart' }
          & Pick<IidxuiPart, 'id'>
        )>, frame?: Maybe<(
          { __typename?: 'IIDXUIPart' }
          & Pick<IidxuiPart, 'id'>
        )>, turntable?: Maybe<(
          { __typename?: 'IIDXUIPart' }
          & Pick<IidxuiPart, 'id'>
        )>, explosion?: Maybe<(
          { __typename?: 'IIDXUIPart' }
          & Pick<IidxuiPart, 'id'>
        )>, explosionSize?: Maybe<(
          { __typename?: 'IIDXUIPart' }
          & Pick<IidxuiPart, 'id'>
        )>, systemBgm?: Maybe<(
          { __typename?: 'IIDXUIPart' }
          & Pick<IidxuiPart, 'id'>
        )>, categoryVoice?: Maybe<(
          { __typename?: 'IIDXUIPart' }
          & Pick<IidxuiPart, 'id'>
        )>, noteskin?: Maybe<(
          { __typename?: 'IIDXUIPart' }
          & Pick<IidxuiPart, 'id'>
        )>, fullcombo?: Maybe<(
          { __typename?: 'IIDXUIPart' }
          & Pick<IidxuiPart, 'id'>
        )>, beams?: Maybe<(
          { __typename?: 'IIDXUIPart' }
          & Pick<IidxuiPart, 'id'>
        )>, judge?: Maybe<(
          { __typename?: 'IIDXUIPart' }
          & Pick<IidxuiPart, 'id'>
        )>, effectorDefault?: Maybe<(
          { __typename?: 'IIDXUIPart' }
          & Pick<IidxuiPart, 'id'>
        )>, noteStart?: Maybe<(
          { __typename?: 'IIDXUIPart' }
          & Pick<IidxuiPart, 'id'>
        )>, hcnSkin?: Maybe<(
          { __typename?: 'IIDXUIPart' }
          & Pick<IidxuiPart, 'id'>
        )>, arenaEmote1?: Maybe<(
          { __typename?: 'IIDXUIPart' }
          & Pick<IidxuiPart, 'id'>
        )>, arenaEmote2?: Maybe<(
          { __typename?: 'IIDXUIPart' }
          & Pick<IidxuiPart, 'id'>
        )>, arenaEmote3?: Maybe<(
          { __typename?: 'IIDXUIPart' }
          & Pick<IidxuiPart, 'id'>
        )> }
      )>, gameData: Array<(
        { __typename?: 'IIDXGameData' }
        & Pick<IidxGameData, 'id'>
        & { game: (
          { __typename?: 'Game' }
          & Pick<Game, 'name' | 'version'>
        ), medals?: Maybe<(
          { __typename?: 'IIDXMedalStats' }
          & { state: Array<(
            { __typename?: 'IIDXMedalState' }
            & Pick<IidxMedalState, 'obtained'>
            & { medal: (
              { __typename?: 'IIDXMedal' }
              & Pick<IidxMedal, 'id' | 'text' | 'description' | 'color'>
            ) }
          )> }
        )>, uiSettings?: Maybe<(
          { __typename?: 'IIDXUISettings' }
          & Pick<IidxuiSettings, 'availableFlags' | 'flags'>
          & { lane?: Maybe<(
            { __typename?: 'IIDXUIPart' }
            & Pick<IidxuiPart, 'id'>
          )>, pacemaker?: Maybe<(
            { __typename?: 'IIDXUIPart' }
            & Pick<IidxuiPart, 'id'>
          )>, frame?: Maybe<(
            { __typename?: 'IIDXUIPart' }
            & Pick<IidxuiPart, 'id'>
          )>, turntable?: Maybe<(
            { __typename?: 'IIDXUIPart' }
            & Pick<IidxuiPart, 'id'>
          )>, explosion?: Maybe<(
            { __typename?: 'IIDXUIPart' }
            & Pick<IidxuiPart, 'id'>
          )>, explosionSize?: Maybe<(
            { __typename?: 'IIDXUIPart' }
            & Pick<IidxuiPart, 'id'>
          )>, systemBgm?: Maybe<(
            { __typename?: 'IIDXUIPart' }
            & Pick<IidxuiPart, 'id'>
          )>, categoryVoice?: Maybe<(
            { __typename?: 'IIDXUIPart' }
            & Pick<IidxuiPart, 'id'>
          )>, noteskin?: Maybe<(
            { __typename?: 'IIDXUIPart' }
            & Pick<IidxuiPart, 'id'>
          )>, fullcombo?: Maybe<(
            { __typename?: 'IIDXUIPart' }
            & Pick<IidxuiPart, 'id'>
          )>, beams?: Maybe<(
            { __typename?: 'IIDXUIPart' }
            & Pick<IidxuiPart, 'id'>
          )>, judge?: Maybe<(
            { __typename?: 'IIDXUIPart' }
            & Pick<IidxuiPart, 'id'>
          )>, effectorDefault?: Maybe<(
            { __typename?: 'IIDXUIPart' }
            & Pick<IidxuiPart, 'id'>
          )>, noteStart?: Maybe<(
            { __typename?: 'IIDXUIPart' }
            & Pick<IidxuiPart, 'id'>
          )>, hcnSkin?: Maybe<(
            { __typename?: 'IIDXUIPart' }
            & Pick<IidxuiPart, 'id'>
          )>, arenaEmote1?: Maybe<(
            { __typename?: 'IIDXUIPart' }
            & Pick<IidxuiPart, 'id'>
          )>, arenaEmote2?: Maybe<(
            { __typename?: 'IIDXUIPart' }
            & Pick<IidxuiPart, 'id'>
          )>, arenaEmote3?: Maybe<(
            { __typename?: 'IIDXUIPart' }
            & Pick<IidxuiPart, 'id'>
          )> }
        )>, qpro?: Maybe<(
          { __typename?: 'IIDXQpro' }
          & { head: (
            { __typename?: 'IIDXQproPart' }
            & Pick<IidxQproPart, 'id' | 'name'>
          ), face: (
            { __typename?: 'IIDXQproPart' }
            & Pick<IidxQproPart, 'id' | 'name'>
          ), hair: (
            { __typename?: 'IIDXQproPart' }
            & Pick<IidxQproPart, 'id' | 'name'>
          ), hand: (
            { __typename?: 'IIDXQproPart' }
            & Pick<IidxQproPart, 'id' | 'name'>
          ), body: (
            { __typename?: 'IIDXQproPart' }
            & Pick<IidxQproPart, 'id' | 'name'>
          ) }
        )>, rivals?: Maybe<Array<(
          { __typename?: 'IIDXRival' }
          & Pick<IidxRival, 'id' | 'playStyle'>
          & { scoreBattle?: Maybe<(
            { __typename?: 'IIDXBattleComparison' }
            & Pick<IidxBattleComparison, 'wins' | 'losses'>
          )>, rival: (
            { __typename?: 'IIDXProfile' }
            & Pick<IidxProfile, 'id' | 'playerId' | 'name'>
            & { qpro: (
              { __typename?: 'IIDXQpro' }
              & { head: (
                { __typename?: 'IIDXQproPart' }
                & Pick<IidxQproPart, 'id' | 'name'>
              ), face: (
                { __typename?: 'IIDXQproPart' }
                & Pick<IidxQproPart, 'id' | 'name'>
              ), hair: (
                { __typename?: 'IIDXQproPart' }
                & Pick<IidxQproPart, 'id' | 'name'>
              ), hand: (
                { __typename?: 'IIDXQproPart' }
                & Pick<IidxQproPart, 'id' | 'name'>
              ), body: (
                { __typename?: 'IIDXQproPart' }
                & Pick<IidxQproPart, 'id' | 'name'>
              ) }
            ) }
          ) }
        )>> }
      )> }
    )> }
  ) }
);

export type IidxUiPartsQueryVariables = Exact<{
  profile?: Maybe<Scalars['ID']>;
  version?: Maybe<Scalars['Int']>;
}>;


export type IidxUiPartsQuery = (
  { __typename?: 'Query' }
  & { iidx: (
    { __typename?: 'IIDXRoot' }
    & { uiParts: Array<(
      { __typename?: 'IIDXUIPart' }
      & Pick<IidxuiPart, 'id' | 'partId' | 'text' | 'type'>
    )> }
  ) }
);

export type IidxQproPartsQueryVariables = Exact<{
  profile?: Maybe<Scalars['ID']>;
  version?: Maybe<Scalars['Int']>;
}>;


export type IidxQproPartsQuery = (
  { __typename?: 'Query' }
  & { iidx: (
    { __typename?: 'IIDXRoot' }
    & { qproParts: Array<(
      { __typename?: 'IIDXQproPart' }
      & Pick<IidxQproPart, 'id' | 'name' | 'text' | 'type'>
    )> }
  ) }
);

export type IidxSetQproMutationVariables = Exact<{
  profile?: Maybe<Scalars['ID']>;
  version?: Maybe<Scalars['Int']>;
  body: Scalars['ID'];
  hand: Scalars['ID'];
  face: Scalars['ID'];
  hair: Scalars['ID'];
  head: Scalars['ID'];
}>;


export type IidxSetQproMutation = (
  { __typename?: 'Mutation' }
  & { iidxSetQpro?: Maybe<(
    { __typename?: 'SetQpro' }
    & Pick<SetQpro, 'ok'>
  )> }
);

export type IidxSetUiSettingsMutationVariables = Exact<{
  profile?: Maybe<Scalars['ID']>;
  version?: Maybe<Scalars['Int']>;
  flags?: Maybe<Array<IidxCustomizationFlag> | IidxCustomizationFlag>;
  lane?: Maybe<Scalars['ID']>;
  pacemaker?: Maybe<Scalars['ID']>;
  beams?: Maybe<Scalars['ID']>;
  noteskin?: Maybe<Scalars['ID']>;
  fullcombo?: Maybe<Scalars['ID']>;
  judge?: Maybe<Scalars['ID']>;
  frame?: Maybe<Scalars['ID']>;
  turntable?: Maybe<Scalars['ID']>;
  systemBgm?: Maybe<Scalars['ID']>;
  explosion?: Maybe<Scalars['ID']>;
  noteStart?: Maybe<Scalars['ID']>;
  effectorDefault?: Maybe<Scalars['ID']>;
  categoryVoice?: Maybe<Scalars['ID']>;
  hcnSkin?: Maybe<Scalars['ID']>;
  explosionSize?: Maybe<Scalars['ID']>;
  arenaEmote1?: Maybe<Scalars['ID']>;
  arenaEmote2?: Maybe<Scalars['ID']>;
  arenaEmote3?: Maybe<Scalars['ID']>;
}>;


export type IidxSetUiSettingsMutation = (
  { __typename?: 'Mutation' }
  & { iidxSetUiSettings?: Maybe<(
    { __typename?: 'SetUISettings' }
    & Pick<SetUiSettings, 'ok'>
  )> }
);

export type IidxRemoveRivalMutationVariables = Exact<{
  rivalEntry: Scalars['ID'];
}>;


export type IidxRemoveRivalMutation = (
  { __typename?: 'Mutation' }
  & { iidxRemoveRival?: Maybe<(
    { __typename?: 'RemoveRival' }
    & Pick<RemoveRival, 'ok'>
  )> }
);

export type IidxAddRivalMutationVariables = Exact<{
  profile?: Maybe<Scalars['ID']>;
  version?: Maybe<Scalars['Int']>;
  rival: Scalars['ID'];
  playstyle: IidxPlayStyle;
}>;


export type IidxAddRivalMutation = (
  { __typename?: 'Mutation' }
  & { iidxAddRival?: Maybe<(
    { __typename?: 'AddRival' }
    & Pick<AddRival, 'ok'>
  )> }
);

export type IidxChartLeaderboardQueryVariables = Exact<{
  chart: Scalars['ID'];
  weekly?: Maybe<Scalars['ID']>;
  arena?: Maybe<Scalars['ID']>;
  competition?: Maybe<Scalars['ID']>;
}>;


export type IidxChartLeaderboardQuery = (
  { __typename?: 'Query' }
  & { iidx: (
    { __typename?: 'IIDXRoot' }
    & { chart?: Maybe<(
      { __typename?: 'IIDXChart' }
      & Pick<IidxChart, 'id' | 'notes' | 'chargeNotes' | 'bssNotes' | 'mssNotes' | 'hellChargeNotes' | 'maxExscore' | 'playstyle' | 'diff' | 'level'>
      & { song: (
        { __typename?: 'IIDXSong' }
        & Pick<IidxSong, 'id' | 'title' | 'rtitle' | 'artist' | 'genre'>
      ), siblingCharts: Array<(
        { __typename?: 'IIDXChart' }
        & Pick<IidxChart, 'id' | 'diff' | 'playstyle' | 'level'>
      )>, revision?: Maybe<(
        { __typename?: 'IIDXRevision' }
        & Pick<IidxRevision, 'id'>
        & { highscores: Array<(
          { __typename?: 'IIDXHighscore' }
          & Pick<IidxHighscore, 'score' | 'lamp' | 'timestamp'>
          & { play: (
            { __typename?: 'IIDXPlay' }
            & Pick<IidxPlay, 'id'>
          ), game: (
            { __typename?: 'Game' }
            & Pick<Game, 'name' | 'version'>
          ), profile: (
            { __typename?: 'IIDXProfile' }
            & Pick<IidxProfile, 'id' | 'name'>
            & { qpro: (
              { __typename?: 'IIDXQpro' }
              & { head: (
                { __typename?: 'IIDXQproPart' }
                & Pick<IidxQproPart, 'id' | 'name'>
              ), face: (
                { __typename?: 'IIDXQproPart' }
                & Pick<IidxQproPart, 'id' | 'name'>
              ), hair: (
                { __typename?: 'IIDXQproPart' }
                & Pick<IidxQproPart, 'id' | 'name'>
              ), hand: (
                { __typename?: 'IIDXQproPart' }
                & Pick<IidxQproPart, 'id' | 'name'>
              ), body: (
                { __typename?: 'IIDXQproPart' }
                & Pick<IidxQproPart, 'id' | 'name'>
              ) }
            ) }
          ) }
        )> }
      )> }
    )> }
  ) }
);

export type IidxPlayerWeeklyQueryVariables = Exact<{ [key: string]: never; }>;


export type IidxPlayerWeeklyQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'Account' }
    & Pick<Account, 'id'>
    & { gameProfile?: Maybe<(
      { __typename?: 'BishiBashiChannelProfile' }
      & Pick<BishiBashiChannelProfile, 'id'>
    ) | (
      { __typename?: 'BeatstreamProfile' }
      & Pick<BeatstreamProfile, 'id'>
    ) | (
      { __typename?: 'IIDXProfile' }
      & Pick<IidxProfile, 'id'>
      & { defaultGame: (
        { __typename?: 'Game' }
        & Pick<Game, 'name'>
      ), activeWeekly?: Maybe<(
        { __typename?: 'IIDXWeekly' }
        & Pick<IidxWeekly, 'id' | 'start' | 'end'>
        & { song?: Maybe<(
          { __typename?: 'IIDXSong' }
          & Pick<IidxSong, 'id' | 'title' | 'artist' | 'genre'>
        )>, charts: Array<(
          { __typename?: 'IIDXChart' }
          & Pick<IidxChart, 'id' | 'playstyle' | 'diff' | 'level'>
        )>, topRankers: Array<(
          { __typename?: 'IIDXWeeklyScore' }
          & Pick<IidxWeeklyScore, 'rank'>
          & { chart: (
            { __typename?: 'IIDXChart' }
            & Pick<IidxChart, 'id'>
          ), profile: (
            { __typename?: 'IIDXProfile' }
            & Pick<IidxProfile, 'id' | 'name'>
          ), play: (
            { __typename?: 'IIDXPlay' }
            & Pick<IidxPlay, 'id' | 'exscore'>
          ) }
        )> }
      )> }
    ) | (
      { __typename?: 'JubeatProfile' }
      & Pick<JubeatProfile, 'id'>
    ) | (
      { __typename?: 'SDVXProfile' }
      & Pick<SdvxProfile, 'id'>
    ) | (
      { __typename?: 'QMAProfile' }
      & Pick<QmaProfile, 'id'>
    )> }
  ) }
);

export type IidxPlayerDailiesQueryVariables = Exact<{ [key: string]: never; }>;


export type IidxPlayerDailiesQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'Account' }
    & Pick<Account, 'id'>
    & { gameProfile?: Maybe<(
      { __typename?: 'BishiBashiChannelProfile' }
      & Pick<BishiBashiChannelProfile, 'id'>
    ) | (
      { __typename?: 'BeatstreamProfile' }
      & Pick<BeatstreamProfile, 'id'>
    ) | (
      { __typename?: 'IIDXProfile' }
      & Pick<IidxProfile, 'id'>
      & { defaultGame: (
        { __typename?: 'Game' }
        & Pick<Game, 'name'>
      ), activeDailies?: Maybe<(
        { __typename?: 'IIDXDaily' }
        & Pick<IidxDaily, 'id' | 'date'>
        & { chart1?: Maybe<(
          { __typename?: 'IIDXChart' }
          & Pick<IidxChart, 'id'>
          & { song: (
            { __typename?: 'IIDXSong' }
            & Pick<IidxSong, 'id' | 'title'>
          ) }
        )>, chart2?: Maybe<(
          { __typename?: 'IIDXChart' }
          & Pick<IidxChart, 'id'>
          & { song: (
            { __typename?: 'IIDXSong' }
            & Pick<IidxSong, 'id' | 'title'>
          ) }
        )>, chart3?: Maybe<(
          { __typename?: 'IIDXChart' }
          & Pick<IidxChart, 'id'>
          & { song: (
            { __typename?: 'IIDXSong' }
            & Pick<IidxSong, 'id' | 'title'>
          ) }
        )> }
      )>, dailiesProgress?: Maybe<(
        { __typename?: 'IIDXDailyProgress' }
        & Pick<IidxDailyProgress, 'playedChart1' | 'playedChart2' | 'playedChart3'>
        & { dailies: (
          { __typename?: 'IIDXDaily' }
          & Pick<IidxDaily, 'id'>
        ) }
      )> }
    ) | (
      { __typename?: 'JubeatProfile' }
      & Pick<JubeatProfile, 'id'>
    ) | (
      { __typename?: 'SDVXProfile' }
      & Pick<SdvxProfile, 'id'>
    ) | (
      { __typename?: 'QMAProfile' }
      & Pick<QmaProfile, 'id'>
    )> }
  ) }
);

export type IidxNewsListQueryVariables = Exact<{
  last: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type IidxNewsListQuery = (
  { __typename?: 'Query' }
  & { iidx: (
    { __typename?: 'IIDXRoot' }
    & { news: (
      { __typename?: 'IIDXNewsConnection' }
      & Pick<IidxNewsConnection, 'totalItems'>
      & { items: Array<(
        { __typename?: 'IIDXNews' }
        & Pick<IidxNews, 'id' | 'type'>
        & { profile?: Maybe<(
          { __typename?: 'IIDXProfile' }
          & Pick<IidxProfile, 'id' | 'name'>
          & { qpro: (
            { __typename?: 'IIDXQpro' }
            & { head: (
              { __typename?: 'IIDXQproPart' }
              & Pick<IidxQproPart, 'id' | 'name'>
            ), face: (
              { __typename?: 'IIDXQproPart' }
              & Pick<IidxQproPart, 'id' | 'name'>
            ), hair: (
              { __typename?: 'IIDXQproPart' }
              & Pick<IidxQproPart, 'id' | 'name'>
            ), hand: (
              { __typename?: 'IIDXQproPart' }
              & Pick<IidxQproPart, 'id' | 'name'>
            ), body: (
              { __typename?: 'IIDXQproPart' }
              & Pick<IidxQproPart, 'id' | 'name'>
            ) }
          ) }
        )>, play: (
          { __typename?: 'IIDXPlay' }
          & Pick<IidxPlay, 'id' | 'timestamp' | 'lamp' | 'exscore' | 'playOptions'>
          & { chart?: Maybe<(
            { __typename?: 'IIDXChart' }
            & Pick<IidxChart, 'id' | 'maxExscore' | 'level' | 'diff' | 'playstyle'>
            & { song: (
              { __typename?: 'IIDXSong' }
              & Pick<IidxSong, 'id' | 'title'>
            ) }
          )>, game: (
            { __typename?: 'Game' }
            & Pick<Game, 'name'>
          ) }
        ) }
      )> }
    ) }
  ) }
);

export type IidxSongListQueryVariables = Exact<{
  last: Scalars['Int'];
  offset: Scalars['Int'];
  search?: Maybe<Scalars['String']>;
  useDefaultGame?: Maybe<Scalars['Boolean']>;
  withGame?: Maybe<Scalars['Boolean']>;
}>;


export type IidxSongListQuery = (
  { __typename?: 'Query' }
  & { iidx: (
    { __typename?: 'IIDXRoot' }
    & { songs: (
      { __typename?: 'IIDXSongConnection' }
      & Pick<IidxSongConnection, 'totalItems'>
      & { items: Array<(
        { __typename?: 'IIDXSong' }
        & MakeOptional<Pick<IidxSong, 'id' | 'title' | 'rtitle' | 'artist' | 'genre'>, 'genre'>
        & { charts: Array<(
          { __typename?: 'IIDXChart' }
          & Pick<IidxChart, 'gameFolder'>
        )> }
      )> }
    ) }
  ) }
);

export type IidxCompetitionListQueryVariables = Exact<{
  last: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type IidxCompetitionListQuery = (
  { __typename?: 'Query' }
  & { iidx: (
    { __typename?: 'IIDXRoot' }
    & { competitions: (
      { __typename?: 'IIDXCompetitionConnection' }
      & Pick<IidxCompetitionConnection, 'totalItems'>
      & { items: Array<(
        { __typename?: 'IIDXCompetition' }
        & Pick<IidxCompetition, 'id' | 'title' | 'subtitle' | 'start' | 'end'>
        & { game: (
          { __typename?: 'Game' }
          & Pick<Game, 'name'>
        ), creator?: Maybe<(
          { __typename?: 'IIDXProfile' }
          & Pick<IidxProfile, 'id' | 'name'>
        )> }
      )> }
    ) }
  ) }
);

export type IidxCompetitionInfoQueryVariables = Exact<{
  competition: Scalars['ID'];
  withScores?: Maybe<Scalars['Boolean']>;
}>;


export type IidxCompetitionInfoQuery = (
  { __typename?: 'Query' }
  & { iidx: (
    { __typename?: 'IIDXRoot' }
    & { competition?: Maybe<(
      { __typename?: 'IIDXCompetition' }
      & Pick<IidxCompetition, 'id' | 'title' | 'subtitle' | 'description' | 'isParticipant' | 'created' | 'start' | 'end'>
      & { game: (
        { __typename?: 'Game' }
        & Pick<Game, 'version' | 'name'>
      ), creator?: Maybe<(
        { __typename?: 'IIDXProfile' }
        & Pick<IidxProfile, 'id' | 'name'>
      )>, charts?: Maybe<Array<(
        { __typename?: 'IIDXChart' }
        & Pick<IidxChart, 'id' | 'diff' | 'level' | 'playstyle'>
        & { revision?: Maybe<(
          { __typename?: 'IIDXRevision' }
          & Pick<IidxRevision, 'id'>
        )>, song: (
          { __typename?: 'IIDXSong' }
          & Pick<IidxSong, 'title' | 'artist'>
        ) }
      )>>, highscores?: Maybe<Array<(
        { __typename?: 'IIDXCompetitionHighscore' }
        & Pick<IidxCompetitionHighscore, 'rank' | 'score'>
        & { profile: (
          { __typename?: 'IIDXProfile' }
          & Pick<IidxProfile, 'id' | 'name'>
          & { qpro: (
            { __typename?: 'IIDXQpro' }
            & { head: (
              { __typename?: 'IIDXQproPart' }
              & Pick<IidxQproPart, 'id' | 'name'>
            ), face: (
              { __typename?: 'IIDXQproPart' }
              & Pick<IidxQproPart, 'id' | 'name'>
            ), hair: (
              { __typename?: 'IIDXQproPart' }
              & Pick<IidxQproPart, 'id' | 'name'>
            ), hand: (
              { __typename?: 'IIDXQproPart' }
              & Pick<IidxQproPart, 'id' | 'name'>
            ), body: (
              { __typename?: 'IIDXQproPart' }
              & Pick<IidxQproPart, 'id' | 'name'>
            ) }
          ) }
        ) }
      )>> }
    )> }
  ) }
);

export type IidxBingoCardListQueryVariables = Exact<{
  last: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type IidxBingoCardListQuery = (
  { __typename?: 'Query' }
  & { iidx: (
    { __typename?: 'IIDXRoot' }
    & { bingoCards: (
      { __typename?: 'IIDXBingoCardConnection' }
      & Pick<IidxBingoCardConnection, 'totalItems'>
      & { items: Array<(
        { __typename?: 'IIDXBingoCard' }
        & Pick<IidxBingoCard, 'id' | 'title' | 'size' | 'created'>
        & { creator?: Maybe<(
          { __typename?: 'IIDXProfile' }
          & Pick<IidxProfile, 'id' | 'name'>
        )> }
      )> }
    ) }
  ) }
);

export type IidxBingoCardProgressQueryVariables = Exact<{
  bingoCard: Scalars['ID'];
  profile?: Maybe<Scalars['ID']>;
}>;


export type IidxBingoCardProgressQuery = (
  { __typename?: 'Query' }
  & { iidx: (
    { __typename?: 'IIDXRoot' }
    & { bingoCard?: Maybe<(
      { __typename?: 'IIDXBingoCard' }
      & Pick<IidxBingoCard, 'id' | 'size'>
      & { playerProgress?: Maybe<(
        { __typename?: 'IIDXBingoCardProgress' }
        & Pick<IidxBingoCardProgress, 'id' | 'clearedSlots'>
      )> }
    )> }
  ) }
);

export type IidxBingoCardInfoQueryVariables = Exact<{
  bingoCard: Scalars['ID'];
}>;


export type IidxBingoCardInfoQuery = (
  { __typename?: 'Query' }
  & { iidx: (
    { __typename?: 'IIDXRoot' }
    & { bingoCard?: Maybe<(
      { __typename?: 'IIDXBingoCard' }
      & Pick<IidxBingoCard, 'id' | 'title' | 'size' | 'created'>
      & { creator?: Maybe<(
        { __typename?: 'IIDXProfile' }
        & Pick<IidxProfile, 'id' | 'name'>
      )>, games: Array<(
        { __typename?: 'Game' }
        & Pick<Game, 'version' | 'name'>
      )>, slots: Array<(
        { __typename?: 'IIDXBingoCardSlot' }
        & Pick<IidxBingoCardSlot, 'id' | 'slotId' | 'clearType'>
        & { revision?: Maybe<(
          { __typename?: 'IIDXRevision' }
          & Pick<IidxRevision, 'id'>
          & { song?: Maybe<(
            { __typename?: 'IIDXSong' }
            & Pick<IidxSong, 'id' | 'title' | 'artist'>
            & { charts: Array<(
              { __typename?: 'IIDXChart' }
              & Pick<IidxChart, 'id' | 'diff' | 'level' | 'playstyle'>
              & { revision?: Maybe<(
                { __typename?: 'IIDXRevision' }
                & Pick<IidxRevision, 'id'>
              )>, game: (
                { __typename?: 'Game' }
                & Pick<Game, 'version' | 'name'>
              ) }
            )> }
          )> }
        )> }
      )> }
    )> }
  ) }
);

export type IidxSongInfoQueryVariables = Exact<{
  song: Scalars['ID'];
  useDefaultGame?: Maybe<Scalars['Boolean']>;
}>;


export type IidxSongInfoQuery = (
  { __typename?: 'Query' }
  & { iidx: (
    { __typename?: 'IIDXRoot' }
    & { song?: Maybe<(
      { __typename?: 'IIDXSong' }
      & Pick<IidxSong, 'id' | 'title' | 'rtitle' | 'artist' | 'genre'>
      & { charts: Array<(
        { __typename?: 'IIDXChart' }
        & Pick<IidxChart, 'id' | 'diff' | 'level' | 'playstyle'>
        & { game: (
          { __typename?: 'Game' }
          & Pick<Game, 'name' | 'version'>
        ), revision?: Maybe<(
          { __typename?: 'IIDXRevision' }
          & Pick<IidxRevision, 'id'>
        )> }
      )> }
    )> }
  ) }
);

export type IidxCompareScoresQueryVariables = Exact<{
  last: Scalars['Int'];
  offset: Scalars['Int'];
  version?: Maybe<Scalars['Int']>;
  players: Array<Scalars['ID']> | Scalars['ID'];
  search?: Maybe<Scalars['String']>;
  playstyle?: Maybe<IidxPlayStyle>;
}>;


export type IidxCompareScoresQuery = (
  { __typename?: 'Query' }
  & { iidx: (
    { __typename?: 'IIDXRoot' }
    & { songs: (
      { __typename?: 'IIDXSongConnection' }
      & Pick<IidxSongConnection, 'totalItems'>
      & { items: Array<(
        { __typename?: 'IIDXSong' }
        & Pick<IidxSong, 'id' | 'title' | 'artist'>
        & { charts: Array<(
          { __typename?: 'IIDXChart' }
          & Pick<IidxChart, 'id' | 'diff' | 'playstyle' | 'level' | 'maxExscore'>
          & { revision?: Maybe<(
            { __typename?: 'IIDXRevision' }
            & Pick<IidxRevision, 'id'>
            & { highscores: Array<(
              { __typename?: 'IIDXHighscore' }
              & Pick<IidxHighscore, 'profileId' | 'lamp' | 'score'>
            )> }
          )> }
        )> }
      )> }
    ) }
  ) }
);

export type IidxSetNewsSettingsMutationVariables = Exact<{
  profile?: Maybe<Scalars['ID']>;
  types?: Maybe<Array<IidxNewsType> | IidxNewsType>;
  charts?: Maybe<Array<IidxNewsChart> | IidxNewsChart>;
  topics?: Maybe<Array<IidxNewsTopic> | IidxNewsTopic>;
}>;


export type IidxSetNewsSettingsMutation = (
  { __typename?: 'Mutation' }
  & { iidxSetNewsSettings?: Maybe<(
    { __typename?: 'SetNewsSettings' }
    & Pick<SetNewsSettings, 'ok' | 'message'>
  )> }
);

export type IidxSetProfileDataMutationVariables = Exact<{
  profile?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  area?: Maybe<Scalars['ID']>;
  arenaComment1?: Maybe<Scalars['String']>;
  arenaComment2?: Maybe<Scalars['String']>;
  arenaComment3?: Maybe<Scalars['String']>;
  arenaComment4?: Maybe<Scalars['String']>;
  forceUnlockCurrent?: Maybe<Scalars['Boolean']>;
  forceUnlockPrevious?: Maybe<Scalars['Boolean']>;
}>;


export type IidxSetProfileDataMutation = (
  { __typename?: 'Mutation' }
  & { iidxSetProfileData?: Maybe<(
    { __typename?: 'SetProfileData' }
    & Pick<SetProfileData, 'ok' | 'message'>
  )> }
);

export type IidxCreateBingoCardMutationVariables = Exact<{
  profile?: Maybe<Scalars['ID']>;
  title: Scalars['String'];
  size: IidxBingoCardSize;
  slots: Array<IidxBingoCardSlotInput> | IidxBingoCardSlotInput;
}>;


export type IidxCreateBingoCardMutation = (
  { __typename?: 'Mutation' }
  & { iidxCreateBingoCard?: Maybe<(
    { __typename?: 'CreateBingoCard' }
    & Pick<CreateBingoCard, 'ok' | 'message'>
    & { bingoCard?: Maybe<(
      { __typename?: 'IIDXBingoCard' }
      & Pick<IidxBingoCard, 'id'>
    )> }
  )> }
);

export type IidxSetBingoCardMutationVariables = Exact<{
  profile?: Maybe<Scalars['ID']>;
  card: Scalars['ID'];
}>;


export type IidxSetBingoCardMutation = (
  { __typename?: 'Mutation' }
  & { iidxSetBingoCard?: Maybe<(
    { __typename?: 'SetBingoCard' }
    & Pick<SetBingoCard, 'ok' | 'message'>
  )> }
);

export type IidxCreateCompetitionMutationVariables = Exact<{
  profile?: Maybe<Scalars['ID']>;
  title: Scalars['String'];
  subtitle?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  charts: Array<Scalars['ID']> | Scalars['ID'];
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
}>;


export type IidxCreateCompetitionMutation = (
  { __typename?: 'Mutation' }
  & { iidxCreateCompetition?: Maybe<(
    { __typename?: 'CreateCompetition' }
    & Pick<CreateCompetition, 'ok' | 'message'>
    & { competition?: Maybe<(
      { __typename?: 'IIDXCompetition' }
      & Pick<IidxCompetition, 'id'>
    )> }
  )> }
);

export type IidxJoinCompetitionMutationVariables = Exact<{
  profile?: Maybe<Scalars['ID']>;
  competition: Scalars['ID'];
}>;


export type IidxJoinCompetitionMutation = (
  { __typename?: 'Mutation' }
  & { iidxJoinCompetition?: Maybe<(
    { __typename?: 'JoinCompetition' }
    & Pick<JoinCompetition, 'ok' | 'message'>
  )> }
);

export type IidxLeaveCompetitionMutationVariables = Exact<{
  profile?: Maybe<Scalars['ID']>;
  competition: Scalars['ID'];
}>;


export type IidxLeaveCompetitionMutation = (
  { __typename?: 'Mutation' }
  & { iidxLeaveCompetition?: Maybe<(
    { __typename?: 'LeaveCompetition' }
    & Pick<LeaveCompetition, 'ok' | 'message'>
  )> }
);

export type JbProfileIdQueryVariables = Exact<{ [key: string]: never; }>;


export type JbProfileIdQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'Account' }
    & Pick<Account, 'id'>
    & { gameProfile?: Maybe<(
      { __typename?: 'BishiBashiChannelProfile' }
      & Pick<BishiBashiChannelProfile, 'id'>
    ) | (
      { __typename?: 'BeatstreamProfile' }
      & Pick<BeatstreamProfile, 'id'>
    ) | (
      { __typename?: 'IIDXProfile' }
      & Pick<IidxProfile, 'id'>
    ) | (
      { __typename?: 'JubeatProfile' }
      & Pick<JubeatProfile, 'id'>
    ) | (
      { __typename?: 'SDVXProfile' }
      & Pick<SdvxProfile, 'id'>
    ) | (
      { __typename?: 'QMAProfile' }
      & Pick<QmaProfile, 'id'>
    )> }
  ) }
);

export type JubeatProfileSearchQueryVariables = Exact<{
  last: Scalars['Int'];
  offset: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  lastPlay?: Maybe<Scalars['Int']>;
}>;


export type JubeatProfileSearchQuery = (
  { __typename?: 'Query' }
  & { jubeat: (
    { __typename?: 'JubeatRoot' }
    & { profileSearch?: Maybe<(
      { __typename?: 'JubeatProfileConnection' }
      & Pick<JubeatProfileConnection, 'totalItems'>
      & { items: Array<(
        { __typename?: 'JubeatProfile' }
        & Pick<JubeatProfile, 'id' | 'name' | 'playerId' | 'accessed' | 'registered'>
      )> }
    )> }
  ) }
);

export type JubeatProfileQueryVariables = Exact<{
  profile?: Maybe<Scalars['ID']>;
}>;


export type JubeatProfileQuery = (
  { __typename?: 'Query' }
  & { jubeat: (
    { __typename?: 'JubeatRoot' }
    & { profileLookup?: Maybe<(
      { __typename?: 'JubeatProfile' }
      & Pick<JubeatProfile, 'id' | 'name' | 'playerId' | 'accessed' | 'registered'>
    )> }
  ) }
);

export type QmaProfileIdQueryVariables = Exact<{ [key: string]: never; }>;


export type QmaProfileIdQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'Account' }
    & Pick<Account, 'id'>
    & { gameProfile?: Maybe<(
      { __typename?: 'BishiBashiChannelProfile' }
      & Pick<BishiBashiChannelProfile, 'id'>
    ) | (
      { __typename?: 'BeatstreamProfile' }
      & Pick<BeatstreamProfile, 'id'>
    ) | (
      { __typename?: 'IIDXProfile' }
      & Pick<IidxProfile, 'id'>
    ) | (
      { __typename?: 'JubeatProfile' }
      & Pick<JubeatProfile, 'id'>
    ) | (
      { __typename?: 'SDVXProfile' }
      & Pick<SdvxProfile, 'id'>
    ) | (
      { __typename?: 'QMAProfile' }
      & Pick<QmaProfile, 'id'>
    )> }
  ) }
);

export type QmaProfileSearchQueryVariables = Exact<{
  last: Scalars['Int'];
  offset: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  lastPlay?: Maybe<Scalars['Int']>;
}>;


export type QmaProfileSearchQuery = (
  { __typename?: 'Query' }
  & { qma: (
    { __typename?: 'QMARoot' }
    & { profileSearch?: Maybe<(
      { __typename?: 'QMAProfileConnection' }
      & Pick<QmaProfileConnection, 'totalItems'>
      & { items: Array<(
        { __typename?: 'QMAProfile' }
        & Pick<QmaProfile, 'id' | 'name' | 'studentId' | 'registered' | 'accessed' | 'character' | 'qLevel'>
      )> }
    )> }
  ) }
);

export type QmaProfileQueryVariables = Exact<{
  profile?: Maybe<Scalars['ID']>;
}>;


export type QmaProfileQuery = (
  { __typename?: 'Query' }
  & { qma: (
    { __typename?: 'QMARoot' }
    & { profileLookup?: Maybe<(
      { __typename?: 'QMAProfile' }
      & Pick<QmaProfile, 'id' | 'name' | 'studentId' | 'registered' | 'accessed' | 'character' | 'qLevel'>
      & { defaultGame: (
        { __typename?: 'Game' }
        & Pick<Game, 'name'>
      ), answerAccuracy: Array<(
        { __typename?: 'QMAAnswerAccuracy' }
        & Pick<QmaAnswerAccuracy, 'genre' | 'accuracyRate'>
      )> }
    )> }
  ) }
);

export type QmaExamsQueryVariables = Exact<{ [key: string]: never; }>;


export type QmaExamsQuery = (
  { __typename?: 'Query' }
  & { qma: (
    { __typename?: 'QMARoot' }
    & { exams: Array<(
      { __typename?: 'QMAExam' }
      & Pick<QmaExam, 'id' | 'textureId' | 'title' | 'subtitle' | 'details' | 'availableFrom' | 'availableUntil' | 'rankTill'>
      & { game: (
        { __typename?: 'Game' }
        & Pick<Game, 'name'>
      ) }
    )> }
  ) }
);

export type QmaExamInfoQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type QmaExamInfoQuery = (
  { __typename?: 'Query' }
  & { qma: (
    { __typename?: 'QMARoot' }
    & { exam: (
      { __typename?: 'QMAExam' }
      & Pick<QmaExam, 'id' | 'textureId' | 'title' | 'subtitle' | 'details' | 'availableFrom' | 'availableUntil' | 'rankTill'>
      & { game: (
        { __typename?: 'Game' }
        & Pick<Game, 'name'>
      ), scores: Array<(
        { __typename?: 'QMAExamRank' }
        & Pick<QmaExamRank, 'attempts' | 'bestScore' | 'timestamp'>
        & { profile: (
          { __typename?: 'QMAProfile' }
          & Pick<QmaProfile, 'id' | 'name' | 'character'>
        ) }
      )> }
    ) }
  ) }
);

export type NotifStatusQueryVariables = Exact<{ [key: string]: never; }>;


export type NotifStatusQuery = (
  { __typename?: 'Query' }
  & { userNotifications?: Maybe<(
    { __typename?: 'Notifications' }
    & Pick<Notifications, 'highestSeverity' | 'pendingItems'>
  )> }
);

export type NotifListQueryVariables = Exact<{
  offset: Scalars['Int'];
  last: Scalars['Int'];
}>;


export type NotifListQuery = (
  { __typename?: 'Query' }
  & { userNotifications?: Maybe<(
    { __typename?: 'Notifications' }
    & { notifications: (
      { __typename?: 'NotificationConnection' }
      & Pick<NotificationConnection, 'totalItems'>
      & { items: Array<(
        { __typename?: 'Notification' }
        & Pick<Notification, 'id' | 'pending' | 'type' | 'topic' | 'severity' | 'customTopic' | 'sent' | 'data'>
      )> }
    ) }
  )> }
);

export type DismissNotificationMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DismissNotificationMutation = (
  { __typename?: 'Mutation' }
  & { dismissNotification?: Maybe<(
    { __typename?: 'DismissNotification' }
    & Pick<DismissNotification, 'ok'>
  )> }
);

export type ShopListQueryVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  area?: Maybe<Scalars['ID']>;
}>;


export type ShopListQuery = (
  { __typename?: 'Query' }
  & { shops: Array<(
    { __typename?: 'Shop' }
    & Pick<Shop, 'id' | 'name'>
    & { area?: Maybe<(
      { __typename?: 'Area' }
      & Pick<Area, 'id' | 'country' | 'titleEn'>
    )>, cabinets?: Maybe<Array<(
      { __typename?: 'Cabinet' }
      & Pick<Cabinet, 'id'>
    )>>, union?: Maybe<(
      { __typename?: 'ShopUnion' }
      & Pick<ShopUnion, 'id' | 'name'>
    )> }
  )> }
);

export type ShopInfoQueryVariables = Exact<{
  shop: Scalars['ID'];
  withCabinets?: Maybe<Scalars['Boolean']>;
  withCabinetStats?: Maybe<Scalars['Boolean']>;
  withUsers?: Maybe<Scalars['Boolean']>;
  withPCBID?: Maybe<Scalars['Boolean']>;
}>;


export type ShopInfoQuery = (
  { __typename?: 'Query' }
  & { shop?: Maybe<(
    { __typename?: 'Shop' }
    & Pick<Shop, 'id' | 'name' | 'acl' | 'aclAnalytics' | 'aclAudit' | 'aclCabinets' | 'aclUsers' | 'aclParameters'>
    & { area?: Maybe<(
      { __typename?: 'Area' }
      & Pick<Area, 'id' | 'country' | 'titleEn'>
    )>, union?: Maybe<(
      { __typename?: 'ShopUnion' }
      & Pick<ShopUnion, 'id' | 'name' | 'aclParameters'>
    )>, users?: Maybe<Array<(
      { __typename?: 'ShopUserMembership' }
      & Pick<ShopUserMembership, 'id' | 'role'>
      & { account?: Maybe<(
        { __typename?: 'Account' }
        & Pick<Account, 'id' | 'email'>
      )> }
    ) | (
      { __typename?: 'ShopUnionUserMembership' }
      & Pick<ShopUnionUserMembership, 'id' | 'role'>
      & { account?: Maybe<(
        { __typename?: 'Account' }
        & Pick<Account, 'id' | 'email'>
      )> }
    )>>, cabinets?: Maybe<Array<(
      { __typename?: 'Cabinet' }
      & MakeOptional<Pick<Cabinet, 'id' | 'name' | 'description' | 'enabled' | 'lastContact' | 'pcbid'>, 'pcbid'>
      & { series?: Maybe<(
        { __typename?: 'Series' }
        & Pick<Series, 'name' | 'shortname'>
      )>, game?: Maybe<(
        { __typename?: 'Game' }
        & Pick<Game, 'name' | 'version'>
      )>, playSessionStats?: Maybe<Array<(
        { __typename?: 'HistogramValue' }
        & Pick<HistogramValue, 'key' | 'value'>
      )>> }
    )>> }
  )> }
);

export type ShopUnionListQueryVariables = Exact<{ [key: string]: never; }>;


export type ShopUnionListQuery = (
  { __typename?: 'Query' }
  & { shopUnions: Array<(
    { __typename?: 'ShopUnion' }
    & Pick<ShopUnion, 'id' | 'name'>
    & { shops: Array<(
      { __typename?: 'Shop' }
      & Pick<Shop, 'id' | 'name'>
    )> }
  )> }
);

export type ShopUnionInfoQueryVariables = Exact<{
  union: Scalars['ID'];
  withShops?: Maybe<Scalars['Boolean']>;
}>;


export type ShopUnionInfoQuery = (
  { __typename?: 'Query' }
  & { shopUnion?: Maybe<(
    { __typename?: 'ShopUnion' }
    & Pick<ShopUnion, 'id' | 'name' | 'aclParameters' | 'aclAnalytics'>
    & { shops?: Maybe<Array<(
      { __typename?: 'Shop' }
      & Pick<Shop, 'id' | 'name'>
    )>> }
  )> }
);

export type CabinetListQueryVariables = Exact<{
  tiedTo?: Maybe<Scalars['ID']>;
}>;


export type CabinetListQuery = (
  { __typename?: 'Query' }
  & { cabinets: Array<(
    { __typename?: 'Cabinet' }
    & Pick<Cabinet, 'id' | 'name' | 'description' | 'enabled' | 'lastContact' | 'pcbid'>
    & { shop?: Maybe<(
      { __typename?: 'Shop' }
      & Pick<Shop, 'id' | 'name'>
      & { union?: Maybe<(
        { __typename?: 'ShopUnion' }
        & Pick<ShopUnion, 'id' | 'name'>
      )> }
    )>, series?: Maybe<(
      { __typename?: 'Series' }
      & Pick<Series, 'name' | 'shortname'>
    )>, game?: Maybe<(
      { __typename?: 'Game' }
      & Pick<Game, 'name' | 'version'>
    )> }
  )> }
);

export type AreaListQueryVariables = Exact<{ [key: string]: never; }>;


export type AreaListQuery = (
  { __typename?: 'Query' }
  & { areas: Array<(
    { __typename?: 'Area' }
    & Pick<Area, 'id' | 'titleEn' | 'country'>
  )> }
);

export type ParameterListQueryVariables = Exact<{
  shop?: Maybe<Scalars['ID']>;
  view?: Maybe<Scalars['ID']>;
  withActive: Scalars['Boolean'];
}>;


export type ParameterListQuery = (
  { __typename?: 'Query' }
  & { parameters: Array<(
    { __typename?: 'Parameter' }
    & Pick<Parameter, 'id' | 'module' | 'name' | 'description'>
    & { defaultValue: (
      { __typename?: 'ParameterValue' }
      & Pick<ParameterValue, 'id' | 'origin' | 'value'>
    ), activeValue?: Maybe<(
      { __typename?: 'ParameterValue' }
      & Pick<ParameterValue, 'id' | 'origin' | 'value' | 'forced'>
    )>, values?: Maybe<Array<(
      { __typename?: 'ParameterValue' }
      & Pick<ParameterValue, 'id' | 'origin' | 'value' | 'forced'>
    )>>, info: (
      { __typename: 'EnumParameterInfo' }
      & Pick<EnumParameterInfo, 'type'>
      & { values: Array<(
        { __typename?: 'EnumParameterEntry' }
        & Pick<EnumParameterEntry, 'description' | 'value'>
      )> }
    ) | (
      { __typename: 'FloatParameterInfo' }
      & Pick<FloatParameterInfo, 'type'>
      & { floatMin: FloatParameterInfo['minValue'], floatMax: FloatParameterInfo['maxValue'] }
    ) | (
      { __typename: 'GenericParameterInfo' }
      & Pick<GenericParameterInfo, 'type'>
    ) | (
      { __typename: 'IntParameterInfo' }
      & Pick<IntParameterInfo, 'type'>
      & { intMin: IntParameterInfo['minValue'], intMax: IntParameterInfo['maxValue'] }
    ) }
  )> }
);

export type UnsetParameterMutationVariables = Exact<{
  parameter: Scalars['ID'];
  node?: Maybe<Scalars['ID']>;
}>;


export type UnsetParameterMutation = (
  { __typename?: 'Mutation' }
  & { unsetParameter?: Maybe<(
    { __typename?: 'UnsetParameter' }
    & Pick<UnsetParameter, 'ok'>
  )> }
);

export type SetParameterMutationVariables = Exact<{
  parameter: Scalars['ID'];
  node?: Maybe<Scalars['ID']>;
  forced?: Maybe<Scalars['Boolean']>;
  value?: Maybe<Scalars['String']>;
}>;


export type SetParameterMutation = (
  { __typename?: 'Mutation' }
  & { setParameter?: Maybe<(
    { __typename?: 'SetParameter' }
    & Pick<SetParameter, 'ok'>
    & { value?: Maybe<(
      { __typename?: 'ParameterValue' }
      & Pick<ParameterValue, 'id' | 'origin' | 'value' | 'forced'>
    )> }
  )> }
);

export type CreateShopMutationVariables = Exact<{
  name: Scalars['String'];
  area: Scalars['ID'];
}>;


export type CreateShopMutation = (
  { __typename?: 'Mutation' }
  & { createShop?: Maybe<(
    { __typename?: 'CreateShop' }
    & Pick<CreateShop, 'ok' | 'message'>
    & { shop?: Maybe<(
      { __typename?: 'Shop' }
      & Pick<Shop, 'id' | 'aclUsers' | 'aclCabinets'>
    )> }
  )> }
);

export type EditShopMutationVariables = Exact<{
  shop: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  area?: Maybe<Scalars['ID']>;
  operatorPin?: Maybe<Scalars['String']>;
}>;


export type EditShopMutation = (
  { __typename?: 'Mutation' }
  & { editShop?: Maybe<(
    { __typename?: 'EditShop' }
    & Pick<EditShop, 'ok' | 'message'>
  )> }
);

export type RemoveMembershipMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemoveMembershipMutation = (
  { __typename?: 'Mutation' }
  & { removeMembership?: Maybe<(
    { __typename?: 'RemoveMembership' }
    & Pick<RemoveMembership, 'ok' | 'message'>
  )> }
);

export type AddMembershipMutationVariables = Exact<{
  account: Scalars['ID'];
  entity: Scalars['ID'];
  role: ShopUserRole;
}>;


export type AddMembershipMutation = (
  { __typename?: 'Mutation' }
  & { addMembership?: Maybe<(
    { __typename?: 'AddMembership' }
    & Pick<AddMembership, 'ok' | 'message'>
    & { membership?: Maybe<(
      { __typename?: 'ShopUserMembership' }
      & Pick<ShopUserMembership, 'id'>
    ) | (
      { __typename?: 'ShopUnionUserMembership' }
      & Pick<ShopUnionUserMembership, 'id'>
    )> }
  )> }
);

export type CreateCabinetMutationVariables = Exact<{
  shop: Scalars['ID'];
  name: Scalars['String'];
  description: Scalars['String'];
  pcbid?: Maybe<Scalars['String']>;
  natPort?: Maybe<Scalars['Int']>;
  hidden: Scalars['Boolean'];
  enabled: Scalars['Boolean'];
}>;


export type CreateCabinetMutation = (
  { __typename?: 'Mutation' }
  & { createCabinet?: Maybe<(
    { __typename?: 'CreateCabinet' }
    & Pick<CreateCabinet, 'ok' | 'message'>
    & { cabinet?: Maybe<(
      { __typename?: 'Cabinet' }
      & Pick<Cabinet, 'id'>
    )> }
  )> }
);

export type TransactionInfoFragment = (
  { __typename?: 'WalletTransactionConnection' }
  & Pick<WalletTransactionConnection, 'totalItems'>
  & { items: Array<(
    { __typename?: 'WalletTransaction' }
    & Pick<WalletTransaction, 'id' | 'timestamp' | 'detail' | 'service' | 'charge' | 'isFreeplay'>
    & { series?: Maybe<(
      { __typename?: 'Series' }
      & Pick<Series, 'name'>
    )>, game?: Maybe<(
      { __typename?: 'Game' }
      & Pick<Game, 'name'>
    )>, shop: (
      { __typename?: 'Shop' }
      & Pick<Shop, 'id' | 'name'>
    ), cabinet: (
      { __typename?: 'Cabinet' }
      & Pick<Cabinet, 'id' | 'name'>
    ) }
  )> }
);

export type OperatorTransactionsQueryVariables = Exact<{
  id: Scalars['ID'];
  last: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type OperatorTransactionsQuery = (
  { __typename?: 'Query' }
  & { node?: Maybe<(
    { __typename?: 'Account' }
    & Pick<Account, 'id'>
  ) | (
    { __typename?: 'Area' }
    & Pick<Area, 'id'>
  ) | (
    { __typename?: 'BeatstreamChart' }
    & Pick<BeatstreamChart, 'id'>
  ) | (
    { __typename?: 'BeatstreamCourseScore' }
    & Pick<BeatstreamCourseScore, 'id'>
  ) | (
    { __typename?: 'BeatstreamPlay' }
    & Pick<BeatstreamPlay, 'id'>
  ) | (
    { __typename?: 'BeatstreamProfile' }
    & Pick<BeatstreamProfile, 'id'>
  ) | (
    { __typename?: 'BeatstreamRevision' }
    & Pick<BeatstreamRevision, 'id'>
  ) | (
    { __typename?: 'BeatstreamSong' }
    & Pick<BeatstreamSong, 'id'>
  ) | (
    { __typename?: 'BishiBashiChannelProfile' }
    & Pick<BishiBashiChannelProfile, 'id'>
  ) | (
    { __typename?: 'Cabinet' }
    & Pick<Cabinet, 'id'>
  ) | (
    { __typename?: 'Card' }
    & Pick<Card, 'id'>
  ) | (
    { __typename?: 'IIDXArenaMatch' }
    & Pick<IidxArenaMatch, 'id'>
  ) | (
    { __typename?: 'IIDXArenaParticipant' }
    & Pick<IidxArenaParticipant, 'id'>
  ) | (
    { __typename?: 'IIDXArenaScore' }
    & Pick<IidxArenaScore, 'id'>
  ) | (
    { __typename?: 'IIDXArenaStage' }
    & Pick<IidxArenaStage, 'id'>
  ) | (
    { __typename?: 'IIDXBingoCard' }
    & Pick<IidxBingoCard, 'id'>
  ) | (
    { __typename?: 'IIDXBingoCardProgress' }
    & Pick<IidxBingoCardProgress, 'id'>
  ) | (
    { __typename?: 'IIDXBingoCardSlot' }
    & Pick<IidxBingoCardSlot, 'id'>
  ) | (
    { __typename?: 'IIDXChart' }
    & Pick<IidxChart, 'id'>
  ) | (
    { __typename?: 'IIDXClassCourseScore' }
    & Pick<IidxClassCourseScore, 'id'>
  ) | (
    { __typename?: 'IIDXCompetition' }
    & Pick<IidxCompetition, 'id'>
  ) | (
    { __typename?: 'IIDXDaily' }
    & Pick<IidxDaily, 'id'>
  ) | (
    { __typename?: 'IIDXGameData' }
    & Pick<IidxGameData, 'id'>
  ) | (
    { __typename?: 'IIDXMedal' }
    & Pick<IidxMedal, 'id'>
  ) | (
    { __typename?: 'IIDXNews' }
    & Pick<IidxNews, 'id'>
  ) | (
    { __typename?: 'IIDXPlay' }
    & Pick<IidxPlay, 'id'>
  ) | (
    { __typename?: 'IIDXPlayDetails' }
    & Pick<IidxPlayDetails, 'id'>
  ) | (
    { __typename?: 'IIDXProfile' }
    & Pick<IidxProfile, 'id'>
  ) | (
    { __typename?: 'IIDXQproPart' }
    & Pick<IidxQproPart, 'id'>
  ) | (
    { __typename?: 'IIDXRevision' }
    & Pick<IidxRevision, 'id'>
  ) | (
    { __typename?: 'IIDXRival' }
    & Pick<IidxRival, 'id'>
  ) | (
    { __typename?: 'IIDXSong' }
    & Pick<IidxSong, 'id'>
  ) | (
    { __typename?: 'IIDXUIPart' }
    & Pick<IidxuiPart, 'id'>
  ) | (
    { __typename?: 'IIDXWeekly' }
    & Pick<IidxWeekly, 'id'>
  ) | (
    { __typename?: 'JubeatProfile' }
    & Pick<JubeatProfile, 'id'>
  ) | (
    { __typename?: 'Notification' }
    & Pick<Notification, 'id'>
  ) | (
    { __typename?: 'Parameter' }
    & Pick<Parameter, 'id'>
  ) | (
    { __typename?: 'QMAExam' }
    & Pick<QmaExam, 'id'>
  ) | (
    { __typename?: 'QMAGameData' }
    & Pick<QmaGameData, 'id'>
  ) | (
    { __typename?: 'QMAProfile' }
    & Pick<QmaProfile, 'id'>
  ) | (
    { __typename?: 'SDVXProfile' }
    & Pick<SdvxProfile, 'id'>
  ) | (
    { __typename?: 'Shop' }
    & Pick<Shop, 'id'>
    & { transactions?: Maybe<(
      { __typename?: 'WalletTransactionConnection' }
      & TransactionInfoFragment
    )> }
  ) | (
    { __typename?: 'ShopUnion' }
    & Pick<ShopUnion, 'id'>
    & { transactions?: Maybe<(
      { __typename?: 'WalletTransactionConnection' }
      & TransactionInfoFragment
    )> }
  ) | (
    { __typename?: 'ShopUnionUserMembership' }
    & Pick<ShopUnionUserMembership, 'id'>
  ) | (
    { __typename?: 'ShopUserMembership' }
    & Pick<ShopUserMembership, 'id'>
  ) | (
    { __typename?: 'Wallet' }
    & Pick<Wallet, 'id'>
  ) | (
    { __typename?: 'WalletTransaction' }
    & Pick<WalletTransaction, 'id'>
  )> }
);

export type UserListQueryVariables = Exact<{
  card?: Maybe<Scalars['String']>;
}>;


export type UserListQuery = (
  { __typename?: 'Query' }
  & { users: Array<(
    { __typename?: 'Account' }
    & Pick<Account, 'id' | 'email' | 'networkOperator'>
    & { cards?: Maybe<Array<(
      { __typename?: 'Card' }
      & Pick<Card, 'id'>
    )>>, gameProfiles?: Maybe<Array<(
      { __typename?: 'BishiBashiChannelProfile' }
      & Pick<BishiBashiChannelProfile, 'id' | 'name'>
    ) | (
      { __typename?: 'BeatstreamProfile' }
      & Pick<BeatstreamProfile, 'id' | 'name' | 'playerId'>
    ) | (
      { __typename?: 'IIDXProfile' }
      & Pick<IidxProfile, 'id' | 'name' | 'playerId'>
    ) | (
      { __typename?: 'JubeatProfile' }
      & Pick<JubeatProfile, 'id' | 'name' | 'playerId'>
    ) | (
      { __typename?: 'SDVXProfile' }
      & Pick<SdvxProfile, 'id' | 'name' | 'playerId'>
    ) | (
      { __typename?: 'QMAProfile' }
      & Pick<QmaProfile, 'id' | 'name' | 'studentId'>
    )>>, wallets?: Maybe<Array<(
      { __typename?: 'Wallet' }
      & Pick<Wallet, 'id'>
    )>>, shops?: Maybe<Array<(
      { __typename?: 'Shop' }
      & Pick<Shop, 'id'>
    )>>, shopUnions?: Maybe<Array<(
      { __typename?: 'ShopUnion' }
      & Pick<ShopUnion, 'id'>
    )>> }
  )> }
);

export type UserAccountQueryVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
}>;


export type UserAccountQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'Account' }
    & Pick<Account, 'id' | 'email' | 'networkOperator' | 'aclCards' | 'aclWallets'>
    & { cards?: Maybe<Array<(
      { __typename?: 'Card' }
      & Pick<Card, 'id'>
    )>>, gameProfiles?: Maybe<Array<(
      { __typename: 'BishiBashiChannelProfile' }
      & Pick<BishiBashiChannelProfile, 'id' | 'name'>
    ) | (
      { __typename: 'BeatstreamProfile' }
      & Pick<BeatstreamProfile, 'id' | 'name' | 'playerId'>
    ) | (
      { __typename: 'IIDXProfile' }
      & Pick<IidxProfile, 'id' | 'name' | 'playerId'>
    ) | (
      { __typename: 'JubeatProfile' }
      & Pick<JubeatProfile, 'id' | 'name' | 'playerId'>
    ) | (
      { __typename: 'SDVXProfile' }
      & Pick<SdvxProfile, 'id' | 'name' | 'playerId'>
    ) | (
      { __typename: 'QMAProfile' }
      & Pick<QmaProfile, 'id' | 'name' | 'studentId'>
    )>>, wallets?: Maybe<Array<(
      { __typename?: 'Wallet' }
      & Pick<Wallet, 'id'>
    )>> }
  )> }
);

export type WalletListQueryVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
  withTransactions?: Maybe<Scalars['Boolean']>;
}>;


export type WalletListQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'Account' }
    & Pick<Account, 'id' | 'email'>
    & { wallets?: Maybe<Array<(
      { __typename?: 'Wallet' }
      & Pick<Wallet, 'id' | 'balance'>
      & { union?: Maybe<(
        { __typename?: 'ShopUnion' }
        & Pick<ShopUnion, 'id' | 'name'>
      )>, transactions?: Maybe<(
        { __typename?: 'WalletTransactionConnection' }
        & { items: Array<(
          { __typename?: 'WalletTransaction' }
          & Pick<WalletTransaction, 'id' | 'timestamp' | 'detail' | 'service' | 'charge' | 'balanceAfter'>
        )> }
      )> }
    )>> }
  )> }
);

export type WalletInfoQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type WalletInfoQuery = (
  { __typename?: 'Query' }
  & { node?: Maybe<(
    { __typename?: 'Account' }
    & Pick<Account, 'id'>
  ) | (
    { __typename?: 'Area' }
    & Pick<Area, 'id'>
  ) | (
    { __typename?: 'BeatstreamChart' }
    & Pick<BeatstreamChart, 'id'>
  ) | (
    { __typename?: 'BeatstreamCourseScore' }
    & Pick<BeatstreamCourseScore, 'id'>
  ) | (
    { __typename?: 'BeatstreamPlay' }
    & Pick<BeatstreamPlay, 'id'>
  ) | (
    { __typename?: 'BeatstreamProfile' }
    & Pick<BeatstreamProfile, 'id'>
  ) | (
    { __typename?: 'BeatstreamRevision' }
    & Pick<BeatstreamRevision, 'id'>
  ) | (
    { __typename?: 'BeatstreamSong' }
    & Pick<BeatstreamSong, 'id'>
  ) | (
    { __typename?: 'BishiBashiChannelProfile' }
    & Pick<BishiBashiChannelProfile, 'id'>
  ) | (
    { __typename?: 'Cabinet' }
    & Pick<Cabinet, 'id'>
  ) | (
    { __typename?: 'Card' }
    & Pick<Card, 'id'>
  ) | (
    { __typename?: 'IIDXArenaMatch' }
    & Pick<IidxArenaMatch, 'id'>
  ) | (
    { __typename?: 'IIDXArenaParticipant' }
    & Pick<IidxArenaParticipant, 'id'>
  ) | (
    { __typename?: 'IIDXArenaScore' }
    & Pick<IidxArenaScore, 'id'>
  ) | (
    { __typename?: 'IIDXArenaStage' }
    & Pick<IidxArenaStage, 'id'>
  ) | (
    { __typename?: 'IIDXBingoCard' }
    & Pick<IidxBingoCard, 'id'>
  ) | (
    { __typename?: 'IIDXBingoCardProgress' }
    & Pick<IidxBingoCardProgress, 'id'>
  ) | (
    { __typename?: 'IIDXBingoCardSlot' }
    & Pick<IidxBingoCardSlot, 'id'>
  ) | (
    { __typename?: 'IIDXChart' }
    & Pick<IidxChart, 'id'>
  ) | (
    { __typename?: 'IIDXClassCourseScore' }
    & Pick<IidxClassCourseScore, 'id'>
  ) | (
    { __typename?: 'IIDXCompetition' }
    & Pick<IidxCompetition, 'id'>
  ) | (
    { __typename?: 'IIDXDaily' }
    & Pick<IidxDaily, 'id'>
  ) | (
    { __typename?: 'IIDXGameData' }
    & Pick<IidxGameData, 'id'>
  ) | (
    { __typename?: 'IIDXMedal' }
    & Pick<IidxMedal, 'id'>
  ) | (
    { __typename?: 'IIDXNews' }
    & Pick<IidxNews, 'id'>
  ) | (
    { __typename?: 'IIDXPlay' }
    & Pick<IidxPlay, 'id'>
  ) | (
    { __typename?: 'IIDXPlayDetails' }
    & Pick<IidxPlayDetails, 'id'>
  ) | (
    { __typename?: 'IIDXProfile' }
    & Pick<IidxProfile, 'id'>
  ) | (
    { __typename?: 'IIDXQproPart' }
    & Pick<IidxQproPart, 'id'>
  ) | (
    { __typename?: 'IIDXRevision' }
    & Pick<IidxRevision, 'id'>
  ) | (
    { __typename?: 'IIDXRival' }
    & Pick<IidxRival, 'id'>
  ) | (
    { __typename?: 'IIDXSong' }
    & Pick<IidxSong, 'id'>
  ) | (
    { __typename?: 'IIDXUIPart' }
    & Pick<IidxuiPart, 'id'>
  ) | (
    { __typename?: 'IIDXWeekly' }
    & Pick<IidxWeekly, 'id'>
  ) | (
    { __typename?: 'JubeatProfile' }
    & Pick<JubeatProfile, 'id'>
  ) | (
    { __typename?: 'Notification' }
    & Pick<Notification, 'id'>
  ) | (
    { __typename?: 'Parameter' }
    & Pick<Parameter, 'id'>
  ) | (
    { __typename?: 'QMAExam' }
    & Pick<QmaExam, 'id'>
  ) | (
    { __typename?: 'QMAGameData' }
    & Pick<QmaGameData, 'id'>
  ) | (
    { __typename?: 'QMAProfile' }
    & Pick<QmaProfile, 'id'>
  ) | (
    { __typename?: 'SDVXProfile' }
    & Pick<SdvxProfile, 'id'>
  ) | (
    { __typename?: 'Shop' }
    & Pick<Shop, 'id'>
  ) | (
    { __typename?: 'ShopUnion' }
    & Pick<ShopUnion, 'id'>
  ) | (
    { __typename?: 'ShopUnionUserMembership' }
    & Pick<ShopUnionUserMembership, 'id'>
  ) | (
    { __typename?: 'ShopUserMembership' }
    & Pick<ShopUserMembership, 'id'>
  ) | (
    { __typename?: 'Wallet' }
    & Pick<Wallet, 'id' | 'balance'>
    & { account?: Maybe<(
      { __typename?: 'Account' }
      & Pick<Account, 'id' | 'email'>
    )>, union?: Maybe<(
      { __typename?: 'ShopUnion' }
      & Pick<ShopUnion, 'id' | 'name'>
    )> }
  ) | (
    { __typename?: 'WalletTransaction' }
    & Pick<WalletTransaction, 'id'>
  )> }
);

export type WalletTransactionsQueryVariables = Exact<{
  id: Scalars['ID'];
  last: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type WalletTransactionsQuery = (
  { __typename?: 'Query' }
  & { node?: Maybe<(
    { __typename?: 'Account' }
    & Pick<Account, 'id'>
  ) | (
    { __typename?: 'Area' }
    & Pick<Area, 'id'>
  ) | (
    { __typename?: 'BeatstreamChart' }
    & Pick<BeatstreamChart, 'id'>
  ) | (
    { __typename?: 'BeatstreamCourseScore' }
    & Pick<BeatstreamCourseScore, 'id'>
  ) | (
    { __typename?: 'BeatstreamPlay' }
    & Pick<BeatstreamPlay, 'id'>
  ) | (
    { __typename?: 'BeatstreamProfile' }
    & Pick<BeatstreamProfile, 'id'>
  ) | (
    { __typename?: 'BeatstreamRevision' }
    & Pick<BeatstreamRevision, 'id'>
  ) | (
    { __typename?: 'BeatstreamSong' }
    & Pick<BeatstreamSong, 'id'>
  ) | (
    { __typename?: 'BishiBashiChannelProfile' }
    & Pick<BishiBashiChannelProfile, 'id'>
  ) | (
    { __typename?: 'Cabinet' }
    & Pick<Cabinet, 'id'>
  ) | (
    { __typename?: 'Card' }
    & Pick<Card, 'id'>
  ) | (
    { __typename?: 'IIDXArenaMatch' }
    & Pick<IidxArenaMatch, 'id'>
  ) | (
    { __typename?: 'IIDXArenaParticipant' }
    & Pick<IidxArenaParticipant, 'id'>
  ) | (
    { __typename?: 'IIDXArenaScore' }
    & Pick<IidxArenaScore, 'id'>
  ) | (
    { __typename?: 'IIDXArenaStage' }
    & Pick<IidxArenaStage, 'id'>
  ) | (
    { __typename?: 'IIDXBingoCard' }
    & Pick<IidxBingoCard, 'id'>
  ) | (
    { __typename?: 'IIDXBingoCardProgress' }
    & Pick<IidxBingoCardProgress, 'id'>
  ) | (
    { __typename?: 'IIDXBingoCardSlot' }
    & Pick<IidxBingoCardSlot, 'id'>
  ) | (
    { __typename?: 'IIDXChart' }
    & Pick<IidxChart, 'id'>
  ) | (
    { __typename?: 'IIDXClassCourseScore' }
    & Pick<IidxClassCourseScore, 'id'>
  ) | (
    { __typename?: 'IIDXCompetition' }
    & Pick<IidxCompetition, 'id'>
  ) | (
    { __typename?: 'IIDXDaily' }
    & Pick<IidxDaily, 'id'>
  ) | (
    { __typename?: 'IIDXGameData' }
    & Pick<IidxGameData, 'id'>
  ) | (
    { __typename?: 'IIDXMedal' }
    & Pick<IidxMedal, 'id'>
  ) | (
    { __typename?: 'IIDXNews' }
    & Pick<IidxNews, 'id'>
  ) | (
    { __typename?: 'IIDXPlay' }
    & Pick<IidxPlay, 'id'>
  ) | (
    { __typename?: 'IIDXPlayDetails' }
    & Pick<IidxPlayDetails, 'id'>
  ) | (
    { __typename?: 'IIDXProfile' }
    & Pick<IidxProfile, 'id'>
  ) | (
    { __typename?: 'IIDXQproPart' }
    & Pick<IidxQproPart, 'id'>
  ) | (
    { __typename?: 'IIDXRevision' }
    & Pick<IidxRevision, 'id'>
  ) | (
    { __typename?: 'IIDXRival' }
    & Pick<IidxRival, 'id'>
  ) | (
    { __typename?: 'IIDXSong' }
    & Pick<IidxSong, 'id'>
  ) | (
    { __typename?: 'IIDXUIPart' }
    & Pick<IidxuiPart, 'id'>
  ) | (
    { __typename?: 'IIDXWeekly' }
    & Pick<IidxWeekly, 'id'>
  ) | (
    { __typename?: 'JubeatProfile' }
    & Pick<JubeatProfile, 'id'>
  ) | (
    { __typename?: 'Notification' }
    & Pick<Notification, 'id'>
  ) | (
    { __typename?: 'Parameter' }
    & Pick<Parameter, 'id'>
  ) | (
    { __typename?: 'QMAExam' }
    & Pick<QmaExam, 'id'>
  ) | (
    { __typename?: 'QMAGameData' }
    & Pick<QmaGameData, 'id'>
  ) | (
    { __typename?: 'QMAProfile' }
    & Pick<QmaProfile, 'id'>
  ) | (
    { __typename?: 'SDVXProfile' }
    & Pick<SdvxProfile, 'id'>
  ) | (
    { __typename?: 'Shop' }
    & Pick<Shop, 'id'>
  ) | (
    { __typename?: 'ShopUnion' }
    & Pick<ShopUnion, 'id'>
  ) | (
    { __typename?: 'ShopUnionUserMembership' }
    & Pick<ShopUnionUserMembership, 'id'>
  ) | (
    { __typename?: 'ShopUserMembership' }
    & Pick<ShopUserMembership, 'id'>
  ) | (
    { __typename?: 'Wallet' }
    & Pick<Wallet, 'id'>
    & { transactions: (
      { __typename?: 'WalletTransactionConnection' }
      & Pick<WalletTransactionConnection, 'totalItems'>
      & { items: Array<(
        { __typename?: 'WalletTransaction' }
        & Pick<WalletTransaction, 'id' | 'timestamp' | 'detail' | 'service' | 'charge' | 'balanceAfter' | 'isFreeplay'>
        & { series?: Maybe<(
          { __typename?: 'Series' }
          & Pick<Series, 'name'>
        )>, game?: Maybe<(
          { __typename?: 'Game' }
          & Pick<Game, 'name'>
        )>, shop: (
          { __typename?: 'Shop' }
          & Pick<Shop, 'id' | 'name'>
        ), cabinet: (
          { __typename?: 'Cabinet' }
          & Pick<Cabinet, 'id' | 'name'>
        ) }
      )> }
    ) }
  ) | (
    { __typename?: 'WalletTransaction' }
    & Pick<WalletTransaction, 'id'>
  )> }
);

export const TransactionInfoFragmentDoc = gql`
    fragment transactionInfo on WalletTransactionConnection {
  totalItems
  items {
    id
    timestamp
    detail
    service
    charge
    isFreeplay
    series {
      name
    }
    game {
      name
    }
    shop {
      id
      name
    }
    cabinet {
      id
      name
    }
  }
}
    `;
export const StatisticsDocument = gql`
    query statistics($query: AnalyticsQuery!, $filterBy: ID, $groupBy: AnalyticsGroup, $since: AnalyticsRelativeTimeRange, $timeRange: AnalyticsAbsoluteTimeRange, $bucketSize: AnalyticsBucketSize) {
  statistics(
    query: $query
    filterBy: $filterBy
    groupBy: $groupBy
    since: $since
    timeRange: $timeRange
    bucketSize: $bucketSize
  ) {
    periodStart
    isEmptyBucket
    group {
      ... on Node {
        id
      }
      ... on Cabinet {
        cabinetName: name
        shop {
          id
          name
        }
      }
      ... on Shop {
        shopName: name
      }
      ... on ShopUnion {
        unionName: name
      }
      ... on Game {
        gameName: name
      }
      ... on Series {
        seriesName: name
      }
    }
    data {
      ... on PlayAnalyticsData {
        plays
      }
      ... on IncomeAnalyticsData {
        transactions
        serviceCredit
        freePlay
        charge
        spent
      }
    }
  }
}
    `;

/**
 * __useStatisticsQuery__
 *
 * To run a query within a React component, call `useStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatisticsQuery({
 *   variables: {
 *      query: // value for 'query'
 *      filterBy: // value for 'filterBy'
 *      groupBy: // value for 'groupBy'
 *      since: // value for 'since'
 *      timeRange: // value for 'timeRange'
 *      bucketSize: // value for 'bucketSize'
 *   },
 * });
 */
export function useStatisticsQuery(baseOptions: Apollo.QueryHookOptions<StatisticsQuery, StatisticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StatisticsQuery, StatisticsQueryVariables>(StatisticsDocument, options);
      }
export function useStatisticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StatisticsQuery, StatisticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StatisticsQuery, StatisticsQueryVariables>(StatisticsDocument, options);
        }
export type StatisticsQueryHookResult = ReturnType<typeof useStatisticsQuery>;
export type StatisticsLazyQueryHookResult = ReturnType<typeof useStatisticsLazyQuery>;
export type StatisticsQueryResult = Apollo.QueryResult<StatisticsQuery, StatisticsQueryVariables>;
export const UserDetailsDocument = gql`
    query userDetails {
  me {
    id
    networkOperator
    aclShop
    shops {
      id
    }
    shopUnions {
      id
    }
  }
}
    `;

/**
 * __useUserDetailsQuery__
 *
 * To run a query within a React component, call `useUserDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserDetailsQuery(baseOptions?: Apollo.QueryHookOptions<UserDetailsQuery, UserDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserDetailsQuery, UserDetailsQueryVariables>(UserDetailsDocument, options);
      }
export function useUserDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserDetailsQuery, UserDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserDetailsQuery, UserDetailsQueryVariables>(UserDetailsDocument, options);
        }
export type UserDetailsQueryHookResult = ReturnType<typeof useUserDetailsQuery>;
export type UserDetailsLazyQueryHookResult = ReturnType<typeof useUserDetailsLazyQuery>;
export type UserDetailsQueryResult = Apollo.QueryResult<UserDetailsQuery, UserDetailsQueryVariables>;
export const ServiceUrlsDocument = gql`
    query serviceUrls {
  apiDetails {
    accountSettingsUrl
    apiDocsUrl
    assetsBaseUrl
    scoreImportUrl
  }
}
    `;

/**
 * __useServiceUrlsQuery__
 *
 * To run a query within a React component, call `useServiceUrlsQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceUrlsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceUrlsQuery({
 *   variables: {
 *   },
 * });
 */
export function useServiceUrlsQuery(baseOptions?: Apollo.QueryHookOptions<ServiceUrlsQuery, ServiceUrlsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ServiceUrlsQuery, ServiceUrlsQueryVariables>(ServiceUrlsDocument, options);
      }
export function useServiceUrlsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ServiceUrlsQuery, ServiceUrlsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ServiceUrlsQuery, ServiceUrlsQueryVariables>(ServiceUrlsDocument, options);
        }
export type ServiceUrlsQueryHookResult = ReturnType<typeof useServiceUrlsQuery>;
export type ServiceUrlsLazyQueryHookResult = ReturnType<typeof useServiceUrlsLazyQuery>;
export type ServiceUrlsQueryResult = Apollo.QueryResult<ServiceUrlsQuery, ServiceUrlsQueryVariables>;
export const CardListDocument = gql`
    query cardList($id: ID) {
  user(id: $id) {
    id
    email
    cards {
      id
      tagId
      tagType
      displayId
      registered
      accessed
    }
  }
}
    `;

/**
 * __useCardListQuery__
 *
 * To run a query within a React component, call `useCardListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCardListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCardListQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCardListQuery(baseOptions?: Apollo.QueryHookOptions<CardListQuery, CardListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CardListQuery, CardListQueryVariables>(CardListDocument, options);
      }
export function useCardListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CardListQuery, CardListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CardListQuery, CardListQueryVariables>(CardListDocument, options);
        }
export type CardListQueryHookResult = ReturnType<typeof useCardListQuery>;
export type CardListLazyQueryHookResult = ReturnType<typeof useCardListLazyQuery>;
export type CardListQueryResult = Apollo.QueryResult<CardListQuery, CardListQueryVariables>;
export const AddCardDocument = gql`
    mutation addCard($accountId: ID, $tagId: String!, $tagType: CardType!) {
  addCard(account: $accountId, tagId: $tagId, tagType: $tagType) {
    card {
      id
    }
    ok
  }
}
    `;
export type AddCardMutationFn = Apollo.MutationFunction<AddCardMutation, AddCardMutationVariables>;

/**
 * __useAddCardMutation__
 *
 * To run a mutation, you first call `useAddCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCardMutation, { data, loading, error }] = useAddCardMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      tagId: // value for 'tagId'
 *      tagType: // value for 'tagType'
 *   },
 * });
 */
export function useAddCardMutation(baseOptions?: Apollo.MutationHookOptions<AddCardMutation, AddCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCardMutation, AddCardMutationVariables>(AddCardDocument, options);
      }
export type AddCardMutationHookResult = ReturnType<typeof useAddCardMutation>;
export type AddCardMutationResult = Apollo.MutationResult<AddCardMutation>;
export type AddCardMutationOptions = Apollo.BaseMutationOptions<AddCardMutation, AddCardMutationVariables>;
export const DeleteCardDocument = gql`
    mutation deleteCard($card: ID!) {
  deleteCard(card: $card) {
    ok
  }
}
    `;
export type DeleteCardMutationFn = Apollo.MutationFunction<DeleteCardMutation, DeleteCardMutationVariables>;

/**
 * __useDeleteCardMutation__
 *
 * To run a mutation, you first call `useDeleteCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCardMutation, { data, loading, error }] = useDeleteCardMutation({
 *   variables: {
 *      card: // value for 'card'
 *   },
 * });
 */
export function useDeleteCardMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCardMutation, DeleteCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCardMutation, DeleteCardMutationVariables>(DeleteCardDocument, options);
      }
export type DeleteCardMutationHookResult = ReturnType<typeof useDeleteCardMutation>;
export type DeleteCardMutationResult = Apollo.MutationResult<DeleteCardMutation>;
export type DeleteCardMutationOptions = Apollo.BaseMutationOptions<DeleteCardMutation, DeleteCardMutationVariables>;
export const GameListDocument = gql`
    query gameList($game: String) {
  series(shortname: $game) {
    name
    shortname
    games {
      version
      name
    }
  }
}
    `;

/**
 * __useGameListQuery__
 *
 * To run a query within a React component, call `useGameListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGameListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGameListQuery({
 *   variables: {
 *      game: // value for 'game'
 *   },
 * });
 */
export function useGameListQuery(baseOptions?: Apollo.QueryHookOptions<GameListQuery, GameListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GameListQuery, GameListQueryVariables>(GameListDocument, options);
      }
export function useGameListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GameListQuery, GameListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GameListQuery, GameListQueryVariables>(GameListDocument, options);
        }
export type GameListQueryHookResult = ReturnType<typeof useGameListQuery>;
export type GameListLazyQueryHookResult = ReturnType<typeof useGameListLazyQuery>;
export type GameListQueryResult = Apollo.QueryResult<GameListQuery, GameListQueryVariables>;
export const UserGameProfileDocument = gql`
    query userGameProfile($game: String!) {
  me {
    id
    gameProfile(shortname: $game) {
      ... on Node {
        id
      }
    }
  }
}
    `;

/**
 * __useUserGameProfileQuery__
 *
 * To run a query within a React component, call `useUserGameProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserGameProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserGameProfileQuery({
 *   variables: {
 *      game: // value for 'game'
 *   },
 * });
 */
export function useUserGameProfileQuery(baseOptions: Apollo.QueryHookOptions<UserGameProfileQuery, UserGameProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserGameProfileQuery, UserGameProfileQueryVariables>(UserGameProfileDocument, options);
      }
export function useUserGameProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserGameProfileQuery, UserGameProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserGameProfileQuery, UserGameProfileQueryVariables>(UserGameProfileDocument, options);
        }
export type UserGameProfileQueryHookResult = ReturnType<typeof useUserGameProfileQuery>;
export type UserGameProfileLazyQueryHookResult = ReturnType<typeof useUserGameProfileLazyQuery>;
export type UserGameProfileQueryResult = Apollo.QueryResult<UserGameProfileQuery, UserGameProfileQueryVariables>;
export const BbcProfileIdDocument = gql`
    query bbcProfileId {
  me {
    id
    gameProfile(shortname: "bbc") {
      ... on Node {
        id
      }
    }
  }
}
    `;

/**
 * __useBbcProfileIdQuery__
 *
 * To run a query within a React component, call `useBbcProfileIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useBbcProfileIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBbcProfileIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useBbcProfileIdQuery(baseOptions?: Apollo.QueryHookOptions<BbcProfileIdQuery, BbcProfileIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BbcProfileIdQuery, BbcProfileIdQueryVariables>(BbcProfileIdDocument, options);
      }
export function useBbcProfileIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BbcProfileIdQuery, BbcProfileIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BbcProfileIdQuery, BbcProfileIdQueryVariables>(BbcProfileIdDocument, options);
        }
export type BbcProfileIdQueryHookResult = ReturnType<typeof useBbcProfileIdQuery>;
export type BbcProfileIdLazyQueryHookResult = ReturnType<typeof useBbcProfileIdLazyQuery>;
export type BbcProfileIdQueryResult = Apollo.QueryResult<BbcProfileIdQuery, BbcProfileIdQueryVariables>;
export const BbcProfileSearchDocument = gql`
    query bbcProfileSearch($last: Int!, $offset: Int!, $name: String, $lastPlay: Int) {
  bbc {
    profileSearch(last: $last, offset: $offset, name: $name, lastPlay: $lastPlay) {
      totalItems
      items {
        id
        name
        accessed
      }
    }
  }
}
    `;

/**
 * __useBbcProfileSearchQuery__
 *
 * To run a query within a React component, call `useBbcProfileSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useBbcProfileSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBbcProfileSearchQuery({
 *   variables: {
 *      last: // value for 'last'
 *      offset: // value for 'offset'
 *      name: // value for 'name'
 *      lastPlay: // value for 'lastPlay'
 *   },
 * });
 */
export function useBbcProfileSearchQuery(baseOptions: Apollo.QueryHookOptions<BbcProfileSearchQuery, BbcProfileSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BbcProfileSearchQuery, BbcProfileSearchQueryVariables>(BbcProfileSearchDocument, options);
      }
export function useBbcProfileSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BbcProfileSearchQuery, BbcProfileSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BbcProfileSearchQuery, BbcProfileSearchQueryVariables>(BbcProfileSearchDocument, options);
        }
export type BbcProfileSearchQueryHookResult = ReturnType<typeof useBbcProfileSearchQuery>;
export type BbcProfileSearchLazyQueryHookResult = ReturnType<typeof useBbcProfileSearchLazyQuery>;
export type BbcProfileSearchQueryResult = Apollo.QueryResult<BbcProfileSearchQuery, BbcProfileSearchQueryVariables>;
export const BbcProfileDocument = gql`
    query bbcProfile($profile: ID) {
  bbc {
    profileLookup(id: $profile) {
      id
      name
      accessed
    }
  }
}
    `;

/**
 * __useBbcProfileQuery__
 *
 * To run a query within a React component, call `useBbcProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useBbcProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBbcProfileQuery({
 *   variables: {
 *      profile: // value for 'profile'
 *   },
 * });
 */
export function useBbcProfileQuery(baseOptions?: Apollo.QueryHookOptions<BbcProfileQuery, BbcProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BbcProfileQuery, BbcProfileQueryVariables>(BbcProfileDocument, options);
      }
export function useBbcProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BbcProfileQuery, BbcProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BbcProfileQuery, BbcProfileQueryVariables>(BbcProfileDocument, options);
        }
export type BbcProfileQueryHookResult = ReturnType<typeof useBbcProfileQuery>;
export type BbcProfileLazyQueryHookResult = ReturnType<typeof useBbcProfileLazyQuery>;
export type BbcProfileQueryResult = Apollo.QueryResult<BbcProfileQuery, BbcProfileQueryVariables>;
export const BstProfileIdDocument = gql`
    query bstProfileId {
  me {
    id
    gameProfile(shortname: "bst") {
      ... on Node {
        id
      }
    }
  }
}
    `;

/**
 * __useBstProfileIdQuery__
 *
 * To run a query within a React component, call `useBstProfileIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useBstProfileIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBstProfileIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useBstProfileIdQuery(baseOptions?: Apollo.QueryHookOptions<BstProfileIdQuery, BstProfileIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BstProfileIdQuery, BstProfileIdQueryVariables>(BstProfileIdDocument, options);
      }
export function useBstProfileIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BstProfileIdQuery, BstProfileIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BstProfileIdQuery, BstProfileIdQueryVariables>(BstProfileIdDocument, options);
        }
export type BstProfileIdQueryHookResult = ReturnType<typeof useBstProfileIdQuery>;
export type BstProfileIdLazyQueryHookResult = ReturnType<typeof useBstProfileIdLazyQuery>;
export type BstProfileIdQueryResult = Apollo.QueryResult<BstProfileIdQuery, BstProfileIdQueryVariables>;
export const BstProfileSearchDocument = gql`
    query bstProfileSearch($last: Int!, $offset: Int!, $name: String, $lastPlay: Int) {
  bst {
    profileSearch(last: $last, offset: $offset, name: $name, lastPlay: $lastPlay) {
      totalItems
      items {
        id
        playerId
        name
        registered
        accessed
        courseRank
      }
    }
  }
}
    `;

/**
 * __useBstProfileSearchQuery__
 *
 * To run a query within a React component, call `useBstProfileSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useBstProfileSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBstProfileSearchQuery({
 *   variables: {
 *      last: // value for 'last'
 *      offset: // value for 'offset'
 *      name: // value for 'name'
 *      lastPlay: // value for 'lastPlay'
 *   },
 * });
 */
export function useBstProfileSearchQuery(baseOptions: Apollo.QueryHookOptions<BstProfileSearchQuery, BstProfileSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BstProfileSearchQuery, BstProfileSearchQueryVariables>(BstProfileSearchDocument, options);
      }
export function useBstProfileSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BstProfileSearchQuery, BstProfileSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BstProfileSearchQuery, BstProfileSearchQueryVariables>(BstProfileSearchDocument, options);
        }
export type BstProfileSearchQueryHookResult = ReturnType<typeof useBstProfileSearchQuery>;
export type BstProfileSearchLazyQueryHookResult = ReturnType<typeof useBstProfileSearchLazyQuery>;
export type BstProfileSearchQueryResult = Apollo.QueryResult<BstProfileSearchQuery, BstProfileSearchQueryVariables>;
export const BstProfileDocument = gql`
    query bstProfile($profile: ID, $withBattles: Boolean = false) {
  bst {
    profileLookup(id: $profile) {
      id
      playerId
      name
      registered
      accessed
      courseRank
      scoreBattle @include(if: $withBattles) {
        wins
        losses
      }
    }
  }
}
    `;

/**
 * __useBstProfileQuery__
 *
 * To run a query within a React component, call `useBstProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useBstProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBstProfileQuery({
 *   variables: {
 *      profile: // value for 'profile'
 *      withBattles: // value for 'withBattles'
 *   },
 * });
 */
export function useBstProfileQuery(baseOptions?: Apollo.QueryHookOptions<BstProfileQuery, BstProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BstProfileQuery, BstProfileQueryVariables>(BstProfileDocument, options);
      }
export function useBstProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BstProfileQuery, BstProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BstProfileQuery, BstProfileQueryVariables>(BstProfileDocument, options);
        }
export type BstProfileQueryHookResult = ReturnType<typeof useBstProfileQuery>;
export type BstProfileLazyQueryHookResult = ReturnType<typeof useBstProfileLazyQuery>;
export type BstProfileQueryResult = Apollo.QueryResult<BstProfileQuery, BstProfileQueryVariables>;
export const BstPlaysDocument = gql`
    query bstPlays($profile: ID, $last: Int!, $offset: Int!) {
  bst {
    profileLookup(id: $profile) {
      id
      playHistogram {
        period
        plays
      }
      plays(last: $last, offset: $offset) {
        totalItems
        items {
          id
          timestamp
          score
          medal
          grade
          judgeMiss
          judgeFine
          judgeGreat
          judgeFantastic
          game {
            name
          }
          chart {
            id
            revision {
              id
            }
            difficulty
            level
            hasCustomJacket
          }
          song {
            id
            title
            artist
            filename
          }
          coursePlay {
            id
            rank
            stagesCleared
            plays {
              id
            }
          }
          shop {
            id
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useBstPlaysQuery__
 *
 * To run a query within a React component, call `useBstPlaysQuery` and pass it any options that fit your needs.
 * When your component renders, `useBstPlaysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBstPlaysQuery({
 *   variables: {
 *      profile: // value for 'profile'
 *      last: // value for 'last'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useBstPlaysQuery(baseOptions: Apollo.QueryHookOptions<BstPlaysQuery, BstPlaysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BstPlaysQuery, BstPlaysQueryVariables>(BstPlaysDocument, options);
      }
export function useBstPlaysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BstPlaysQuery, BstPlaysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BstPlaysQuery, BstPlaysQueryVariables>(BstPlaysDocument, options);
        }
export type BstPlaysQueryHookResult = ReturnType<typeof useBstPlaysQuery>;
export type BstPlaysLazyQueryHookResult = ReturnType<typeof useBstPlaysLazyQuery>;
export type BstPlaysQueryResult = Apollo.QueryResult<BstPlaysQuery, BstPlaysQueryVariables>;
export const BstPlayHistoryDocument = gql`
    query bstPlayHistory($profile: ID, $revision: ID!) {
  bst {
    profileLookup(id: $profile) {
      id
      plays(last: 10, offset: 0, revision: $revision) {
        totalItems
        items {
          id
          timestamp
          score
          medal
          grade
          shop {
            id
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useBstPlayHistoryQuery__
 *
 * To run a query within a React component, call `useBstPlayHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useBstPlayHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBstPlayHistoryQuery({
 *   variables: {
 *      profile: // value for 'profile'
 *      revision: // value for 'revision'
 *   },
 * });
 */
export function useBstPlayHistoryQuery(baseOptions: Apollo.QueryHookOptions<BstPlayHistoryQuery, BstPlayHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BstPlayHistoryQuery, BstPlayHistoryQueryVariables>(BstPlayHistoryDocument, options);
      }
export function useBstPlayHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BstPlayHistoryQuery, BstPlayHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BstPlayHistoryQuery, BstPlayHistoryQueryVariables>(BstPlayHistoryDocument, options);
        }
export type BstPlayHistoryQueryHookResult = ReturnType<typeof useBstPlayHistoryQuery>;
export type BstPlayHistoryLazyQueryHookResult = ReturnType<typeof useBstPlayHistoryLazyQuery>;
export type BstPlayHistoryQueryResult = Apollo.QueryResult<BstPlayHistoryQuery, BstPlayHistoryQueryVariables>;
export const BstChartLeaderboardDocument = gql`
    query bstChartLeaderboard($chart: ID!) {
  bst {
    chart(id: $chart) {
      id
      difficulty
      level
      hasCustomJacket
      genre
      song {
        id
        title
        subTitle
        artist
        filename
        bpmMin
        bpmMax
        introducedIn {
          name
        }
      }
      siblingCharts {
        id
        difficulty
        level
      }
      revision {
        id
        highscores {
          profile {
            id
            name
          }
          play {
            id
            grade
          }
          game {
            name
            version
          }
          score
          medal
          timestamp
        }
      }
    }
  }
}
    `;

/**
 * __useBstChartLeaderboardQuery__
 *
 * To run a query within a React component, call `useBstChartLeaderboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useBstChartLeaderboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBstChartLeaderboardQuery({
 *   variables: {
 *      chart: // value for 'chart'
 *   },
 * });
 */
export function useBstChartLeaderboardQuery(baseOptions: Apollo.QueryHookOptions<BstChartLeaderboardQuery, BstChartLeaderboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BstChartLeaderboardQuery, BstChartLeaderboardQueryVariables>(BstChartLeaderboardDocument, options);
      }
export function useBstChartLeaderboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BstChartLeaderboardQuery, BstChartLeaderboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BstChartLeaderboardQuery, BstChartLeaderboardQueryVariables>(BstChartLeaderboardDocument, options);
        }
export type BstChartLeaderboardQueryHookResult = ReturnType<typeof useBstChartLeaderboardQuery>;
export type BstChartLeaderboardLazyQueryHookResult = ReturnType<typeof useBstChartLeaderboardLazyQuery>;
export type BstChartLeaderboardQueryResult = Apollo.QueryResult<BstChartLeaderboardQuery, BstChartLeaderboardQueryVariables>;
export const BstSongListDocument = gql`
    query bstSongList($last: Int!, $offset: Int!) {
  bst {
    songs(last: $last, offset: $offset) {
      items {
        id
        title
        subTitle
        artist
        filename
      }
      totalItems
    }
  }
}
    `;

/**
 * __useBstSongListQuery__
 *
 * To run a query within a React component, call `useBstSongListQuery` and pass it any options that fit your needs.
 * When your component renders, `useBstSongListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBstSongListQuery({
 *   variables: {
 *      last: // value for 'last'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useBstSongListQuery(baseOptions: Apollo.QueryHookOptions<BstSongListQuery, BstSongListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BstSongListQuery, BstSongListQueryVariables>(BstSongListDocument, options);
      }
export function useBstSongListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BstSongListQuery, BstSongListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BstSongListQuery, BstSongListQueryVariables>(BstSongListDocument, options);
        }
export type BstSongListQueryHookResult = ReturnType<typeof useBstSongListQuery>;
export type BstSongListLazyQueryHookResult = ReturnType<typeof useBstSongListLazyQuery>;
export type BstSongListQueryResult = Apollo.QueryResult<BstSongListQuery, BstSongListQueryVariables>;
export const BstSongInfoDocument = gql`
    query bstSongInfo($song: ID!) {
  bst {
    song(id: $song) {
      id
      title
      subTitle
      artist
      filename
      charts {
        id
        difficulty
        level
        hasCustomJacket
        game {
          name
          version
        }
      }
    }
  }
}
    `;

/**
 * __useBstSongInfoQuery__
 *
 * To run a query within a React component, call `useBstSongInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useBstSongInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBstSongInfoQuery({
 *   variables: {
 *      song: // value for 'song'
 *   },
 * });
 */
export function useBstSongInfoQuery(baseOptions: Apollo.QueryHookOptions<BstSongInfoQuery, BstSongInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BstSongInfoQuery, BstSongInfoQueryVariables>(BstSongInfoDocument, options);
      }
export function useBstSongInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BstSongInfoQuery, BstSongInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BstSongInfoQuery, BstSongInfoQueryVariables>(BstSongInfoDocument, options);
        }
export type BstSongInfoQueryHookResult = ReturnType<typeof useBstSongInfoQuery>;
export type BstSongInfoLazyQueryHookResult = ReturnType<typeof useBstSongInfoLazyQuery>;
export type BstSongInfoQueryResult = Apollo.QueryResult<BstSongInfoQuery, BstSongInfoQueryVariables>;
export const BstCompareScoresDocument = gql`
    query bstCompareScores($last: Int!, $offset: Int!, $version: Int, $players: [ID!]!) {
  bst {
    songs(last: $last, offset: $offset, version: $version, useDefaultGame: true) {
      totalItems
      items {
        id
        title
        artist
        charts(version: $version, useDefaultGame: true) {
          id
          difficulty
          level
          revision {
            id
            highscores(players: $players) {
              profileId
              medal
              play {
                id
                grade
              }
              score
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useBstCompareScoresQuery__
 *
 * To run a query within a React component, call `useBstCompareScoresQuery` and pass it any options that fit your needs.
 * When your component renders, `useBstCompareScoresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBstCompareScoresQuery({
 *   variables: {
 *      last: // value for 'last'
 *      offset: // value for 'offset'
 *      version: // value for 'version'
 *      players: // value for 'players'
 *   },
 * });
 */
export function useBstCompareScoresQuery(baseOptions: Apollo.QueryHookOptions<BstCompareScoresQuery, BstCompareScoresQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BstCompareScoresQuery, BstCompareScoresQueryVariables>(BstCompareScoresDocument, options);
      }
export function useBstCompareScoresLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BstCompareScoresQuery, BstCompareScoresQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BstCompareScoresQuery, BstCompareScoresQueryVariables>(BstCompareScoresDocument, options);
        }
export type BstCompareScoresQueryHookResult = ReturnType<typeof useBstCompareScoresQuery>;
export type BstCompareScoresLazyQueryHookResult = ReturnType<typeof useBstCompareScoresLazyQuery>;
export type BstCompareScoresQueryResult = Apollo.QueryResult<BstCompareScoresQuery, BstCompareScoresQueryVariables>;
export const IidxProfileIdDocument = gql`
    query iidxProfileId {
  me {
    id
    gameProfile(shortname: "iidx") {
      ... on Node {
        id
      }
    }
  }
}
    `;

/**
 * __useIidxProfileIdQuery__
 *
 * To run a query within a React component, call `useIidxProfileIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useIidxProfileIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIidxProfileIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useIidxProfileIdQuery(baseOptions?: Apollo.QueryHookOptions<IidxProfileIdQuery, IidxProfileIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IidxProfileIdQuery, IidxProfileIdQueryVariables>(IidxProfileIdDocument, options);
      }
export function useIidxProfileIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IidxProfileIdQuery, IidxProfileIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IidxProfileIdQuery, IidxProfileIdQueryVariables>(IidxProfileIdDocument, options);
        }
export type IidxProfileIdQueryHookResult = ReturnType<typeof useIidxProfileIdQuery>;
export type IidxProfileIdLazyQueryHookResult = ReturnType<typeof useIidxProfileIdLazyQuery>;
export type IidxProfileIdQueryResult = Apollo.QueryResult<IidxProfileIdQuery, IidxProfileIdQueryVariables>;
export const IidxProfileSearchDocument = gql`
    query iidxProfileSearch($last: Int!, $offset: Int!, $name: String, $lastPlay: Int) {
  iidx {
    profileSearch(last: $last, offset: $offset, name: $name, lastPlay: $lastPlay) {
      totalItems
      items {
        id
        playerId
        name
        registered
        accessed
        spArenaClass
        spDjPoints
        spNotesRadar {
          notes
          chord
          peak
          charge
          scratch
          soflan
        }
        spRank {
          id
          rank
          playStyle
        }
        dpArenaClass
        dpDjPoints
        dpNotesRadar {
          notes
          chord
          peak
          charge
          scratch
          soflan
        }
        dpRank {
          id
          rank
          playStyle
        }
        qpro {
          head {
            id
            name
          }
          face {
            id
            name
          }
          hair {
            id
            name
          }
          hand {
            id
            name
          }
          body {
            id
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useIidxProfileSearchQuery__
 *
 * To run a query within a React component, call `useIidxProfileSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useIidxProfileSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIidxProfileSearchQuery({
 *   variables: {
 *      last: // value for 'last'
 *      offset: // value for 'offset'
 *      name: // value for 'name'
 *      lastPlay: // value for 'lastPlay'
 *   },
 * });
 */
export function useIidxProfileSearchQuery(baseOptions: Apollo.QueryHookOptions<IidxProfileSearchQuery, IidxProfileSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IidxProfileSearchQuery, IidxProfileSearchQueryVariables>(IidxProfileSearchDocument, options);
      }
export function useIidxProfileSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IidxProfileSearchQuery, IidxProfileSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IidxProfileSearchQuery, IidxProfileSearchQueryVariables>(IidxProfileSearchDocument, options);
        }
export type IidxProfileSearchQueryHookResult = ReturnType<typeof useIidxProfileSearchQuery>;
export type IidxProfileSearchLazyQueryHookResult = ReturnType<typeof useIidxProfileSearchLazyQuery>;
export type IidxProfileSearchQueryResult = Apollo.QueryResult<IidxProfileSearchQuery, IidxProfileSearchQueryVariables>;
export const IidxProfileDocument = gql`
    query iidxProfile($profile: ID, $withRivals: Boolean = false, $withClassRank: Boolean = false, $withQpro: Boolean = false, $withBattles: Boolean = false, $withMedals: Boolean = false, $withBingo: Boolean = false) {
  iidx {
    profileLookup(id: $profile) {
      id
      playerId
      name
      registered
      accessed
      bingo @include(if: $withBingo) {
        myCard {
          id
          size
          playerProgress(profile: $profile) {
            id
            clearedSlots
          }
        }
        discipleCard {
          id
          size
          playerProgress(profile: $profile) {
            id
            clearedSlots
          }
        }
      }
      medals @include(if: $withMedals) {
        platinum
        gold
        silver
        bronze
        black
      }
      qpro @include(if: $withQpro) {
        head {
          id
          name
        }
        face {
          id
          name
        }
        hair {
          id
          name
        }
        hand {
          id
          name
        }
        body {
          id
          name
        }
      }
      scoreBattle @include(if: $withBattles) {
        wins
        losses
      }
      spDjPoints
      spArenaClass
      spNotesRadar {
        notes
        chord
        peak
        charge
        scratch
        soflan
      }
      spRank @include(if: $withClassRank) {
        id
        rank
        playStyle
        courseCleared
        achievementRate
        playtime
        game {
          version
          name
        }
        plays {
          id
          song {
            id
            title
          }
          chart {
            id
            maxExscore
          }
          exscore
        }
      }
      dpDjPoints
      dpArenaClass
      dpNotesRadar {
        notes
        chord
        peak
        charge
        scratch
        soflan
      }
      dpRank @include(if: $withClassRank) {
        id
        rank
        playStyle
        courseCleared
        achievementRate
        playtime
        game {
          version
          name
        }
        plays {
          song {
            title
          }
          chart {
            maxExscore
          }
          exscore
        }
      }
      rivals @include(if: $withRivals) {
        id
        playStyle
        rival {
          id
          name
          qpro @include(if: $withQpro) {
            head {
              id
              name
            }
            face {
              id
              name
            }
            hair {
              id
              name
            }
            hand {
              id
              name
            }
            body {
              id
              name
            }
          }
        }
      }
      area {
        id
        country
        titleEn
      }
      forceUnlockCurrent
      forceUnlockPrevious
      arenaComment1
      arenaComment2
      arenaComment3
      arenaComment4
      newsSettings {
        topics
        types
        charts
      }
    }
  }
}
    `;

/**
 * __useIidxProfileQuery__
 *
 * To run a query within a React component, call `useIidxProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useIidxProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIidxProfileQuery({
 *   variables: {
 *      profile: // value for 'profile'
 *      withRivals: // value for 'withRivals'
 *      withClassRank: // value for 'withClassRank'
 *      withQpro: // value for 'withQpro'
 *      withBattles: // value for 'withBattles'
 *      withMedals: // value for 'withMedals'
 *      withBingo: // value for 'withBingo'
 *   },
 * });
 */
export function useIidxProfileQuery(baseOptions?: Apollo.QueryHookOptions<IidxProfileQuery, IidxProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IidxProfileQuery, IidxProfileQueryVariables>(IidxProfileDocument, options);
      }
export function useIidxProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IidxProfileQuery, IidxProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IidxProfileQuery, IidxProfileQueryVariables>(IidxProfileDocument, options);
        }
export type IidxProfileQueryHookResult = ReturnType<typeof useIidxProfileQuery>;
export type IidxProfileLazyQueryHookResult = ReturnType<typeof useIidxProfileLazyQuery>;
export type IidxProfileQueryResult = Apollo.QueryResult<IidxProfileQuery, IidxProfileQueryVariables>;
export const IidxClassCourseScoresDocument = gql`
    query iidxClassCourseScores($profile: ID, $last: Int!, $offset: Int!) {
  iidx {
    profileLookup(id: $profile) {
      id
      classCourseScores(last: $last, offset: $offset) {
        totalItems
        items {
          id
          rank
          playStyle
          courseCleared
          isHidden
          isIgnored
          isEx
          isMirror
          stagesCleared
          achievementRate
          playtime
          game {
            version
            name
          }
          shop {
            id
            name
          }
          plays {
            id
            lamp
            gauge
            ghost
            song {
              id
              title
              artist
            }
            chart {
              id
              playstyle
              level
              diff
              maxExscore
            }
            exscore
          }
        }
      }
    }
  }
}
    `;

/**
 * __useIidxClassCourseScoresQuery__
 *
 * To run a query within a React component, call `useIidxClassCourseScoresQuery` and pass it any options that fit your needs.
 * When your component renders, `useIidxClassCourseScoresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIidxClassCourseScoresQuery({
 *   variables: {
 *      profile: // value for 'profile'
 *      last: // value for 'last'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useIidxClassCourseScoresQuery(baseOptions: Apollo.QueryHookOptions<IidxClassCourseScoresQuery, IidxClassCourseScoresQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IidxClassCourseScoresQuery, IidxClassCourseScoresQueryVariables>(IidxClassCourseScoresDocument, options);
      }
export function useIidxClassCourseScoresLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IidxClassCourseScoresQuery, IidxClassCourseScoresQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IidxClassCourseScoresQuery, IidxClassCourseScoresQueryVariables>(IidxClassCourseScoresDocument, options);
        }
export type IidxClassCourseScoresQueryHookResult = ReturnType<typeof useIidxClassCourseScoresQuery>;
export type IidxClassCourseScoresLazyQueryHookResult = ReturnType<typeof useIidxClassCourseScoresLazyQuery>;
export type IidxClassCourseScoresQueryResult = Apollo.QueryResult<IidxClassCourseScoresQuery, IidxClassCourseScoresQueryVariables>;
export const IidxPlayHistogramDocument = gql`
    query iidxPlayHistogram($profile: ID) {
  iidx {
    profileLookup(id: $profile) {
      id
      playHistogram {
        period
        plays
      }
    }
  }
}
    `;

/**
 * __useIidxPlayHistogramQuery__
 *
 * To run a query within a React component, call `useIidxPlayHistogramQuery` and pass it any options that fit your needs.
 * When your component renders, `useIidxPlayHistogramQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIidxPlayHistogramQuery({
 *   variables: {
 *      profile: // value for 'profile'
 *   },
 * });
 */
export function useIidxPlayHistogramQuery(baseOptions?: Apollo.QueryHookOptions<IidxPlayHistogramQuery, IidxPlayHistogramQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IidxPlayHistogramQuery, IidxPlayHistogramQueryVariables>(IidxPlayHistogramDocument, options);
      }
export function useIidxPlayHistogramLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IidxPlayHistogramQuery, IidxPlayHistogramQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IidxPlayHistogramQuery, IidxPlayHistogramQueryVariables>(IidxPlayHistogramDocument, options);
        }
export type IidxPlayHistogramQueryHookResult = ReturnType<typeof useIidxPlayHistogramQuery>;
export type IidxPlayHistogramLazyQueryHookResult = ReturnType<typeof useIidxPlayHistogramLazyQuery>;
export type IidxPlayHistogramQueryResult = Apollo.QueryResult<IidxPlayHistogramQuery, IidxPlayHistogramQueryVariables>;
export const IidxPlayGradeStatsDocument = gql`
    query iidxPlayGradeStats($profile: ID) {
  iidx {
    profileLookup(id: $profile) {
      id
      playStats(query: GRADES) {
        count
        grade
      }
    }
  }
}
    `;

/**
 * __useIidxPlayGradeStatsQuery__
 *
 * To run a query within a React component, call `useIidxPlayGradeStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useIidxPlayGradeStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIidxPlayGradeStatsQuery({
 *   variables: {
 *      profile: // value for 'profile'
 *   },
 * });
 */
export function useIidxPlayGradeStatsQuery(baseOptions?: Apollo.QueryHookOptions<IidxPlayGradeStatsQuery, IidxPlayGradeStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IidxPlayGradeStatsQuery, IidxPlayGradeStatsQueryVariables>(IidxPlayGradeStatsDocument, options);
      }
export function useIidxPlayGradeStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IidxPlayGradeStatsQuery, IidxPlayGradeStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IidxPlayGradeStatsQuery, IidxPlayGradeStatsQueryVariables>(IidxPlayGradeStatsDocument, options);
        }
export type IidxPlayGradeStatsQueryHookResult = ReturnType<typeof useIidxPlayGradeStatsQuery>;
export type IidxPlayGradeStatsLazyQueryHookResult = ReturnType<typeof useIidxPlayGradeStatsLazyQuery>;
export type IidxPlayGradeStatsQueryResult = Apollo.QueryResult<IidxPlayGradeStatsQuery, IidxPlayGradeStatsQueryVariables>;
export const IidxPlayClearStatsDocument = gql`
    query iidxPlayClearStats($profile: ID) {
  iidx {
    profileLookup(id: $profile) {
      id
      playStats {
        count
        lamp
      }
    }
  }
}
    `;

/**
 * __useIidxPlayClearStatsQuery__
 *
 * To run a query within a React component, call `useIidxPlayClearStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useIidxPlayClearStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIidxPlayClearStatsQuery({
 *   variables: {
 *      profile: // value for 'profile'
 *   },
 * });
 */
export function useIidxPlayClearStatsQuery(baseOptions?: Apollo.QueryHookOptions<IidxPlayClearStatsQuery, IidxPlayClearStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IidxPlayClearStatsQuery, IidxPlayClearStatsQueryVariables>(IidxPlayClearStatsDocument, options);
      }
export function useIidxPlayClearStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IidxPlayClearStatsQuery, IidxPlayClearStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IidxPlayClearStatsQuery, IidxPlayClearStatsQueryVariables>(IidxPlayClearStatsDocument, options);
        }
export type IidxPlayClearStatsQueryHookResult = ReturnType<typeof useIidxPlayClearStatsQuery>;
export type IidxPlayClearStatsLazyQueryHookResult = ReturnType<typeof useIidxPlayClearStatsLazyQuery>;
export type IidxPlayClearStatsQueryResult = Apollo.QueryResult<IidxPlayClearStatsQuery, IidxPlayClearStatsQueryVariables>;
export const IidxPlaysDocument = gql`
    query iidxPlays($profile: ID, $last: Int!, $offset: Int!, $revision: ID, $lamp: IIDXLamp, $plays: [ID!]) {
  iidx {
    profileLookup(id: $profile) {
      id
      plays(
        last: $last
        offset: $offset
        revision: $revision
        lamp: $lamp
        plays: $plays
      ) {
        totalItems
        items {
          id
          timestamp
          exscore
          lamp
          ghost
          gauge
          isHidden
          isIgnored
          playOptions
          song {
            id
            title
            artist
          }
          chart {
            id
            revision {
              id
            }
            level
            diff
            playstyle
            chargeNotes
            hellChargeNotes
            maxExscore
          }
          game {
            name
          }
          shop {
            id
            name
          }
          competition {
            id
          }
          classCourseScore {
            id
            rank
            stagesCleared
            plays {
              id
            }
          }
          arenaScore {
            id
          }
          arenaStage {
            id
            stage
            scores {
              id
              exscore
              lamp
              play {
                id
              }
              participant {
                id
                name
                isCpu
                profile {
                  id
                }
              }
            }
          }
          details {
            id
            judgePgreat
            judgeGreat
            judgeGood
            judgeBad
            judgePoor
            timingFast
            timingSlow
            comboBreak
          }
        }
      }
    }
  }
}
    `;

/**
 * __useIidxPlaysQuery__
 *
 * To run a query within a React component, call `useIidxPlaysQuery` and pass it any options that fit your needs.
 * When your component renders, `useIidxPlaysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIidxPlaysQuery({
 *   variables: {
 *      profile: // value for 'profile'
 *      last: // value for 'last'
 *      offset: // value for 'offset'
 *      revision: // value for 'revision'
 *      lamp: // value for 'lamp'
 *      plays: // value for 'plays'
 *   },
 * });
 */
export function useIidxPlaysQuery(baseOptions: Apollo.QueryHookOptions<IidxPlaysQuery, IidxPlaysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IidxPlaysQuery, IidxPlaysQueryVariables>(IidxPlaysDocument, options);
      }
export function useIidxPlaysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IidxPlaysQuery, IidxPlaysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IidxPlaysQuery, IidxPlaysQueryVariables>(IidxPlaysDocument, options);
        }
export type IidxPlaysQueryHookResult = ReturnType<typeof useIidxPlaysQuery>;
export type IidxPlaysLazyQueryHookResult = ReturnType<typeof useIidxPlaysLazyQuery>;
export type IidxPlaysQueryResult = Apollo.QueryResult<IidxPlaysQuery, IidxPlaysQueryVariables>;
export const IidxPlayHistoryDocument = gql`
    query iidxPlayHistory($profile: ID, $revision: ID!) {
  iidx {
    profileLookup(id: $profile) {
      id
      plays(last: 10, offset: 0, revision: $revision) {
        totalItems
        items {
          id
          timestamp
          exscore
          playOptions
          lamp
          isHidden
          isIgnored
          song {
            id
            title
            artist
          }
          chart {
            id
            level
            diff
            playstyle
            maxExscore
          }
          game {
            name
          }
          classCourseScore {
            id
            rank
            stagesCleared
            plays {
              id
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useIidxPlayHistoryQuery__
 *
 * To run a query within a React component, call `useIidxPlayHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useIidxPlayHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIidxPlayHistoryQuery({
 *   variables: {
 *      profile: // value for 'profile'
 *      revision: // value for 'revision'
 *   },
 * });
 */
export function useIidxPlayHistoryQuery(baseOptions: Apollo.QueryHookOptions<IidxPlayHistoryQuery, IidxPlayHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IidxPlayHistoryQuery, IidxPlayHistoryQueryVariables>(IidxPlayHistoryDocument, options);
      }
export function useIidxPlayHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IidxPlayHistoryQuery, IidxPlayHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IidxPlayHistoryQuery, IidxPlayHistoryQueryVariables>(IidxPlayHistoryDocument, options);
        }
export type IidxPlayHistoryQueryHookResult = ReturnType<typeof useIidxPlayHistoryQuery>;
export type IidxPlayHistoryLazyQueryHookResult = ReturnType<typeof useIidxPlayHistoryLazyQuery>;
export type IidxPlayHistoryQueryResult = Apollo.QueryResult<IidxPlayHistoryQuery, IidxPlayHistoryQueryVariables>;
export const IidxGameDataDocument = gql`
    query iidxGameData($profile: ID, $withQpro: Boolean = false, $withRivals: Boolean = false, $withBattles: Boolean = false, $withUI: Boolean = false, $withMedals: Boolean = false) {
  iidx {
    profileLookup(id: $profile) {
      id
      name
      defaultGame {
        name
        version
      }
      medals @include(if: $withMedals) {
        state {
          obtained
          medal {
            id
            text
            description
            color
          }
        }
      }
      rivals @include(if: $withRivals) {
        id
        playStyle
        scoreBattle @include(if: $withBattles) {
          wins
          losses
        }
        rival {
          id
          playerId
          name
          qpro {
            head {
              id
              name
            }
            face {
              id
              name
            }
            hair {
              id
              name
            }
            hand {
              id
              name
            }
            body {
              id
              name
            }
          }
        }
      }
      uiSettings @include(if: $withUI) {
        availableFlags
        flags
        lane {
          id
        }
        pacemaker {
          id
        }
        frame {
          id
        }
        turntable {
          id
        }
        explosion {
          id
        }
        explosionSize {
          id
        }
        systemBgm {
          id
        }
        categoryVoice {
          id
        }
        noteskin {
          id
        }
        fullcombo {
          id
        }
        beams {
          id
        }
        judge {
          id
        }
        effectorDefault {
          id
        }
        noteStart {
          id
        }
        hcnSkin {
          id
        }
        arenaEmote1 {
          id
        }
        arenaEmote2 {
          id
        }
        arenaEmote3 {
          id
        }
      }
      gameData {
        id
        game {
          name
          version
        }
        medals @include(if: $withMedals) {
          state {
            obtained
            medal {
              id
              text
              description
              color
            }
          }
        }
        uiSettings @include(if: $withUI) {
          availableFlags
          flags
          lane {
            id
          }
          pacemaker {
            id
          }
          frame {
            id
          }
          turntable {
            id
          }
          explosion {
            id
          }
          explosionSize {
            id
          }
          systemBgm {
            id
          }
          categoryVoice {
            id
          }
          noteskin {
            id
          }
          fullcombo {
            id
          }
          beams {
            id
          }
          judge {
            id
          }
          effectorDefault {
            id
          }
          noteStart {
            id
          }
          hcnSkin {
            id
          }
          arenaEmote1 {
            id
          }
          arenaEmote2 {
            id
          }
          arenaEmote3 {
            id
          }
        }
        qpro @include(if: $withQpro) {
          head {
            id
            name
          }
          face {
            id
            name
          }
          hair {
            id
            name
          }
          hand {
            id
            name
          }
          body {
            id
            name
          }
        }
        rivals @include(if: $withRivals) {
          id
          playStyle
          scoreBattle @include(if: $withBattles) {
            wins
            losses
          }
          rival {
            id
            playerId
            name
            qpro {
              head {
                id
                name
              }
              face {
                id
                name
              }
              hair {
                id
                name
              }
              hand {
                id
                name
              }
              body {
                id
                name
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useIidxGameDataQuery__
 *
 * To run a query within a React component, call `useIidxGameDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useIidxGameDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIidxGameDataQuery({
 *   variables: {
 *      profile: // value for 'profile'
 *      withQpro: // value for 'withQpro'
 *      withRivals: // value for 'withRivals'
 *      withBattles: // value for 'withBattles'
 *      withUI: // value for 'withUI'
 *      withMedals: // value for 'withMedals'
 *   },
 * });
 */
export function useIidxGameDataQuery(baseOptions?: Apollo.QueryHookOptions<IidxGameDataQuery, IidxGameDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IidxGameDataQuery, IidxGameDataQueryVariables>(IidxGameDataDocument, options);
      }
export function useIidxGameDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IidxGameDataQuery, IidxGameDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IidxGameDataQuery, IidxGameDataQueryVariables>(IidxGameDataDocument, options);
        }
export type IidxGameDataQueryHookResult = ReturnType<typeof useIidxGameDataQuery>;
export type IidxGameDataLazyQueryHookResult = ReturnType<typeof useIidxGameDataLazyQuery>;
export type IidxGameDataQueryResult = Apollo.QueryResult<IidxGameDataQuery, IidxGameDataQueryVariables>;
export const IidxUiPartsDocument = gql`
    query iidxUiParts($profile: ID, $version: Int) {
  iidx {
    uiParts(unlockedBy: $profile, version: $version) {
      id
      partId
      text
      type
    }
  }
}
    `;

/**
 * __useIidxUiPartsQuery__
 *
 * To run a query within a React component, call `useIidxUiPartsQuery` and pass it any options that fit your needs.
 * When your component renders, `useIidxUiPartsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIidxUiPartsQuery({
 *   variables: {
 *      profile: // value for 'profile'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useIidxUiPartsQuery(baseOptions?: Apollo.QueryHookOptions<IidxUiPartsQuery, IidxUiPartsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IidxUiPartsQuery, IidxUiPartsQueryVariables>(IidxUiPartsDocument, options);
      }
export function useIidxUiPartsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IidxUiPartsQuery, IidxUiPartsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IidxUiPartsQuery, IidxUiPartsQueryVariables>(IidxUiPartsDocument, options);
        }
export type IidxUiPartsQueryHookResult = ReturnType<typeof useIidxUiPartsQuery>;
export type IidxUiPartsLazyQueryHookResult = ReturnType<typeof useIidxUiPartsLazyQuery>;
export type IidxUiPartsQueryResult = Apollo.QueryResult<IidxUiPartsQuery, IidxUiPartsQueryVariables>;
export const IidxQproPartsDocument = gql`
    query iidxQproParts($profile: ID, $version: Int) {
  iidx {
    qproParts(unlockedBy: $profile, version: $version) {
      id
      name
      text
      type
    }
  }
}
    `;

/**
 * __useIidxQproPartsQuery__
 *
 * To run a query within a React component, call `useIidxQproPartsQuery` and pass it any options that fit your needs.
 * When your component renders, `useIidxQproPartsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIidxQproPartsQuery({
 *   variables: {
 *      profile: // value for 'profile'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useIidxQproPartsQuery(baseOptions?: Apollo.QueryHookOptions<IidxQproPartsQuery, IidxQproPartsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IidxQproPartsQuery, IidxQproPartsQueryVariables>(IidxQproPartsDocument, options);
      }
export function useIidxQproPartsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IidxQproPartsQuery, IidxQproPartsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IidxQproPartsQuery, IidxQproPartsQueryVariables>(IidxQproPartsDocument, options);
        }
export type IidxQproPartsQueryHookResult = ReturnType<typeof useIidxQproPartsQuery>;
export type IidxQproPartsLazyQueryHookResult = ReturnType<typeof useIidxQproPartsLazyQuery>;
export type IidxQproPartsQueryResult = Apollo.QueryResult<IidxQproPartsQuery, IidxQproPartsQueryVariables>;
export const IidxSetQproDocument = gql`
    mutation iidxSetQpro($profile: ID, $version: Int, $body: ID!, $hand: ID!, $face: ID!, $hair: ID!, $head: ID!) {
  iidxSetQpro(
    profile: $profile
    version: $version
    body: $body
    hand: $hand
    face: $face
    hair: $hair
    head: $head
  ) {
    ok
  }
}
    `;
export type IidxSetQproMutationFn = Apollo.MutationFunction<IidxSetQproMutation, IidxSetQproMutationVariables>;

/**
 * __useIidxSetQproMutation__
 *
 * To run a mutation, you first call `useIidxSetQproMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIidxSetQproMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [iidxSetQproMutation, { data, loading, error }] = useIidxSetQproMutation({
 *   variables: {
 *      profile: // value for 'profile'
 *      version: // value for 'version'
 *      body: // value for 'body'
 *      hand: // value for 'hand'
 *      face: // value for 'face'
 *      hair: // value for 'hair'
 *      head: // value for 'head'
 *   },
 * });
 */
export function useIidxSetQproMutation(baseOptions?: Apollo.MutationHookOptions<IidxSetQproMutation, IidxSetQproMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IidxSetQproMutation, IidxSetQproMutationVariables>(IidxSetQproDocument, options);
      }
export type IidxSetQproMutationHookResult = ReturnType<typeof useIidxSetQproMutation>;
export type IidxSetQproMutationResult = Apollo.MutationResult<IidxSetQproMutation>;
export type IidxSetQproMutationOptions = Apollo.BaseMutationOptions<IidxSetQproMutation, IidxSetQproMutationVariables>;
export const IidxSetUiSettingsDocument = gql`
    mutation iidxSetUISettings($profile: ID, $version: Int, $flags: [IIDXCustomizationFlag!], $lane: ID, $pacemaker: ID, $beams: ID, $noteskin: ID, $fullcombo: ID, $judge: ID, $frame: ID, $turntable: ID, $systemBgm: ID, $explosion: ID, $noteStart: ID, $effectorDefault: ID, $categoryVoice: ID, $hcnSkin: ID, $explosionSize: ID, $arenaEmote1: ID, $arenaEmote2: ID, $arenaEmote3: ID) {
  iidxSetUiSettings(
    profile: $profile
    version: $version
    flags: $flags
    lane: $lane
    pacemaker: $pacemaker
    beams: $beams
    noteskin: $noteskin
    fullcombo: $fullcombo
    judge: $judge
    frame: $frame
    turntable: $turntable
    systemBgm: $systemBgm
    explosion: $explosion
    effectorDefault: $effectorDefault
    categoryVoice: $categoryVoice
    noteStart: $noteStart
    hcnSkin: $hcnSkin
    explosionSize: $explosionSize
    arenaEmote1: $arenaEmote1
    arenaEmote2: $arenaEmote2
    arenaEmote3: $arenaEmote3
  ) {
    ok
  }
}
    `;
export type IidxSetUiSettingsMutationFn = Apollo.MutationFunction<IidxSetUiSettingsMutation, IidxSetUiSettingsMutationVariables>;

/**
 * __useIidxSetUiSettingsMutation__
 *
 * To run a mutation, you first call `useIidxSetUiSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIidxSetUiSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [iidxSetUiSettingsMutation, { data, loading, error }] = useIidxSetUiSettingsMutation({
 *   variables: {
 *      profile: // value for 'profile'
 *      version: // value for 'version'
 *      flags: // value for 'flags'
 *      lane: // value for 'lane'
 *      pacemaker: // value for 'pacemaker'
 *      beams: // value for 'beams'
 *      noteskin: // value for 'noteskin'
 *      fullcombo: // value for 'fullcombo'
 *      judge: // value for 'judge'
 *      frame: // value for 'frame'
 *      turntable: // value for 'turntable'
 *      systemBgm: // value for 'systemBgm'
 *      explosion: // value for 'explosion'
 *      noteStart: // value for 'noteStart'
 *      effectorDefault: // value for 'effectorDefault'
 *      categoryVoice: // value for 'categoryVoice'
 *      hcnSkin: // value for 'hcnSkin'
 *      explosionSize: // value for 'explosionSize'
 *      arenaEmote1: // value for 'arenaEmote1'
 *      arenaEmote2: // value for 'arenaEmote2'
 *      arenaEmote3: // value for 'arenaEmote3'
 *   },
 * });
 */
export function useIidxSetUiSettingsMutation(baseOptions?: Apollo.MutationHookOptions<IidxSetUiSettingsMutation, IidxSetUiSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IidxSetUiSettingsMutation, IidxSetUiSettingsMutationVariables>(IidxSetUiSettingsDocument, options);
      }
export type IidxSetUiSettingsMutationHookResult = ReturnType<typeof useIidxSetUiSettingsMutation>;
export type IidxSetUiSettingsMutationResult = Apollo.MutationResult<IidxSetUiSettingsMutation>;
export type IidxSetUiSettingsMutationOptions = Apollo.BaseMutationOptions<IidxSetUiSettingsMutation, IidxSetUiSettingsMutationVariables>;
export const IidxRemoveRivalDocument = gql`
    mutation iidxRemoveRival($rivalEntry: ID!) {
  iidxRemoveRival(id: $rivalEntry) {
    ok
  }
}
    `;
export type IidxRemoveRivalMutationFn = Apollo.MutationFunction<IidxRemoveRivalMutation, IidxRemoveRivalMutationVariables>;

/**
 * __useIidxRemoveRivalMutation__
 *
 * To run a mutation, you first call `useIidxRemoveRivalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIidxRemoveRivalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [iidxRemoveRivalMutation, { data, loading, error }] = useIidxRemoveRivalMutation({
 *   variables: {
 *      rivalEntry: // value for 'rivalEntry'
 *   },
 * });
 */
export function useIidxRemoveRivalMutation(baseOptions?: Apollo.MutationHookOptions<IidxRemoveRivalMutation, IidxRemoveRivalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IidxRemoveRivalMutation, IidxRemoveRivalMutationVariables>(IidxRemoveRivalDocument, options);
      }
export type IidxRemoveRivalMutationHookResult = ReturnType<typeof useIidxRemoveRivalMutation>;
export type IidxRemoveRivalMutationResult = Apollo.MutationResult<IidxRemoveRivalMutation>;
export type IidxRemoveRivalMutationOptions = Apollo.BaseMutationOptions<IidxRemoveRivalMutation, IidxRemoveRivalMutationVariables>;
export const IidxAddRivalDocument = gql`
    mutation iidxAddRival($profile: ID, $version: Int, $rival: ID!, $playstyle: IIDXPlayStyle!) {
  iidxAddRival(
    profile: $profile
    version: $version
    rival: $rival
    playStyle: $playstyle
  ) {
    ok
  }
}
    `;
export type IidxAddRivalMutationFn = Apollo.MutationFunction<IidxAddRivalMutation, IidxAddRivalMutationVariables>;

/**
 * __useIidxAddRivalMutation__
 *
 * To run a mutation, you first call `useIidxAddRivalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIidxAddRivalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [iidxAddRivalMutation, { data, loading, error }] = useIidxAddRivalMutation({
 *   variables: {
 *      profile: // value for 'profile'
 *      version: // value for 'version'
 *      rival: // value for 'rival'
 *      playstyle: // value for 'playstyle'
 *   },
 * });
 */
export function useIidxAddRivalMutation(baseOptions?: Apollo.MutationHookOptions<IidxAddRivalMutation, IidxAddRivalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IidxAddRivalMutation, IidxAddRivalMutationVariables>(IidxAddRivalDocument, options);
      }
export type IidxAddRivalMutationHookResult = ReturnType<typeof useIidxAddRivalMutation>;
export type IidxAddRivalMutationResult = Apollo.MutationResult<IidxAddRivalMutation>;
export type IidxAddRivalMutationOptions = Apollo.BaseMutationOptions<IidxAddRivalMutation, IidxAddRivalMutationVariables>;
export const IidxChartLeaderboardDocument = gql`
    query iidxChartLeaderboard($chart: ID!, $weekly: ID, $arena: ID, $competition: ID) {
  iidx {
    chart(id: $chart) {
      id
      notes
      chargeNotes
      bssNotes
      mssNotes
      hellChargeNotes
      maxExscore
      playstyle
      diff
      level
      song {
        id
        title
        rtitle
        artist
        genre
      }
      siblingCharts {
        id
        diff
        playstyle
        level
      }
      revision {
        id
        highscores(weekly: $weekly, arenaStage: $arena, competition: $competition) {
          score
          lamp
          timestamp
          play {
            id
          }
          game {
            name
            version
          }
          profile {
            id
            name
            qpro {
              head {
                id
                name
              }
              face {
                id
                name
              }
              hair {
                id
                name
              }
              hand {
                id
                name
              }
              body {
                id
                name
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useIidxChartLeaderboardQuery__
 *
 * To run a query within a React component, call `useIidxChartLeaderboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useIidxChartLeaderboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIidxChartLeaderboardQuery({
 *   variables: {
 *      chart: // value for 'chart'
 *      weekly: // value for 'weekly'
 *      arena: // value for 'arena'
 *      competition: // value for 'competition'
 *   },
 * });
 */
export function useIidxChartLeaderboardQuery(baseOptions: Apollo.QueryHookOptions<IidxChartLeaderboardQuery, IidxChartLeaderboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IidxChartLeaderboardQuery, IidxChartLeaderboardQueryVariables>(IidxChartLeaderboardDocument, options);
      }
export function useIidxChartLeaderboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IidxChartLeaderboardQuery, IidxChartLeaderboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IidxChartLeaderboardQuery, IidxChartLeaderboardQueryVariables>(IidxChartLeaderboardDocument, options);
        }
export type IidxChartLeaderboardQueryHookResult = ReturnType<typeof useIidxChartLeaderboardQuery>;
export type IidxChartLeaderboardLazyQueryHookResult = ReturnType<typeof useIidxChartLeaderboardLazyQuery>;
export type IidxChartLeaderboardQueryResult = Apollo.QueryResult<IidxChartLeaderboardQuery, IidxChartLeaderboardQueryVariables>;
export const IidxPlayerWeeklyDocument = gql`
    query iidxPlayerWeekly {
  me {
    id
    gameProfile(shortname: "iidx") {
      ... on Node {
        id
      }
      ... on IIDXProfile {
        defaultGame {
          name
        }
        activeWeekly {
          id
          song {
            id
            title
            artist
            genre
          }
          charts {
            id
            playstyle
            diff
            level
          }
          start
          end
          topRankers {
            chart {
              id
            }
            rank
            profile {
              id
              name
            }
            play {
              id
              exscore
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useIidxPlayerWeeklyQuery__
 *
 * To run a query within a React component, call `useIidxPlayerWeeklyQuery` and pass it any options that fit your needs.
 * When your component renders, `useIidxPlayerWeeklyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIidxPlayerWeeklyQuery({
 *   variables: {
 *   },
 * });
 */
export function useIidxPlayerWeeklyQuery(baseOptions?: Apollo.QueryHookOptions<IidxPlayerWeeklyQuery, IidxPlayerWeeklyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IidxPlayerWeeklyQuery, IidxPlayerWeeklyQueryVariables>(IidxPlayerWeeklyDocument, options);
      }
export function useIidxPlayerWeeklyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IidxPlayerWeeklyQuery, IidxPlayerWeeklyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IidxPlayerWeeklyQuery, IidxPlayerWeeklyQueryVariables>(IidxPlayerWeeklyDocument, options);
        }
export type IidxPlayerWeeklyQueryHookResult = ReturnType<typeof useIidxPlayerWeeklyQuery>;
export type IidxPlayerWeeklyLazyQueryHookResult = ReturnType<typeof useIidxPlayerWeeklyLazyQuery>;
export type IidxPlayerWeeklyQueryResult = Apollo.QueryResult<IidxPlayerWeeklyQuery, IidxPlayerWeeklyQueryVariables>;
export const IidxPlayerDailiesDocument = gql`
    query iidxPlayerDailies {
  me {
    id
    gameProfile(shortname: "iidx") {
      ... on Node {
        id
      }
      ... on IIDXProfile {
        defaultGame {
          name
        }
        activeDailies {
          id
          date
          chart1 {
            id
            song {
              id
              title
            }
          }
          chart2 {
            id
            song {
              id
              title
            }
          }
          chart3 {
            id
            song {
              id
              title
            }
          }
        }
        dailiesProgress {
          playedChart1
          playedChart2
          playedChart3
          dailies {
            id
          }
        }
      }
    }
  }
}
    `;

/**
 * __useIidxPlayerDailiesQuery__
 *
 * To run a query within a React component, call `useIidxPlayerDailiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useIidxPlayerDailiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIidxPlayerDailiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useIidxPlayerDailiesQuery(baseOptions?: Apollo.QueryHookOptions<IidxPlayerDailiesQuery, IidxPlayerDailiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IidxPlayerDailiesQuery, IidxPlayerDailiesQueryVariables>(IidxPlayerDailiesDocument, options);
      }
export function useIidxPlayerDailiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IidxPlayerDailiesQuery, IidxPlayerDailiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IidxPlayerDailiesQuery, IidxPlayerDailiesQueryVariables>(IidxPlayerDailiesDocument, options);
        }
export type IidxPlayerDailiesQueryHookResult = ReturnType<typeof useIidxPlayerDailiesQuery>;
export type IidxPlayerDailiesLazyQueryHookResult = ReturnType<typeof useIidxPlayerDailiesLazyQuery>;
export type IidxPlayerDailiesQueryResult = Apollo.QueryResult<IidxPlayerDailiesQuery, IidxPlayerDailiesQueryVariables>;
export const IidxNewsListDocument = gql`
    query iidxNewsList($last: Int!, $offset: Int!) {
  iidx {
    news(last: $last, offset: $offset) {
      items {
        id
        type
        profile {
          id
          name
          qpro {
            head {
              id
              name
            }
            face {
              id
              name
            }
            hair {
              id
              name
            }
            hand {
              id
              name
            }
            body {
              id
              name
            }
          }
        }
        play {
          id
          timestamp
          lamp
          exscore
          playOptions
          chart {
            id
            song {
              id
              title
            }
            maxExscore
            level
            diff
            playstyle
          }
          game {
            name
          }
        }
      }
      totalItems
    }
  }
}
    `;

/**
 * __useIidxNewsListQuery__
 *
 * To run a query within a React component, call `useIidxNewsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useIidxNewsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIidxNewsListQuery({
 *   variables: {
 *      last: // value for 'last'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useIidxNewsListQuery(baseOptions: Apollo.QueryHookOptions<IidxNewsListQuery, IidxNewsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IidxNewsListQuery, IidxNewsListQueryVariables>(IidxNewsListDocument, options);
      }
export function useIidxNewsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IidxNewsListQuery, IidxNewsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IidxNewsListQuery, IidxNewsListQueryVariables>(IidxNewsListDocument, options);
        }
export type IidxNewsListQueryHookResult = ReturnType<typeof useIidxNewsListQuery>;
export type IidxNewsListLazyQueryHookResult = ReturnType<typeof useIidxNewsListLazyQuery>;
export type IidxNewsListQueryResult = Apollo.QueryResult<IidxNewsListQuery, IidxNewsListQueryVariables>;
export const IidxSongListDocument = gql`
    query iidxSongList($last: Int!, $offset: Int!, $search: String, $useDefaultGame: Boolean, $withGame: Boolean = false) {
  iidx {
    songs(
      last: $last
      offset: $offset
      search: $search
      useDefaultGame: $useDefaultGame
    ) {
      items {
        id
        title
        rtitle
        artist
        genre @include(if: $withGame)
        charts(limit: 1) {
          gameFolder
        }
      }
      totalItems
    }
  }
}
    `;

/**
 * __useIidxSongListQuery__
 *
 * To run a query within a React component, call `useIidxSongListQuery` and pass it any options that fit your needs.
 * When your component renders, `useIidxSongListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIidxSongListQuery({
 *   variables: {
 *      last: // value for 'last'
 *      offset: // value for 'offset'
 *      search: // value for 'search'
 *      useDefaultGame: // value for 'useDefaultGame'
 *      withGame: // value for 'withGame'
 *   },
 * });
 */
export function useIidxSongListQuery(baseOptions: Apollo.QueryHookOptions<IidxSongListQuery, IidxSongListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IidxSongListQuery, IidxSongListQueryVariables>(IidxSongListDocument, options);
      }
export function useIidxSongListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IidxSongListQuery, IidxSongListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IidxSongListQuery, IidxSongListQueryVariables>(IidxSongListDocument, options);
        }
export type IidxSongListQueryHookResult = ReturnType<typeof useIidxSongListQuery>;
export type IidxSongListLazyQueryHookResult = ReturnType<typeof useIidxSongListLazyQuery>;
export type IidxSongListQueryResult = Apollo.QueryResult<IidxSongListQuery, IidxSongListQueryVariables>;
export const IidxCompetitionListDocument = gql`
    query iidxCompetitionList($last: Int!, $offset: Int!) {
  iidx {
    competitions(last: $last, offset: $offset) {
      totalItems
      items {
        id
        title
        subtitle
        game {
          name
        }
        creator {
          id
          name
        }
        start
        end
      }
    }
  }
}
    `;

/**
 * __useIidxCompetitionListQuery__
 *
 * To run a query within a React component, call `useIidxCompetitionListQuery` and pass it any options that fit your needs.
 * When your component renders, `useIidxCompetitionListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIidxCompetitionListQuery({
 *   variables: {
 *      last: // value for 'last'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useIidxCompetitionListQuery(baseOptions: Apollo.QueryHookOptions<IidxCompetitionListQuery, IidxCompetitionListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IidxCompetitionListQuery, IidxCompetitionListQueryVariables>(IidxCompetitionListDocument, options);
      }
export function useIidxCompetitionListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IidxCompetitionListQuery, IidxCompetitionListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IidxCompetitionListQuery, IidxCompetitionListQueryVariables>(IidxCompetitionListDocument, options);
        }
export type IidxCompetitionListQueryHookResult = ReturnType<typeof useIidxCompetitionListQuery>;
export type IidxCompetitionListLazyQueryHookResult = ReturnType<typeof useIidxCompetitionListLazyQuery>;
export type IidxCompetitionListQueryResult = Apollo.QueryResult<IidxCompetitionListQuery, IidxCompetitionListQueryVariables>;
export const IidxCompetitionInfoDocument = gql`
    query iidxCompetitionInfo($competition: ID!, $withScores: Boolean = true) {
  iidx {
    competition(id: $competition) {
      id
      title
      subtitle
      description
      game {
        version
        name
      }
      isParticipant
      creator {
        id
        name
      }
      created
      start
      end
      charts @include(if: $withScores) {
        id
        diff
        level
        playstyle
        revision {
          id
        }
        song {
          title
          artist
        }
      }
      highscores @include(if: $withScores) {
        rank
        score
        profile {
          id
          name
          qpro {
            head {
              id
              name
            }
            face {
              id
              name
            }
            hair {
              id
              name
            }
            hand {
              id
              name
            }
            body {
              id
              name
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useIidxCompetitionInfoQuery__
 *
 * To run a query within a React component, call `useIidxCompetitionInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useIidxCompetitionInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIidxCompetitionInfoQuery({
 *   variables: {
 *      competition: // value for 'competition'
 *      withScores: // value for 'withScores'
 *   },
 * });
 */
export function useIidxCompetitionInfoQuery(baseOptions: Apollo.QueryHookOptions<IidxCompetitionInfoQuery, IidxCompetitionInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IidxCompetitionInfoQuery, IidxCompetitionInfoQueryVariables>(IidxCompetitionInfoDocument, options);
      }
export function useIidxCompetitionInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IidxCompetitionInfoQuery, IidxCompetitionInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IidxCompetitionInfoQuery, IidxCompetitionInfoQueryVariables>(IidxCompetitionInfoDocument, options);
        }
export type IidxCompetitionInfoQueryHookResult = ReturnType<typeof useIidxCompetitionInfoQuery>;
export type IidxCompetitionInfoLazyQueryHookResult = ReturnType<typeof useIidxCompetitionInfoLazyQuery>;
export type IidxCompetitionInfoQueryResult = Apollo.QueryResult<IidxCompetitionInfoQuery, IidxCompetitionInfoQueryVariables>;
export const IidxBingoCardListDocument = gql`
    query iidxBingoCardList($last: Int!, $offset: Int!) {
  iidx {
    bingoCards(last: $last, offset: $offset) {
      totalItems
      items {
        id
        title
        size
        creator {
          id
          name
        }
        created
      }
    }
  }
}
    `;

/**
 * __useIidxBingoCardListQuery__
 *
 * To run a query within a React component, call `useIidxBingoCardListQuery` and pass it any options that fit your needs.
 * When your component renders, `useIidxBingoCardListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIidxBingoCardListQuery({
 *   variables: {
 *      last: // value for 'last'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useIidxBingoCardListQuery(baseOptions: Apollo.QueryHookOptions<IidxBingoCardListQuery, IidxBingoCardListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IidxBingoCardListQuery, IidxBingoCardListQueryVariables>(IidxBingoCardListDocument, options);
      }
export function useIidxBingoCardListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IidxBingoCardListQuery, IidxBingoCardListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IidxBingoCardListQuery, IidxBingoCardListQueryVariables>(IidxBingoCardListDocument, options);
        }
export type IidxBingoCardListQueryHookResult = ReturnType<typeof useIidxBingoCardListQuery>;
export type IidxBingoCardListLazyQueryHookResult = ReturnType<typeof useIidxBingoCardListLazyQuery>;
export type IidxBingoCardListQueryResult = Apollo.QueryResult<IidxBingoCardListQuery, IidxBingoCardListQueryVariables>;
export const IidxBingoCardProgressDocument = gql`
    query iidxBingoCardProgress($bingoCard: ID!, $profile: ID) {
  iidx {
    bingoCard(id: $bingoCard) {
      id
      size
      playerProgress(profile: $profile) {
        id
        clearedSlots
      }
    }
  }
}
    `;

/**
 * __useIidxBingoCardProgressQuery__
 *
 * To run a query within a React component, call `useIidxBingoCardProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useIidxBingoCardProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIidxBingoCardProgressQuery({
 *   variables: {
 *      bingoCard: // value for 'bingoCard'
 *      profile: // value for 'profile'
 *   },
 * });
 */
export function useIidxBingoCardProgressQuery(baseOptions: Apollo.QueryHookOptions<IidxBingoCardProgressQuery, IidxBingoCardProgressQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IidxBingoCardProgressQuery, IidxBingoCardProgressQueryVariables>(IidxBingoCardProgressDocument, options);
      }
export function useIidxBingoCardProgressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IidxBingoCardProgressQuery, IidxBingoCardProgressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IidxBingoCardProgressQuery, IidxBingoCardProgressQueryVariables>(IidxBingoCardProgressDocument, options);
        }
export type IidxBingoCardProgressQueryHookResult = ReturnType<typeof useIidxBingoCardProgressQuery>;
export type IidxBingoCardProgressLazyQueryHookResult = ReturnType<typeof useIidxBingoCardProgressLazyQuery>;
export type IidxBingoCardProgressQueryResult = Apollo.QueryResult<IidxBingoCardProgressQuery, IidxBingoCardProgressQueryVariables>;
export const IidxBingoCardInfoDocument = gql`
    query iidxBingoCardInfo($bingoCard: ID!) {
  iidx {
    bingoCard(id: $bingoCard) {
      id
      title
      size
      creator {
        id
        name
      }
      created
      games {
        version
        name
      }
      slots {
        id
        slotId
        clearType
        revision {
          id
          song {
            id
            title
            artist
            charts {
              id
              diff
              level
              playstyle
              revision {
                id
              }
              game {
                version
                name
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useIidxBingoCardInfoQuery__
 *
 * To run a query within a React component, call `useIidxBingoCardInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useIidxBingoCardInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIidxBingoCardInfoQuery({
 *   variables: {
 *      bingoCard: // value for 'bingoCard'
 *   },
 * });
 */
export function useIidxBingoCardInfoQuery(baseOptions: Apollo.QueryHookOptions<IidxBingoCardInfoQuery, IidxBingoCardInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IidxBingoCardInfoQuery, IidxBingoCardInfoQueryVariables>(IidxBingoCardInfoDocument, options);
      }
export function useIidxBingoCardInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IidxBingoCardInfoQuery, IidxBingoCardInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IidxBingoCardInfoQuery, IidxBingoCardInfoQueryVariables>(IidxBingoCardInfoDocument, options);
        }
export type IidxBingoCardInfoQueryHookResult = ReturnType<typeof useIidxBingoCardInfoQuery>;
export type IidxBingoCardInfoLazyQueryHookResult = ReturnType<typeof useIidxBingoCardInfoLazyQuery>;
export type IidxBingoCardInfoQueryResult = Apollo.QueryResult<IidxBingoCardInfoQuery, IidxBingoCardInfoQueryVariables>;
export const IidxSongInfoDocument = gql`
    query iidxSongInfo($song: ID!, $useDefaultGame: Boolean) {
  iidx {
    song(id: $song) {
      id
      title
      rtitle
      artist
      genre
      charts(useDefaultGame: $useDefaultGame) {
        id
        diff
        level
        playstyle
        game {
          name
          version
        }
        revision {
          id
        }
      }
    }
  }
}
    `;

/**
 * __useIidxSongInfoQuery__
 *
 * To run a query within a React component, call `useIidxSongInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useIidxSongInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIidxSongInfoQuery({
 *   variables: {
 *      song: // value for 'song'
 *      useDefaultGame: // value for 'useDefaultGame'
 *   },
 * });
 */
export function useIidxSongInfoQuery(baseOptions: Apollo.QueryHookOptions<IidxSongInfoQuery, IidxSongInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IidxSongInfoQuery, IidxSongInfoQueryVariables>(IidxSongInfoDocument, options);
      }
export function useIidxSongInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IidxSongInfoQuery, IidxSongInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IidxSongInfoQuery, IidxSongInfoQueryVariables>(IidxSongInfoDocument, options);
        }
export type IidxSongInfoQueryHookResult = ReturnType<typeof useIidxSongInfoQuery>;
export type IidxSongInfoLazyQueryHookResult = ReturnType<typeof useIidxSongInfoLazyQuery>;
export type IidxSongInfoQueryResult = Apollo.QueryResult<IidxSongInfoQuery, IidxSongInfoQueryVariables>;
export const IidxCompareScoresDocument = gql`
    query iidxCompareScores($last: Int!, $offset: Int!, $version: Int, $players: [ID!]!, $search: String, $playstyle: IIDXPlayStyle) {
  iidx {
    songs(
      last: $last
      offset: $offset
      version: $version
      useDefaultGame: true
      search: $search
      playedBy: $players
    ) {
      totalItems
      items {
        id
        title
        artist
        charts(version: $version, useDefaultGame: true, playStyle: $playstyle) {
          id
          diff
          playstyle
          level
          maxExscore
          revision {
            id
            highscores(players: $players) {
              profileId
              lamp
              score
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useIidxCompareScoresQuery__
 *
 * To run a query within a React component, call `useIidxCompareScoresQuery` and pass it any options that fit your needs.
 * When your component renders, `useIidxCompareScoresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIidxCompareScoresQuery({
 *   variables: {
 *      last: // value for 'last'
 *      offset: // value for 'offset'
 *      version: // value for 'version'
 *      players: // value for 'players'
 *      search: // value for 'search'
 *      playstyle: // value for 'playstyle'
 *   },
 * });
 */
export function useIidxCompareScoresQuery(baseOptions: Apollo.QueryHookOptions<IidxCompareScoresQuery, IidxCompareScoresQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IidxCompareScoresQuery, IidxCompareScoresQueryVariables>(IidxCompareScoresDocument, options);
      }
export function useIidxCompareScoresLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IidxCompareScoresQuery, IidxCompareScoresQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IidxCompareScoresQuery, IidxCompareScoresQueryVariables>(IidxCompareScoresDocument, options);
        }
export type IidxCompareScoresQueryHookResult = ReturnType<typeof useIidxCompareScoresQuery>;
export type IidxCompareScoresLazyQueryHookResult = ReturnType<typeof useIidxCompareScoresLazyQuery>;
export type IidxCompareScoresQueryResult = Apollo.QueryResult<IidxCompareScoresQuery, IidxCompareScoresQueryVariables>;
export const IidxSetNewsSettingsDocument = gql`
    mutation iidxSetNewsSettings($profile: ID, $types: [IIDXNewsType!], $charts: [IIDXNewsChart!], $topics: [IIDXNewsTopic!]) {
  iidxSetNewsSettings(
    profile: $profile
    types: $types
    charts: $charts
    topics: $topics
  ) {
    ok
    message
  }
}
    `;
export type IidxSetNewsSettingsMutationFn = Apollo.MutationFunction<IidxSetNewsSettingsMutation, IidxSetNewsSettingsMutationVariables>;

/**
 * __useIidxSetNewsSettingsMutation__
 *
 * To run a mutation, you first call `useIidxSetNewsSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIidxSetNewsSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [iidxSetNewsSettingsMutation, { data, loading, error }] = useIidxSetNewsSettingsMutation({
 *   variables: {
 *      profile: // value for 'profile'
 *      types: // value for 'types'
 *      charts: // value for 'charts'
 *      topics: // value for 'topics'
 *   },
 * });
 */
export function useIidxSetNewsSettingsMutation(baseOptions?: Apollo.MutationHookOptions<IidxSetNewsSettingsMutation, IidxSetNewsSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IidxSetNewsSettingsMutation, IidxSetNewsSettingsMutationVariables>(IidxSetNewsSettingsDocument, options);
      }
export type IidxSetNewsSettingsMutationHookResult = ReturnType<typeof useIidxSetNewsSettingsMutation>;
export type IidxSetNewsSettingsMutationResult = Apollo.MutationResult<IidxSetNewsSettingsMutation>;
export type IidxSetNewsSettingsMutationOptions = Apollo.BaseMutationOptions<IidxSetNewsSettingsMutation, IidxSetNewsSettingsMutationVariables>;
export const IidxSetProfileDataDocument = gql`
    mutation iidxSetProfileData($profile: ID, $name: String, $area: ID, $arenaComment1: String, $arenaComment2: String, $arenaComment3: String, $arenaComment4: String, $forceUnlockCurrent: Boolean, $forceUnlockPrevious: Boolean) {
  iidxSetProfileData(
    profile: $profile
    name: $name
    area: $area
    arenaComment1: $arenaComment1
    arenaComment2: $arenaComment2
    arenaComment3: $arenaComment3
    arenaComment4: $arenaComment4
    forceUnlockCurrent: $forceUnlockCurrent
    forceUnlockPrevious: $forceUnlockPrevious
  ) {
    ok
    message
  }
}
    `;
export type IidxSetProfileDataMutationFn = Apollo.MutationFunction<IidxSetProfileDataMutation, IidxSetProfileDataMutationVariables>;

/**
 * __useIidxSetProfileDataMutation__
 *
 * To run a mutation, you first call `useIidxSetProfileDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIidxSetProfileDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [iidxSetProfileDataMutation, { data, loading, error }] = useIidxSetProfileDataMutation({
 *   variables: {
 *      profile: // value for 'profile'
 *      name: // value for 'name'
 *      area: // value for 'area'
 *      arenaComment1: // value for 'arenaComment1'
 *      arenaComment2: // value for 'arenaComment2'
 *      arenaComment3: // value for 'arenaComment3'
 *      arenaComment4: // value for 'arenaComment4'
 *      forceUnlockCurrent: // value for 'forceUnlockCurrent'
 *      forceUnlockPrevious: // value for 'forceUnlockPrevious'
 *   },
 * });
 */
export function useIidxSetProfileDataMutation(baseOptions?: Apollo.MutationHookOptions<IidxSetProfileDataMutation, IidxSetProfileDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IidxSetProfileDataMutation, IidxSetProfileDataMutationVariables>(IidxSetProfileDataDocument, options);
      }
export type IidxSetProfileDataMutationHookResult = ReturnType<typeof useIidxSetProfileDataMutation>;
export type IidxSetProfileDataMutationResult = Apollo.MutationResult<IidxSetProfileDataMutation>;
export type IidxSetProfileDataMutationOptions = Apollo.BaseMutationOptions<IidxSetProfileDataMutation, IidxSetProfileDataMutationVariables>;
export const IidxCreateBingoCardDocument = gql`
    mutation iidxCreateBingoCard($profile: ID, $title: String!, $size: IIDXBingoCardSize!, $slots: [IIDXBingoCardSlotInput!]!) {
  iidxCreateBingoCard(
    profile: $profile
    title: $title
    size: $size
    slots: $slots
  ) {
    ok
    message
    bingoCard {
      id
    }
  }
}
    `;
export type IidxCreateBingoCardMutationFn = Apollo.MutationFunction<IidxCreateBingoCardMutation, IidxCreateBingoCardMutationVariables>;

/**
 * __useIidxCreateBingoCardMutation__
 *
 * To run a mutation, you first call `useIidxCreateBingoCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIidxCreateBingoCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [iidxCreateBingoCardMutation, { data, loading, error }] = useIidxCreateBingoCardMutation({
 *   variables: {
 *      profile: // value for 'profile'
 *      title: // value for 'title'
 *      size: // value for 'size'
 *      slots: // value for 'slots'
 *   },
 * });
 */
export function useIidxCreateBingoCardMutation(baseOptions?: Apollo.MutationHookOptions<IidxCreateBingoCardMutation, IidxCreateBingoCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IidxCreateBingoCardMutation, IidxCreateBingoCardMutationVariables>(IidxCreateBingoCardDocument, options);
      }
export type IidxCreateBingoCardMutationHookResult = ReturnType<typeof useIidxCreateBingoCardMutation>;
export type IidxCreateBingoCardMutationResult = Apollo.MutationResult<IidxCreateBingoCardMutation>;
export type IidxCreateBingoCardMutationOptions = Apollo.BaseMutationOptions<IidxCreateBingoCardMutation, IidxCreateBingoCardMutationVariables>;
export const IidxSetBingoCardDocument = gql`
    mutation iidxSetBingoCard($profile: ID, $card: ID!) {
  iidxSetBingoCard(profile: $profile, card: $card) {
    ok
    message
  }
}
    `;
export type IidxSetBingoCardMutationFn = Apollo.MutationFunction<IidxSetBingoCardMutation, IidxSetBingoCardMutationVariables>;

/**
 * __useIidxSetBingoCardMutation__
 *
 * To run a mutation, you first call `useIidxSetBingoCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIidxSetBingoCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [iidxSetBingoCardMutation, { data, loading, error }] = useIidxSetBingoCardMutation({
 *   variables: {
 *      profile: // value for 'profile'
 *      card: // value for 'card'
 *   },
 * });
 */
export function useIidxSetBingoCardMutation(baseOptions?: Apollo.MutationHookOptions<IidxSetBingoCardMutation, IidxSetBingoCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IidxSetBingoCardMutation, IidxSetBingoCardMutationVariables>(IidxSetBingoCardDocument, options);
      }
export type IidxSetBingoCardMutationHookResult = ReturnType<typeof useIidxSetBingoCardMutation>;
export type IidxSetBingoCardMutationResult = Apollo.MutationResult<IidxSetBingoCardMutation>;
export type IidxSetBingoCardMutationOptions = Apollo.BaseMutationOptions<IidxSetBingoCardMutation, IidxSetBingoCardMutationVariables>;
export const IidxCreateCompetitionDocument = gql`
    mutation iidxCreateCompetition($profile: ID, $title: String!, $subtitle: String, $description: String, $charts: [ID!]!, $start: DateTime!, $end: DateTime!) {
  iidxCreateCompetition(
    profile: $profile
    title: $title
    subtitle: $subtitle
    description: $description
    charts: $charts
    start: $start
    end: $end
  ) {
    ok
    message
    competition {
      id
    }
  }
}
    `;
export type IidxCreateCompetitionMutationFn = Apollo.MutationFunction<IidxCreateCompetitionMutation, IidxCreateCompetitionMutationVariables>;

/**
 * __useIidxCreateCompetitionMutation__
 *
 * To run a mutation, you first call `useIidxCreateCompetitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIidxCreateCompetitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [iidxCreateCompetitionMutation, { data, loading, error }] = useIidxCreateCompetitionMutation({
 *   variables: {
 *      profile: // value for 'profile'
 *      title: // value for 'title'
 *      subtitle: // value for 'subtitle'
 *      description: // value for 'description'
 *      charts: // value for 'charts'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useIidxCreateCompetitionMutation(baseOptions?: Apollo.MutationHookOptions<IidxCreateCompetitionMutation, IidxCreateCompetitionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IidxCreateCompetitionMutation, IidxCreateCompetitionMutationVariables>(IidxCreateCompetitionDocument, options);
      }
export type IidxCreateCompetitionMutationHookResult = ReturnType<typeof useIidxCreateCompetitionMutation>;
export type IidxCreateCompetitionMutationResult = Apollo.MutationResult<IidxCreateCompetitionMutation>;
export type IidxCreateCompetitionMutationOptions = Apollo.BaseMutationOptions<IidxCreateCompetitionMutation, IidxCreateCompetitionMutationVariables>;
export const IidxJoinCompetitionDocument = gql`
    mutation iidxJoinCompetition($profile: ID, $competition: ID!) {
  iidxJoinCompetition(profile: $profile, competition: $competition) {
    ok
    message
  }
}
    `;
export type IidxJoinCompetitionMutationFn = Apollo.MutationFunction<IidxJoinCompetitionMutation, IidxJoinCompetitionMutationVariables>;

/**
 * __useIidxJoinCompetitionMutation__
 *
 * To run a mutation, you first call `useIidxJoinCompetitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIidxJoinCompetitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [iidxJoinCompetitionMutation, { data, loading, error }] = useIidxJoinCompetitionMutation({
 *   variables: {
 *      profile: // value for 'profile'
 *      competition: // value for 'competition'
 *   },
 * });
 */
export function useIidxJoinCompetitionMutation(baseOptions?: Apollo.MutationHookOptions<IidxJoinCompetitionMutation, IidxJoinCompetitionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IidxJoinCompetitionMutation, IidxJoinCompetitionMutationVariables>(IidxJoinCompetitionDocument, options);
      }
export type IidxJoinCompetitionMutationHookResult = ReturnType<typeof useIidxJoinCompetitionMutation>;
export type IidxJoinCompetitionMutationResult = Apollo.MutationResult<IidxJoinCompetitionMutation>;
export type IidxJoinCompetitionMutationOptions = Apollo.BaseMutationOptions<IidxJoinCompetitionMutation, IidxJoinCompetitionMutationVariables>;
export const IidxLeaveCompetitionDocument = gql`
    mutation iidxLeaveCompetition($profile: ID, $competition: ID!) {
  iidxLeaveCompetition(profile: $profile, competition: $competition) {
    ok
    message
  }
}
    `;
export type IidxLeaveCompetitionMutationFn = Apollo.MutationFunction<IidxLeaveCompetitionMutation, IidxLeaveCompetitionMutationVariables>;

/**
 * __useIidxLeaveCompetitionMutation__
 *
 * To run a mutation, you first call `useIidxLeaveCompetitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIidxLeaveCompetitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [iidxLeaveCompetitionMutation, { data, loading, error }] = useIidxLeaveCompetitionMutation({
 *   variables: {
 *      profile: // value for 'profile'
 *      competition: // value for 'competition'
 *   },
 * });
 */
export function useIidxLeaveCompetitionMutation(baseOptions?: Apollo.MutationHookOptions<IidxLeaveCompetitionMutation, IidxLeaveCompetitionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IidxLeaveCompetitionMutation, IidxLeaveCompetitionMutationVariables>(IidxLeaveCompetitionDocument, options);
      }
export type IidxLeaveCompetitionMutationHookResult = ReturnType<typeof useIidxLeaveCompetitionMutation>;
export type IidxLeaveCompetitionMutationResult = Apollo.MutationResult<IidxLeaveCompetitionMutation>;
export type IidxLeaveCompetitionMutationOptions = Apollo.BaseMutationOptions<IidxLeaveCompetitionMutation, IidxLeaveCompetitionMutationVariables>;
export const JbProfileIdDocument = gql`
    query jbProfileId {
  me {
    id
    gameProfile(shortname: "jb") {
      ... on Node {
        id
      }
    }
  }
}
    `;

/**
 * __useJbProfileIdQuery__
 *
 * To run a query within a React component, call `useJbProfileIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useJbProfileIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJbProfileIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useJbProfileIdQuery(baseOptions?: Apollo.QueryHookOptions<JbProfileIdQuery, JbProfileIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JbProfileIdQuery, JbProfileIdQueryVariables>(JbProfileIdDocument, options);
      }
export function useJbProfileIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JbProfileIdQuery, JbProfileIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JbProfileIdQuery, JbProfileIdQueryVariables>(JbProfileIdDocument, options);
        }
export type JbProfileIdQueryHookResult = ReturnType<typeof useJbProfileIdQuery>;
export type JbProfileIdLazyQueryHookResult = ReturnType<typeof useJbProfileIdLazyQuery>;
export type JbProfileIdQueryResult = Apollo.QueryResult<JbProfileIdQuery, JbProfileIdQueryVariables>;
export const JubeatProfileSearchDocument = gql`
    query jubeatProfileSearch($last: Int!, $offset: Int!, $name: String, $lastPlay: Int) {
  jubeat {
    profileSearch(last: $last, offset: $offset, name: $name, lastPlay: $lastPlay) {
      totalItems
      items {
        id
        name
        playerId
        accessed
        registered
      }
    }
  }
}
    `;

/**
 * __useJubeatProfileSearchQuery__
 *
 * To run a query within a React component, call `useJubeatProfileSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useJubeatProfileSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJubeatProfileSearchQuery({
 *   variables: {
 *      last: // value for 'last'
 *      offset: // value for 'offset'
 *      name: // value for 'name'
 *      lastPlay: // value for 'lastPlay'
 *   },
 * });
 */
export function useJubeatProfileSearchQuery(baseOptions: Apollo.QueryHookOptions<JubeatProfileSearchQuery, JubeatProfileSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JubeatProfileSearchQuery, JubeatProfileSearchQueryVariables>(JubeatProfileSearchDocument, options);
      }
export function useJubeatProfileSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JubeatProfileSearchQuery, JubeatProfileSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JubeatProfileSearchQuery, JubeatProfileSearchQueryVariables>(JubeatProfileSearchDocument, options);
        }
export type JubeatProfileSearchQueryHookResult = ReturnType<typeof useJubeatProfileSearchQuery>;
export type JubeatProfileSearchLazyQueryHookResult = ReturnType<typeof useJubeatProfileSearchLazyQuery>;
export type JubeatProfileSearchQueryResult = Apollo.QueryResult<JubeatProfileSearchQuery, JubeatProfileSearchQueryVariables>;
export const JubeatProfileDocument = gql`
    query jubeatProfile($profile: ID) {
  jubeat {
    profileLookup(id: $profile) {
      id
      name
      playerId
      accessed
      registered
    }
  }
}
    `;

/**
 * __useJubeatProfileQuery__
 *
 * To run a query within a React component, call `useJubeatProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useJubeatProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJubeatProfileQuery({
 *   variables: {
 *      profile: // value for 'profile'
 *   },
 * });
 */
export function useJubeatProfileQuery(baseOptions?: Apollo.QueryHookOptions<JubeatProfileQuery, JubeatProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JubeatProfileQuery, JubeatProfileQueryVariables>(JubeatProfileDocument, options);
      }
export function useJubeatProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JubeatProfileQuery, JubeatProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JubeatProfileQuery, JubeatProfileQueryVariables>(JubeatProfileDocument, options);
        }
export type JubeatProfileQueryHookResult = ReturnType<typeof useJubeatProfileQuery>;
export type JubeatProfileLazyQueryHookResult = ReturnType<typeof useJubeatProfileLazyQuery>;
export type JubeatProfileQueryResult = Apollo.QueryResult<JubeatProfileQuery, JubeatProfileQueryVariables>;
export const QmaProfileIdDocument = gql`
    query qmaProfileId {
  me {
    id
    gameProfile(shortname: "qma") {
      ... on Node {
        id
      }
    }
  }
}
    `;

/**
 * __useQmaProfileIdQuery__
 *
 * To run a query within a React component, call `useQmaProfileIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useQmaProfileIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQmaProfileIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useQmaProfileIdQuery(baseOptions?: Apollo.QueryHookOptions<QmaProfileIdQuery, QmaProfileIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QmaProfileIdQuery, QmaProfileIdQueryVariables>(QmaProfileIdDocument, options);
      }
export function useQmaProfileIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QmaProfileIdQuery, QmaProfileIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QmaProfileIdQuery, QmaProfileIdQueryVariables>(QmaProfileIdDocument, options);
        }
export type QmaProfileIdQueryHookResult = ReturnType<typeof useQmaProfileIdQuery>;
export type QmaProfileIdLazyQueryHookResult = ReturnType<typeof useQmaProfileIdLazyQuery>;
export type QmaProfileIdQueryResult = Apollo.QueryResult<QmaProfileIdQuery, QmaProfileIdQueryVariables>;
export const QmaProfileSearchDocument = gql`
    query qmaProfileSearch($last: Int!, $offset: Int!, $name: String, $lastPlay: Int) {
  qma {
    profileSearch(last: $last, offset: $offset, name: $name, lastPlay: $lastPlay) {
      totalItems
      items {
        id
        name
        studentId
        registered
        accessed
        character
        qLevel
      }
    }
  }
}
    `;

/**
 * __useQmaProfileSearchQuery__
 *
 * To run a query within a React component, call `useQmaProfileSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useQmaProfileSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQmaProfileSearchQuery({
 *   variables: {
 *      last: // value for 'last'
 *      offset: // value for 'offset'
 *      name: // value for 'name'
 *      lastPlay: // value for 'lastPlay'
 *   },
 * });
 */
export function useQmaProfileSearchQuery(baseOptions: Apollo.QueryHookOptions<QmaProfileSearchQuery, QmaProfileSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QmaProfileSearchQuery, QmaProfileSearchQueryVariables>(QmaProfileSearchDocument, options);
      }
export function useQmaProfileSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QmaProfileSearchQuery, QmaProfileSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QmaProfileSearchQuery, QmaProfileSearchQueryVariables>(QmaProfileSearchDocument, options);
        }
export type QmaProfileSearchQueryHookResult = ReturnType<typeof useQmaProfileSearchQuery>;
export type QmaProfileSearchLazyQueryHookResult = ReturnType<typeof useQmaProfileSearchLazyQuery>;
export type QmaProfileSearchQueryResult = Apollo.QueryResult<QmaProfileSearchQuery, QmaProfileSearchQueryVariables>;
export const QmaProfileDocument = gql`
    query qmaProfile($profile: ID) {
  qma {
    profileLookup(id: $profile) {
      id
      name
      studentId
      registered
      accessed
      character
      qLevel
      defaultGame {
        name
      }
      answerAccuracy {
        genre
        accuracyRate
      }
    }
  }
}
    `;

/**
 * __useQmaProfileQuery__
 *
 * To run a query within a React component, call `useQmaProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useQmaProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQmaProfileQuery({
 *   variables: {
 *      profile: // value for 'profile'
 *   },
 * });
 */
export function useQmaProfileQuery(baseOptions?: Apollo.QueryHookOptions<QmaProfileQuery, QmaProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QmaProfileQuery, QmaProfileQueryVariables>(QmaProfileDocument, options);
      }
export function useQmaProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QmaProfileQuery, QmaProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QmaProfileQuery, QmaProfileQueryVariables>(QmaProfileDocument, options);
        }
export type QmaProfileQueryHookResult = ReturnType<typeof useQmaProfileQuery>;
export type QmaProfileLazyQueryHookResult = ReturnType<typeof useQmaProfileLazyQuery>;
export type QmaProfileQueryResult = Apollo.QueryResult<QmaProfileQuery, QmaProfileQueryVariables>;
export const QmaExamsDocument = gql`
    query qmaExams {
  qma {
    exams {
      id
      textureId
      title
      subtitle
      details
      availableFrom
      availableUntil
      rankTill
      game {
        name
      }
    }
  }
}
    `;

/**
 * __useQmaExamsQuery__
 *
 * To run a query within a React component, call `useQmaExamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQmaExamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQmaExamsQuery({
 *   variables: {
 *   },
 * });
 */
export function useQmaExamsQuery(baseOptions?: Apollo.QueryHookOptions<QmaExamsQuery, QmaExamsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QmaExamsQuery, QmaExamsQueryVariables>(QmaExamsDocument, options);
      }
export function useQmaExamsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QmaExamsQuery, QmaExamsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QmaExamsQuery, QmaExamsQueryVariables>(QmaExamsDocument, options);
        }
export type QmaExamsQueryHookResult = ReturnType<typeof useQmaExamsQuery>;
export type QmaExamsLazyQueryHookResult = ReturnType<typeof useQmaExamsLazyQuery>;
export type QmaExamsQueryResult = Apollo.QueryResult<QmaExamsQuery, QmaExamsQueryVariables>;
export const QmaExamInfoDocument = gql`
    query qmaExamInfo($id: ID!) {
  qma {
    exam(id: $id) {
      id
      textureId
      title
      subtitle
      details
      availableFrom
      availableUntil
      rankTill
      game {
        name
      }
      scores {
        attempts
        bestScore
        timestamp
        profile {
          id
          name
          character
        }
      }
    }
  }
}
    `;

/**
 * __useQmaExamInfoQuery__
 *
 * To run a query within a React component, call `useQmaExamInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useQmaExamInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQmaExamInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useQmaExamInfoQuery(baseOptions: Apollo.QueryHookOptions<QmaExamInfoQuery, QmaExamInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QmaExamInfoQuery, QmaExamInfoQueryVariables>(QmaExamInfoDocument, options);
      }
export function useQmaExamInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QmaExamInfoQuery, QmaExamInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QmaExamInfoQuery, QmaExamInfoQueryVariables>(QmaExamInfoDocument, options);
        }
export type QmaExamInfoQueryHookResult = ReturnType<typeof useQmaExamInfoQuery>;
export type QmaExamInfoLazyQueryHookResult = ReturnType<typeof useQmaExamInfoLazyQuery>;
export type QmaExamInfoQueryResult = Apollo.QueryResult<QmaExamInfoQuery, QmaExamInfoQueryVariables>;
export const NotifStatusDocument = gql`
    query notifStatus {
  userNotifications {
    highestSeverity
    pendingItems
  }
}
    `;

/**
 * __useNotifStatusQuery__
 *
 * To run a query within a React component, call `useNotifStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotifStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotifStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useNotifStatusQuery(baseOptions?: Apollo.QueryHookOptions<NotifStatusQuery, NotifStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NotifStatusQuery, NotifStatusQueryVariables>(NotifStatusDocument, options);
      }
export function useNotifStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotifStatusQuery, NotifStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NotifStatusQuery, NotifStatusQueryVariables>(NotifStatusDocument, options);
        }
export type NotifStatusQueryHookResult = ReturnType<typeof useNotifStatusQuery>;
export type NotifStatusLazyQueryHookResult = ReturnType<typeof useNotifStatusLazyQuery>;
export type NotifStatusQueryResult = Apollo.QueryResult<NotifStatusQuery, NotifStatusQueryVariables>;
export const NotifListDocument = gql`
    query notifList($offset: Int!, $last: Int!) {
  userNotifications {
    notifications(offset: $offset, last: $last) {
      totalItems
      items {
        id
        pending
        type
        topic
        severity
        customTopic
        sent
        data
      }
    }
  }
}
    `;

/**
 * __useNotifListQuery__
 *
 * To run a query within a React component, call `useNotifListQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotifListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotifListQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useNotifListQuery(baseOptions: Apollo.QueryHookOptions<NotifListQuery, NotifListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NotifListQuery, NotifListQueryVariables>(NotifListDocument, options);
      }
export function useNotifListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotifListQuery, NotifListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NotifListQuery, NotifListQueryVariables>(NotifListDocument, options);
        }
export type NotifListQueryHookResult = ReturnType<typeof useNotifListQuery>;
export type NotifListLazyQueryHookResult = ReturnType<typeof useNotifListLazyQuery>;
export type NotifListQueryResult = Apollo.QueryResult<NotifListQuery, NotifListQueryVariables>;
export const DismissNotificationDocument = gql`
    mutation dismissNotification($id: ID!) {
  dismissNotification(id: $id) {
    ok
  }
}
    `;
export type DismissNotificationMutationFn = Apollo.MutationFunction<DismissNotificationMutation, DismissNotificationMutationVariables>;

/**
 * __useDismissNotificationMutation__
 *
 * To run a mutation, you first call `useDismissNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDismissNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dismissNotificationMutation, { data, loading, error }] = useDismissNotificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDismissNotificationMutation(baseOptions?: Apollo.MutationHookOptions<DismissNotificationMutation, DismissNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DismissNotificationMutation, DismissNotificationMutationVariables>(DismissNotificationDocument, options);
      }
export type DismissNotificationMutationHookResult = ReturnType<typeof useDismissNotificationMutation>;
export type DismissNotificationMutationResult = Apollo.MutationResult<DismissNotificationMutation>;
export type DismissNotificationMutationOptions = Apollo.BaseMutationOptions<DismissNotificationMutation, DismissNotificationMutationVariables>;
export const ShopListDocument = gql`
    query shopList($name: String, $area: ID) {
  shops(name: $name, area: $area) {
    id
    name
    area {
      id
      country
      titleEn
    }
    cabinets {
      id
    }
    union {
      id
      name
    }
  }
}
    `;

/**
 * __useShopListQuery__
 *
 * To run a query within a React component, call `useShopListQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopListQuery({
 *   variables: {
 *      name: // value for 'name'
 *      area: // value for 'area'
 *   },
 * });
 */
export function useShopListQuery(baseOptions?: Apollo.QueryHookOptions<ShopListQuery, ShopListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShopListQuery, ShopListQueryVariables>(ShopListDocument, options);
      }
export function useShopListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShopListQuery, ShopListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShopListQuery, ShopListQueryVariables>(ShopListDocument, options);
        }
export type ShopListQueryHookResult = ReturnType<typeof useShopListQuery>;
export type ShopListLazyQueryHookResult = ReturnType<typeof useShopListLazyQuery>;
export type ShopListQueryResult = Apollo.QueryResult<ShopListQuery, ShopListQueryVariables>;
export const ShopInfoDocument = gql`
    query shopInfo($shop: ID!, $withCabinets: Boolean = false, $withCabinetStats: Boolean = false, $withUsers: Boolean = false, $withPCBID: Boolean = false) {
  shop(id: $shop) {
    id
    name
    area {
      id
      country
      titleEn
    }
    acl
    aclAnalytics
    aclAudit
    aclCabinets
    aclUsers
    aclParameters
    union {
      id
      name
      aclParameters
    }
    users @include(if: $withUsers) {
      ... on Node {
        id
      }
      ... on ShopUserMembership {
        account {
          id
          email
        }
        role
      }
      ... on ShopUnionUserMembership {
        account {
          id
          email
        }
        role
      }
    }
    cabinets @include(if: $withCabinets) {
      id
      name
      description
      enabled
      lastContact
      pcbid @include(if: $withPCBID)
      series {
        name
        shortname
      }
      game {
        name
        version
      }
      playSessionStats @include(if: $withCabinetStats) {
        key
        value
      }
    }
  }
}
    `;

/**
 * __useShopInfoQuery__
 *
 * To run a query within a React component, call `useShopInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopInfoQuery({
 *   variables: {
 *      shop: // value for 'shop'
 *      withCabinets: // value for 'withCabinets'
 *      withCabinetStats: // value for 'withCabinetStats'
 *      withUsers: // value for 'withUsers'
 *      withPCBID: // value for 'withPCBID'
 *   },
 * });
 */
export function useShopInfoQuery(baseOptions: Apollo.QueryHookOptions<ShopInfoQuery, ShopInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShopInfoQuery, ShopInfoQueryVariables>(ShopInfoDocument, options);
      }
export function useShopInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShopInfoQuery, ShopInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShopInfoQuery, ShopInfoQueryVariables>(ShopInfoDocument, options);
        }
export type ShopInfoQueryHookResult = ReturnType<typeof useShopInfoQuery>;
export type ShopInfoLazyQueryHookResult = ReturnType<typeof useShopInfoLazyQuery>;
export type ShopInfoQueryResult = Apollo.QueryResult<ShopInfoQuery, ShopInfoQueryVariables>;
export const ShopUnionListDocument = gql`
    query shopUnionList {
  shopUnions {
    id
    name
    shops {
      id
      name
    }
  }
}
    `;

/**
 * __useShopUnionListQuery__
 *
 * To run a query within a React component, call `useShopUnionListQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopUnionListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopUnionListQuery({
 *   variables: {
 *   },
 * });
 */
export function useShopUnionListQuery(baseOptions?: Apollo.QueryHookOptions<ShopUnionListQuery, ShopUnionListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShopUnionListQuery, ShopUnionListQueryVariables>(ShopUnionListDocument, options);
      }
export function useShopUnionListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShopUnionListQuery, ShopUnionListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShopUnionListQuery, ShopUnionListQueryVariables>(ShopUnionListDocument, options);
        }
export type ShopUnionListQueryHookResult = ReturnType<typeof useShopUnionListQuery>;
export type ShopUnionListLazyQueryHookResult = ReturnType<typeof useShopUnionListLazyQuery>;
export type ShopUnionListQueryResult = Apollo.QueryResult<ShopUnionListQuery, ShopUnionListQueryVariables>;
export const ShopUnionInfoDocument = gql`
    query shopUnionInfo($union: ID!, $withShops: Boolean = false) {
  shopUnion(id: $union) {
    id
    name
    aclParameters
    aclAnalytics
    shops @include(if: $withShops) {
      id
      name
    }
  }
}
    `;

/**
 * __useShopUnionInfoQuery__
 *
 * To run a query within a React component, call `useShopUnionInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopUnionInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopUnionInfoQuery({
 *   variables: {
 *      union: // value for 'union'
 *      withShops: // value for 'withShops'
 *   },
 * });
 */
export function useShopUnionInfoQuery(baseOptions: Apollo.QueryHookOptions<ShopUnionInfoQuery, ShopUnionInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShopUnionInfoQuery, ShopUnionInfoQueryVariables>(ShopUnionInfoDocument, options);
      }
export function useShopUnionInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShopUnionInfoQuery, ShopUnionInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShopUnionInfoQuery, ShopUnionInfoQueryVariables>(ShopUnionInfoDocument, options);
        }
export type ShopUnionInfoQueryHookResult = ReturnType<typeof useShopUnionInfoQuery>;
export type ShopUnionInfoLazyQueryHookResult = ReturnType<typeof useShopUnionInfoLazyQuery>;
export type ShopUnionInfoQueryResult = Apollo.QueryResult<ShopUnionInfoQuery, ShopUnionInfoQueryVariables>;
export const CabinetListDocument = gql`
    query cabinetList($tiedTo: ID) {
  cabinets(tiedTo: $tiedTo) {
    id
    name
    description
    enabled
    lastContact
    shop {
      id
      name
      union {
        id
        name
      }
    }
    series {
      name
      shortname
    }
    game {
      name
      version
    }
    pcbid
  }
}
    `;

/**
 * __useCabinetListQuery__
 *
 * To run a query within a React component, call `useCabinetListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCabinetListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCabinetListQuery({
 *   variables: {
 *      tiedTo: // value for 'tiedTo'
 *   },
 * });
 */
export function useCabinetListQuery(baseOptions?: Apollo.QueryHookOptions<CabinetListQuery, CabinetListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CabinetListQuery, CabinetListQueryVariables>(CabinetListDocument, options);
      }
export function useCabinetListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CabinetListQuery, CabinetListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CabinetListQuery, CabinetListQueryVariables>(CabinetListDocument, options);
        }
export type CabinetListQueryHookResult = ReturnType<typeof useCabinetListQuery>;
export type CabinetListLazyQueryHookResult = ReturnType<typeof useCabinetListLazyQuery>;
export type CabinetListQueryResult = Apollo.QueryResult<CabinetListQuery, CabinetListQueryVariables>;
export const AreaListDocument = gql`
    query areaList {
  areas {
    id
    titleEn
    country
  }
}
    `;

/**
 * __useAreaListQuery__
 *
 * To run a query within a React component, call `useAreaListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAreaListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAreaListQuery({
 *   variables: {
 *   },
 * });
 */
export function useAreaListQuery(baseOptions?: Apollo.QueryHookOptions<AreaListQuery, AreaListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AreaListQuery, AreaListQueryVariables>(AreaListDocument, options);
      }
export function useAreaListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AreaListQuery, AreaListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AreaListQuery, AreaListQueryVariables>(AreaListDocument, options);
        }
export type AreaListQueryHookResult = ReturnType<typeof useAreaListQuery>;
export type AreaListLazyQueryHookResult = ReturnType<typeof useAreaListLazyQuery>;
export type AreaListQueryResult = Apollo.QueryResult<AreaListQuery, AreaListQueryVariables>;
export const ParameterListDocument = gql`
    query parameterList($shop: ID, $view: ID, $withActive: Boolean!) {
  parameters {
    id
    module
    name
    description
    defaultValue {
      id
      origin
      value
    }
    activeValue(shop: $shop) @include(if: $withActive) {
      id
      origin
      value
      forced
    }
    values(node: $view) {
      id
      origin
      value
      forced
    }
    info {
      __typename
      type
      ... on IntParameterInfo {
        intMin: minValue
        intMax: maxValue
      }
      ... on FloatParameterInfo {
        floatMin: minValue
        floatMax: maxValue
      }
      ... on EnumParameterInfo {
        values {
          description
          value
        }
      }
    }
  }
}
    `;

/**
 * __useParameterListQuery__
 *
 * To run a query within a React component, call `useParameterListQuery` and pass it any options that fit your needs.
 * When your component renders, `useParameterListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useParameterListQuery({
 *   variables: {
 *      shop: // value for 'shop'
 *      view: // value for 'view'
 *      withActive: // value for 'withActive'
 *   },
 * });
 */
export function useParameterListQuery(baseOptions: Apollo.QueryHookOptions<ParameterListQuery, ParameterListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ParameterListQuery, ParameterListQueryVariables>(ParameterListDocument, options);
      }
export function useParameterListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ParameterListQuery, ParameterListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ParameterListQuery, ParameterListQueryVariables>(ParameterListDocument, options);
        }
export type ParameterListQueryHookResult = ReturnType<typeof useParameterListQuery>;
export type ParameterListLazyQueryHookResult = ReturnType<typeof useParameterListLazyQuery>;
export type ParameterListQueryResult = Apollo.QueryResult<ParameterListQuery, ParameterListQueryVariables>;
export const UnsetParameterDocument = gql`
    mutation unsetParameter($parameter: ID!, $node: ID) {
  unsetParameter(parameter: $parameter, node: $node) {
    ok
  }
}
    `;
export type UnsetParameterMutationFn = Apollo.MutationFunction<UnsetParameterMutation, UnsetParameterMutationVariables>;

/**
 * __useUnsetParameterMutation__
 *
 * To run a mutation, you first call `useUnsetParameterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnsetParameterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unsetParameterMutation, { data, loading, error }] = useUnsetParameterMutation({
 *   variables: {
 *      parameter: // value for 'parameter'
 *      node: // value for 'node'
 *   },
 * });
 */
export function useUnsetParameterMutation(baseOptions?: Apollo.MutationHookOptions<UnsetParameterMutation, UnsetParameterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnsetParameterMutation, UnsetParameterMutationVariables>(UnsetParameterDocument, options);
      }
export type UnsetParameterMutationHookResult = ReturnType<typeof useUnsetParameterMutation>;
export type UnsetParameterMutationResult = Apollo.MutationResult<UnsetParameterMutation>;
export type UnsetParameterMutationOptions = Apollo.BaseMutationOptions<UnsetParameterMutation, UnsetParameterMutationVariables>;
export const SetParameterDocument = gql`
    mutation setParameter($parameter: ID!, $node: ID, $forced: Boolean, $value: String) {
  setParameter(parameter: $parameter, node: $node, forced: $forced, value: $value) {
    ok
    value {
      id
      origin
      value
      forced
    }
  }
}
    `;
export type SetParameterMutationFn = Apollo.MutationFunction<SetParameterMutation, SetParameterMutationVariables>;

/**
 * __useSetParameterMutation__
 *
 * To run a mutation, you first call `useSetParameterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetParameterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setParameterMutation, { data, loading, error }] = useSetParameterMutation({
 *   variables: {
 *      parameter: // value for 'parameter'
 *      node: // value for 'node'
 *      forced: // value for 'forced'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useSetParameterMutation(baseOptions?: Apollo.MutationHookOptions<SetParameterMutation, SetParameterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetParameterMutation, SetParameterMutationVariables>(SetParameterDocument, options);
      }
export type SetParameterMutationHookResult = ReturnType<typeof useSetParameterMutation>;
export type SetParameterMutationResult = Apollo.MutationResult<SetParameterMutation>;
export type SetParameterMutationOptions = Apollo.BaseMutationOptions<SetParameterMutation, SetParameterMutationVariables>;
export const CreateShopDocument = gql`
    mutation createShop($name: String!, $area: ID!) {
  createShop(name: $name, area: $area) {
    ok
    message
    shop {
      id
      aclUsers
      aclCabinets
    }
  }
}
    `;
export type CreateShopMutationFn = Apollo.MutationFunction<CreateShopMutation, CreateShopMutationVariables>;

/**
 * __useCreateShopMutation__
 *
 * To run a mutation, you first call `useCreateShopMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateShopMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createShopMutation, { data, loading, error }] = useCreateShopMutation({
 *   variables: {
 *      name: // value for 'name'
 *      area: // value for 'area'
 *   },
 * });
 */
export function useCreateShopMutation(baseOptions?: Apollo.MutationHookOptions<CreateShopMutation, CreateShopMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateShopMutation, CreateShopMutationVariables>(CreateShopDocument, options);
      }
export type CreateShopMutationHookResult = ReturnType<typeof useCreateShopMutation>;
export type CreateShopMutationResult = Apollo.MutationResult<CreateShopMutation>;
export type CreateShopMutationOptions = Apollo.BaseMutationOptions<CreateShopMutation, CreateShopMutationVariables>;
export const EditShopDocument = gql`
    mutation editShop($shop: ID!, $name: String, $area: ID, $operatorPin: String) {
  editShop(shop: $shop, name: $name, area: $area, operatorPin: $operatorPin) {
    ok
    message
  }
}
    `;
export type EditShopMutationFn = Apollo.MutationFunction<EditShopMutation, EditShopMutationVariables>;

/**
 * __useEditShopMutation__
 *
 * To run a mutation, you first call `useEditShopMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditShopMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editShopMutation, { data, loading, error }] = useEditShopMutation({
 *   variables: {
 *      shop: // value for 'shop'
 *      name: // value for 'name'
 *      area: // value for 'area'
 *      operatorPin: // value for 'operatorPin'
 *   },
 * });
 */
export function useEditShopMutation(baseOptions?: Apollo.MutationHookOptions<EditShopMutation, EditShopMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditShopMutation, EditShopMutationVariables>(EditShopDocument, options);
      }
export type EditShopMutationHookResult = ReturnType<typeof useEditShopMutation>;
export type EditShopMutationResult = Apollo.MutationResult<EditShopMutation>;
export type EditShopMutationOptions = Apollo.BaseMutationOptions<EditShopMutation, EditShopMutationVariables>;
export const RemoveMembershipDocument = gql`
    mutation removeMembership($id: ID!) {
  removeMembership(id: $id) {
    ok
    message
  }
}
    `;
export type RemoveMembershipMutationFn = Apollo.MutationFunction<RemoveMembershipMutation, RemoveMembershipMutationVariables>;

/**
 * __useRemoveMembershipMutation__
 *
 * To run a mutation, you first call `useRemoveMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMembershipMutation, { data, loading, error }] = useRemoveMembershipMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveMembershipMutation(baseOptions?: Apollo.MutationHookOptions<RemoveMembershipMutation, RemoveMembershipMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveMembershipMutation, RemoveMembershipMutationVariables>(RemoveMembershipDocument, options);
      }
export type RemoveMembershipMutationHookResult = ReturnType<typeof useRemoveMembershipMutation>;
export type RemoveMembershipMutationResult = Apollo.MutationResult<RemoveMembershipMutation>;
export type RemoveMembershipMutationOptions = Apollo.BaseMutationOptions<RemoveMembershipMutation, RemoveMembershipMutationVariables>;
export const AddMembershipDocument = gql`
    mutation addMembership($account: ID!, $entity: ID!, $role: ShopUserRole!) {
  addMembership(account: $account, entity: $entity, role: $role) {
    ok
    message
    membership {
      ... on Node {
        id
      }
    }
  }
}
    `;
export type AddMembershipMutationFn = Apollo.MutationFunction<AddMembershipMutation, AddMembershipMutationVariables>;

/**
 * __useAddMembershipMutation__
 *
 * To run a mutation, you first call `useAddMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMembershipMutation, { data, loading, error }] = useAddMembershipMutation({
 *   variables: {
 *      account: // value for 'account'
 *      entity: // value for 'entity'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useAddMembershipMutation(baseOptions?: Apollo.MutationHookOptions<AddMembershipMutation, AddMembershipMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddMembershipMutation, AddMembershipMutationVariables>(AddMembershipDocument, options);
      }
export type AddMembershipMutationHookResult = ReturnType<typeof useAddMembershipMutation>;
export type AddMembershipMutationResult = Apollo.MutationResult<AddMembershipMutation>;
export type AddMembershipMutationOptions = Apollo.BaseMutationOptions<AddMembershipMutation, AddMembershipMutationVariables>;
export const CreateCabinetDocument = gql`
    mutation createCabinet($shop: ID!, $name: String!, $description: String!, $pcbid: String, $natPort: Int, $hidden: Boolean!, $enabled: Boolean!) {
  createCabinet(
    shop: $shop
    name: $name
    description: $description
    pcbid: $pcbid
    natPort: $natPort
    hidden: $hidden
    enabled: $enabled
  ) {
    ok
    message
    cabinet {
      id
    }
  }
}
    `;
export type CreateCabinetMutationFn = Apollo.MutationFunction<CreateCabinetMutation, CreateCabinetMutationVariables>;

/**
 * __useCreateCabinetMutation__
 *
 * To run a mutation, you first call `useCreateCabinetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCabinetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCabinetMutation, { data, loading, error }] = useCreateCabinetMutation({
 *   variables: {
 *      shop: // value for 'shop'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      pcbid: // value for 'pcbid'
 *      natPort: // value for 'natPort'
 *      hidden: // value for 'hidden'
 *      enabled: // value for 'enabled'
 *   },
 * });
 */
export function useCreateCabinetMutation(baseOptions?: Apollo.MutationHookOptions<CreateCabinetMutation, CreateCabinetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCabinetMutation, CreateCabinetMutationVariables>(CreateCabinetDocument, options);
      }
export type CreateCabinetMutationHookResult = ReturnType<typeof useCreateCabinetMutation>;
export type CreateCabinetMutationResult = Apollo.MutationResult<CreateCabinetMutation>;
export type CreateCabinetMutationOptions = Apollo.BaseMutationOptions<CreateCabinetMutation, CreateCabinetMutationVariables>;
export const OperatorTransactionsDocument = gql`
    query operatorTransactions($id: ID!, $last: Int!, $offset: Int!) {
  node(id: $id) {
    ... on Node {
      id
    }
    ... on Shop {
      transactions(last: $last, offset: $offset) {
        ...transactionInfo
      }
    }
    ... on ShopUnion {
      transactions(last: $last, offset: $offset) {
        ...transactionInfo
      }
    }
  }
}
    ${TransactionInfoFragmentDoc}`;

/**
 * __useOperatorTransactionsQuery__
 *
 * To run a query within a React component, call `useOperatorTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOperatorTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOperatorTransactionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      last: // value for 'last'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useOperatorTransactionsQuery(baseOptions: Apollo.QueryHookOptions<OperatorTransactionsQuery, OperatorTransactionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OperatorTransactionsQuery, OperatorTransactionsQueryVariables>(OperatorTransactionsDocument, options);
      }
export function useOperatorTransactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OperatorTransactionsQuery, OperatorTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OperatorTransactionsQuery, OperatorTransactionsQueryVariables>(OperatorTransactionsDocument, options);
        }
export type OperatorTransactionsQueryHookResult = ReturnType<typeof useOperatorTransactionsQuery>;
export type OperatorTransactionsLazyQueryHookResult = ReturnType<typeof useOperatorTransactionsLazyQuery>;
export type OperatorTransactionsQueryResult = Apollo.QueryResult<OperatorTransactionsQuery, OperatorTransactionsQueryVariables>;
export const UserListDocument = gql`
    query userList($card: String) {
  users(cardTag: $card) {
    id
    email
    networkOperator
    cards {
      id
    }
    gameProfiles {
      ... on Node {
        id
      }
      ... on BeatstreamProfile {
        name
        playerId
      }
      ... on BishiBashiChannelProfile {
        name
        id
      }
      ... on IIDXProfile {
        name
        playerId
      }
      ... on JubeatProfile {
        name
        playerId
      }
      ... on SDVXProfile {
        name
        playerId
      }
      ... on QMAProfile {
        name
        studentId
      }
    }
    wallets {
      id
    }
    shops {
      id
    }
    shopUnions {
      id
    }
  }
}
    `;

/**
 * __useUserListQuery__
 *
 * To run a query within a React component, call `useUserListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserListQuery({
 *   variables: {
 *      card: // value for 'card'
 *   },
 * });
 */
export function useUserListQuery(baseOptions?: Apollo.QueryHookOptions<UserListQuery, UserListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserListQuery, UserListQueryVariables>(UserListDocument, options);
      }
export function useUserListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserListQuery, UserListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserListQuery, UserListQueryVariables>(UserListDocument, options);
        }
export type UserListQueryHookResult = ReturnType<typeof useUserListQuery>;
export type UserListLazyQueryHookResult = ReturnType<typeof useUserListLazyQuery>;
export type UserListQueryResult = Apollo.QueryResult<UserListQuery, UserListQueryVariables>;
export const UserAccountDocument = gql`
    query userAccount($id: ID) {
  user(id: $id) {
    id
    email
    networkOperator
    aclCards
    aclWallets
    cards {
      id
    }
    gameProfiles {
      __typename
      ... on Node {
        id
      }
      ... on BeatstreamProfile {
        name
        playerId
      }
      ... on BishiBashiChannelProfile {
        name
        id
      }
      ... on IIDXProfile {
        name
        playerId
      }
      ... on JubeatProfile {
        name
        playerId
      }
      ... on SDVXProfile {
        name
        playerId
      }
      ... on QMAProfile {
        name
        studentId
      }
    }
    wallets {
      id
    }
  }
}
    `;

/**
 * __useUserAccountQuery__
 *
 * To run a query within a React component, call `useUserAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserAccountQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserAccountQuery(baseOptions?: Apollo.QueryHookOptions<UserAccountQuery, UserAccountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserAccountQuery, UserAccountQueryVariables>(UserAccountDocument, options);
      }
export function useUserAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserAccountQuery, UserAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserAccountQuery, UserAccountQueryVariables>(UserAccountDocument, options);
        }
export type UserAccountQueryHookResult = ReturnType<typeof useUserAccountQuery>;
export type UserAccountLazyQueryHookResult = ReturnType<typeof useUserAccountLazyQuery>;
export type UserAccountQueryResult = Apollo.QueryResult<UserAccountQuery, UserAccountQueryVariables>;
export const WalletListDocument = gql`
    query walletList($id: ID, $withTransactions: Boolean = false) {
  user(id: $id) {
    id
    email
    wallets {
      id
      balance
      union {
        id
        name
      }
      transactions(last: 5, offset: 0) @include(if: $withTransactions) {
        items {
          id
          timestamp
          detail
          service
          charge
          balanceAfter
        }
      }
    }
  }
}
    `;

/**
 * __useWalletListQuery__
 *
 * To run a query within a React component, call `useWalletListQuery` and pass it any options that fit your needs.
 * When your component renders, `useWalletListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWalletListQuery({
 *   variables: {
 *      id: // value for 'id'
 *      withTransactions: // value for 'withTransactions'
 *   },
 * });
 */
export function useWalletListQuery(baseOptions?: Apollo.QueryHookOptions<WalletListQuery, WalletListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WalletListQuery, WalletListQueryVariables>(WalletListDocument, options);
      }
export function useWalletListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WalletListQuery, WalletListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WalletListQuery, WalletListQueryVariables>(WalletListDocument, options);
        }
export type WalletListQueryHookResult = ReturnType<typeof useWalletListQuery>;
export type WalletListLazyQueryHookResult = ReturnType<typeof useWalletListLazyQuery>;
export type WalletListQueryResult = Apollo.QueryResult<WalletListQuery, WalletListQueryVariables>;
export const WalletInfoDocument = gql`
    query walletInfo($id: ID!) {
  node(id: $id) {
    ... on Node {
      id
    }
    ... on Wallet {
      id
      balance
      account {
        id
        email
      }
      union {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useWalletInfoQuery__
 *
 * To run a query within a React component, call `useWalletInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useWalletInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWalletInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useWalletInfoQuery(baseOptions: Apollo.QueryHookOptions<WalletInfoQuery, WalletInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WalletInfoQuery, WalletInfoQueryVariables>(WalletInfoDocument, options);
      }
export function useWalletInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WalletInfoQuery, WalletInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WalletInfoQuery, WalletInfoQueryVariables>(WalletInfoDocument, options);
        }
export type WalletInfoQueryHookResult = ReturnType<typeof useWalletInfoQuery>;
export type WalletInfoLazyQueryHookResult = ReturnType<typeof useWalletInfoLazyQuery>;
export type WalletInfoQueryResult = Apollo.QueryResult<WalletInfoQuery, WalletInfoQueryVariables>;
export const WalletTransactionsDocument = gql`
    query walletTransactions($id: ID!, $last: Int!, $offset: Int!) {
  node(id: $id) {
    ... on Node {
      id
    }
    ... on Wallet {
      transactions(last: $last, offset: $offset) {
        totalItems
        items {
          id
          timestamp
          detail
          service
          charge
          balanceAfter
          isFreeplay
          series {
            name
          }
          game {
            name
          }
          shop {
            id
            name
          }
          cabinet {
            id
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useWalletTransactionsQuery__
 *
 * To run a query within a React component, call `useWalletTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWalletTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWalletTransactionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      last: // value for 'last'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useWalletTransactionsQuery(baseOptions: Apollo.QueryHookOptions<WalletTransactionsQuery, WalletTransactionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WalletTransactionsQuery, WalletTransactionsQueryVariables>(WalletTransactionsDocument, options);
      }
export function useWalletTransactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WalletTransactionsQuery, WalletTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WalletTransactionsQuery, WalletTransactionsQueryVariables>(WalletTransactionsDocument, options);
        }
export type WalletTransactionsQueryHookResult = ReturnType<typeof useWalletTransactionsQuery>;
export type WalletTransactionsLazyQueryHookResult = ReturnType<typeof useWalletTransactionsLazyQuery>;
export type WalletTransactionsQueryResult = Apollo.QueryResult<WalletTransactionsQuery, WalletTransactionsQueryVariables>;