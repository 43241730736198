import * as React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  Divider,
  Dropdown,
  Menu,
} from 'semantic-ui-react'

type Props = {
  title?: string,
  icon?: any,
  sidebar?: boolean,
  items: Array<any>,
  direction?: "left" | "right",
  dim?: boolean,
}

const UniversalMenu: React.FunctionComponent<Props> = ({ direction, title, sidebar, dim, icon: menu_icon, items: raw_items }) => {

  const items = raw_items.filter((item: any) => !!item).filter((item: any, index: number, array: any) => {
    if (!!item?.divider) {
      // The first item in the list can't possibly be a divider
      if (index == 0)
        return false;

      // We don't want successive dividers
      if (!!array[index - 1].divider)
        return false;

      // If we're the last item in the list, there's no point to have a divider
      if (index + 1 == array.length)
        return false;

      return true;
    }
    return true;
  }).filter((item: any) => !!item);

  if (items.length == 0)
    return <></>;

  if (!!sidebar) {
    // Menu collapsing logic for mobile sidebar

    const [isOpen, setIsOpen] = React.useState(false);

    const show = () => setIsOpen(true)
    const toggle = () => setIsOpen(!isOpen)

    return <Menu.Item onClick={isOpen ? undefined : show}>
      {/* FIXME: The <i>-wrap is as correct as you can get it when comparing to using <Icon>, but both Icon and FontAwesomeIcon
                 will adjust the icon a bit too much down compared to the text. */}
      <Menu.Header style={(!isOpen && !!dim) ? { opacity: 0.7 }: {}} onClick={toggle}>{menu_icon && <i className="icon"><FontAwesomeIcon icon={["fas", menu_icon]} /></i> }{title}</Menu.Header>
      {isOpen && <Menu.Menu>
        {items.map((item: any, index: number) => {
          return <React.Fragment key={index}>
            {!item.divider && <Menu.Item onClick={item.onClick}>
              {(item.iconEl || item.icon) && <i className="icon">{item.iconEl || <FontAwesomeIcon icon={["fas", item.icon]} />}</i>}
              {item.text}
            </Menu.Item>}
          {!!item.divider && <Divider />}
          </React.Fragment>;
        })}
      </Menu.Menu>}
    </Menu.Item>;
  }

  return <Dropdown item simple icon={menu_icon && <FontAwesomeIcon style={!!dim ? { opacity: 0.7 }: {}} icon={["fas", menu_icon] || undefined} />} direction={direction} text={menu_icon ? undefined : title}>
    <Dropdown.Menu style={{"minWidth": "200px"}}>
      <Dropdown.Item><b>{title}</b></Dropdown.Item>
      {items.map((item: any, index: number) => {
        return <React.Fragment key={index}>
          {!item.divider && <Dropdown.Item onClick={item.onClick}>
            {(item.iconEl || item.icon) && <i className="icon">{item.iconEl || <FontAwesomeIcon icon={["fad", item.icon]} />}</i>}
            {item.text}
          </Dropdown.Item>}
          {!!item.divider && <Divider />}
        </React.Fragment>;
      })}
    </Dropdown.Menu>
  </Dropdown>
};

export default UniversalMenu
