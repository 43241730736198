import * as React from 'react'

import Router from 'next/router';

import UniversalMenu from '@/components/UniversalMenu'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import * as GQL from '@/gql-types'

const navigateTo = (url: string, as: string | null) => {
    let actualAs: string | undefined = undefined;

    if (as != null)
      actualAs = as;

    return (e: any) => {
        e.preventDefault();
        Router.push(url,actualAs);
        return false;
    }
};

const IIDXMenu = (data: any) => {
  const series = data.series;
  const sidebar = data.sidebar;
  const user = data.user;

  const profileData = GQL.useUserGameProfileQuery({ variables: {game: series.shortname} });

  let playerItems: any = [];
  let hasProfile = false;
  if (profileData?.data?.me?.gameProfile) {
    hasProfile = true;
    playerItems = [
      { "icon": "user", "text": "Profile", "onClick": navigateTo('/game/iidx/[pid]', '/game/iidx/me') },
      { "icon": "play", "text": "Plays", "onClick": navigateTo('/game/iidx/[pid]/plays', '/game/iidx/me/plays') },
      { "icon": "gem", "text": "Scores", "onClick": navigateTo('/game/iidx/[pid]/scores', '/game/iidx/me/scores') },
      { "icon": "chevron-circle-up", "text": "Class Courses", "onClick": navigateTo('/game/iidx/[pid]/class-courses', '/game/iidx/me/class-courses') },
      { "icon": "trophy", "text": "Medals", "onClick": navigateTo('/game/iidx/[pid]/medals', '/game/iidx/me/medals') },
      { "divider": true },
      { "icon": "user-circle", "text": "Qpro", "onClick": navigateTo('/game/iidx/[pid]/qpro', '/game/iidx/me/qpro') },
      { "icon": "wrench", "text": "Customize", "onClick": navigateTo('/game/iidx/[pid]/customize', '/game/iidx/me/customize') },
      { "icon": "balance-scale", "text": "Rivals", "onClick": navigateTo('/game/iidx/[pid]/rivals', '/game/iidx/me/rivals') },
      { "divider": true },
    ];
  }

  let defaultItems: any = [
    (user?.networkOperator ? { "icon": "swords", "text": <>Arena Battles <small>BETA</small></>, "onClick": navigateTo('/game/iidx/arena', null) } : undefined ),
    { "iconEl": <FontAwesomeIcon icon={["fas", "th"]} />, "text": <>Bingo <small>BETA</small></>, "onClick": navigateTo('/game/iidx/bingo', null) },
    { "icon": "calendar", "text": "Competitions", "onClick": navigateTo('/game/iidx/compo', null) },
    { "icon": "newspaper", "text": "News", "onClick": navigateTo('/game/iidx/news', null) },
    { "icon": "users", "text": "Players", "onClick": navigateTo('/game/iidx/players', null) },
    { "icon": "music", "text": "Songs", "onClick": navigateTo('/game/iidx/songs', null) },
    (user?.networkOperator ? { "icon": "list-ol", "text": <>Song Ranking <small>BETA</small></>, "onClick": navigateTo('/game/iidx/songs/ranking', null) } : undefined),
  ];

  return <UniversalMenu title={series.name} icon="compact-disc" sidebar={sidebar} dim={!hasProfile} items={playerItems.concat(defaultItems)}/>
};

export default IIDXMenu
