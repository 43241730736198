import * as React from 'react'

import {
  Label,
  Menu,
  Icon,
} from 'semantic-ui-react'

import Router from 'next/router';

import * as GQL from '@/gql-types'

const navigateTo = (url: string) => {
    return (e: any) => {
        e.preventDefault();
        Router.push(url)
        return false;
    }
};

const severityColors = {
  "Low": "blue",
  "Medium": "orange",
  "High": "red",
}

const NotificationMenu = ({}) => {
  const [isClientSide, setIsClientSide] = React.useState(false);
  React.useEffect(() => { !isClientSide && setIsClientSide(true) });

  const { data: notifData } = GQL.useNotifStatusQuery({
    pollInterval: isClientSide ? 10 * 1000 : undefined,
  });

  const badgeCount = notifData?.userNotifications?.pendingItems || 0;
  const severity = notifData?.userNotifications?.highestSeverity;
  const badgeColor = severity ? severityColors[severity] : "grey";


  return <Menu.Item as="a" onClick={navigateTo("/acp/notifs")}>
    {badgeCount > 0 && <><Label style={{"marginLeft": "0px", "marginRight": "5px", "marginTop": "-3px", "marginBottom": "-2px"}} color={badgeColor as any}>{badgeCount}</Label>&nbsp;</>}
    <Icon name="bell" style={{"marginRight": "0px", "opacity": badgeCount > 0 ? 1.0 : 0.3}} />
  </Menu.Item>
};

export default NotificationMenu
