import * as React from 'react'

import Router from 'next/router';

import UniversalMenu from '@/components/UniversalMenu'

import * as GQL from '@/gql-types'


const navigateTo = (url: string, as: string | null) => {
    let actualAs: string | undefined = undefined;

    if (as != null)
      actualAs = as;

    return (e: any) => {
        e.preventDefault();
        Router.push(url,actualAs);
        return false;
    }
};

const BeatstreamMenu = (data: any) => {
  const series = data.series;
  const sidebar = data.sidebar;

  const profileData = GQL.useUserGameProfileQuery({ variables: {game: series.shortname} });

  let playerItems: any = [];
  let hasProfile = false;
  if (profileData?.data?.me?.gameProfile) {
    hasProfile = true;
    playerItems = [
      { "icon": "user", "text": "Profile", "onClick": navigateTo('/game/bst/[pid]', '/game/bst/me') },
      { "icon": "gem", "text": "Scores", "onClick": navigateTo('/game/bst/[pid]/scores', '/game/bst/me/scores') },
      { "divider": true },
    ];
  }

  let defaultItems: any = [
    { "icon": "users", "text": "Players", "onClick": navigateTo('/game/bst/players', null) },
    { "icon": "music", "text": "Songs", "onClick": navigateTo('/game/bst/songs', null) },
  ];

  return <UniversalMenu title={series.name} icon="paw" sidebar={sidebar} dim={!hasProfile} items={playerItems.concat(defaultItems)} />
};

export default BeatstreamMenu
