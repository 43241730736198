import * as React from 'react'

import Router from 'next/router';

import UniversalMenu from '@/components/UniversalMenu'
import PaseliIcon from '@/components/PaseliIcon'

import * as GQL from '@/gql-types'

const navigateTo = (url: string) => {
    return (e: any) => {
        e.preventDefault();
        Router.push(url)
        return false;
    }
};

const AccountMenu = (data: { sidebar?: boolean }) => {
  const sidebar = data.sidebar;

  const { data: urlData } = GQL.useServiceUrlsQuery();
  const urls = urlData?.apiDetails

  return <UniversalMenu title={"Account"} icon="user" direction="left" sidebar={sidebar} items={[
    { "icon": "user", "text": "My Account", "onClick": navigateTo('/me') },
    { "icon": "id-card", "text": "Cards", "onClick": navigateTo('/me/cards') },
    { "iconEl": <PaseliIcon className="svg-inline--fa fa-w-16" />, "text": "Wallets", "onClick": navigateTo('/me/wallets') },
    { "divider": true },
    !!urls && { "icon": "user-shield", "text": "Account settings", "onClick": () => window.open(urls.accountSettingsUrl, "_blank") },
    !!urls && { "icon": "file-import", "text": "Score import", "onClick": () => window.open(urls.scoreImportUrl, "_blank") },
    !!urls && { "icon": "tools", "text": "API", "onClick": () => window.open(urls.apiDocsUrl, "_blank") },
    { "divider": true },
    { "icon": "sign-out", "text": "Log out", "onClick": navigateTo('/auth/logout') },
  ]} />
};

export default AccountMenu
