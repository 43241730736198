import * as React from 'react'

import * as GQL from '@/gql-types';

import UniversalMenu from '@/components/UniversalMenu'

import Router from 'next/router';

const navigateTo = (url: string) => {
    return (e: any) => {
        e.preventDefault();
        Router.push(url)
        return false;
    }
};

const ManagementMenu = (data: any) => {
  const sidebar = data.sidebar;

  const { data: userData } = GQL.useUserDetailsQuery();
  const account = userData?.me;

  if (!account)
    return <></>;

  const hasShop = account.shops && account.shops.length > 0;
  const hasUnion = account.shopUnions && account.shopUnions.length > 0;

  return <UniversalMenu title={"Management"} icon="wrench" direction="left" sidebar={sidebar} items={[
    account.aclShop.includes(GQL.AclFlag.CanAdd) ? { "icon": "magic", "text": "Onboard Shop", "onClick": navigateTo('/acp/onboard') } : undefined,
    { "divider": true },
    // Show shops to shop role users and netops
    (account.networkOperator || hasShop) ? { "icon": "store-alt", "text": "Shops", "onClick": navigateTo('/acp/shop') } : undefined,
    // Show unions to union role users and netops
    (account.networkOperator || hasUnion) ? { "icon": "building", "text": "Shop Unions", "onClick": navigateTo('/acp/union') } : undefined,
    // Show cab menu to any role user and all netops
    (account.networkOperator || hasShop || hasUnion) ? { "icon": "server", "text": "Cabinets", "onClick": navigateTo('/acp/cabinets') } : undefined,
    // Only open network management lists and pages for netops
    account.networkOperator ? { "icon": "users", "text": "Users", "onClick": navigateTo('/acp/user') } : undefined,
    account.networkOperator ? { "icon": "analytics", "text": "Analytics", "onClick": navigateTo('/acp/analytics') } : undefined,
    account.networkOperator ? { "icon": "cog", "text": "Parameters", "onClick": navigateTo('/acp/parameters') } : undefined,
  ]} />
};

export default ManagementMenu
