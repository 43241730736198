import * as React from 'react'

import Router from 'next/router';

import UniversalMenu from '@/components/UniversalMenu'

import * as GQL from '@/gql-types'

const navigateTo = (url: string, as: string | null) => {
    let actualAs: string | undefined = undefined;

    if (as != null)
      actualAs = as;

    return (e: any) => {
        e.preventDefault();
        Router.push(url,actualAs);
        return false;
    }
};

const JubeatMenu = (data: any) => {
  const series = data.series;
  const sidebar = data.sidebar;
  const user = data.user;

  const profileData = GQL.useUserGameProfileQuery({ variables: {game: series.shortname} });

  let playerItems: any = [];
  let hasProfile = false;
  if (profileData?.data?.me?.gameProfile) {
    hasProfile = true;
    playerItems = [
      { "icon": "user", "text": <>Profile <small>BETA</small></>, "onClick": navigateTo('/game/jb/[pid]', '/game/jb/me') },
      (user?.networkOperator ? { "icon": "gem", "text": <>Scores <small>BETA</small></>, "onClick": navigateTo('/game/jb/[pid]/scores', '/game/jb/me/scores') } : undefined),
      { "divider": true },
      (user?.networkOperator ? { "icon": "shield-alt", "text": <>Emblem <small>BETA</small></>, "onClick": navigateTo('/game/jb/[pid]/emblem', '/game/jb/me/emblem') } : undefined),
      (user?.networkOperator ? { "icon": "cubes", "text": <>Jbox <small>BETA</small></>, "onClick": navigateTo('/game/jb/[pid]/jbox', '/game/jb/me/jbox') } : undefined),
      (user?.networkOperator ? { "icon": "balance-scale", "text": <>Rivals <small>BETA</small></>, "onClick": navigateTo('/game/jb/[pid]/rivals', '/game/jb/me/rivals') } : undefined),
      { "divider": true },
    ];
  }

  let defaultItems: any = [
    { "icon": "users", "text": "Players", "onClick": navigateTo('/game/jb/players', null) },
    (user?.networkOperator ? { "icon": "music", "text": <>Songs <small>BETA</small></>, "onClick": navigateTo('/game/jb/songs', null) } : undefined),
  ];

  return <UniversalMenu title={series.name} icon="dice-d6" sidebar={sidebar} dim={!hasProfile} items={playerItems.concat(defaultItems)}/>
};

export default JubeatMenu
