import * as React from 'react'

import UniversalMenu from '@/components/UniversalMenu'

const PopnMenu = (data: any) => {
  const series = data.series;
  const sidebar = data.sidebar;

  let playerItems: any = [];
  let hasProfile = false;

  let defaultItems: any = [
    { "text": <i>Sorry, but we don't fully support this series yet</i> }
  ];

  return <UniversalMenu title={series.name} icon="hamburger" sidebar={sidebar} dim={!hasProfile} items={playerItems.concat(defaultItems)}/>
};

export default PopnMenu
